<template>
  <div class="drawerHscode">
    <div class="searchinput">
      <el-form :model="datas" :inline="true" size="normal" :rules="formRules" ref="drawerHscodeRef" @submit.prevent>
        <el-form-item prop="inputValue" class="myclass">
          <el-input v-model="datas.inputValue" placeholder="请输入产品名称或海关编码" clearable ref="inputHscodeRef">
            <!-- @change="searchExampleHs" -->
            <template #append>
              <el-button icon="el-icon-search" @click="searchFunc" native-type="submit">搜索</el-button>
            </template>
          </el-input>
        </el-form-item>
      </el-form>
    </div>

    <div class="tableform">
      <el-table :data="tableDatas" ref="tableFormref" style="width: 100%" max-height="500px" stripe>
        <el-table-column fixed="left" prop="hsCode" sortable label="海关编码" width="120" />
        <el-table-column prop="productName" label="申报名称" show-overflow-tooltip min-width="180">
          <template #header>申报名称</template>
          <template #default="scope">
            <div v-html="scope.row.productName"></div>
          </template>
        </el-table-column>
        <el-table-column prop="exampleRef" label="实例参考" show-overflow-tooltip min-width="520" />
        <el-table-column prop="hsDesc" label="海关描述" show-overflow-tooltip min-width="250" />
        <el-table-column prop="inspectionItem" label="监管条件" width="76" />
        <el-table-column prop="unitJointStr" label="法定单位" width="120" />
        <el-table-column fixed="right" prop="disabledVal" label="操作" sortable width="100">
          <template #header>操作</template>
          <template #default="scope">
            <el-tooltip content="当前海关编码监管条件不可操作，请选择其他编码或品名" :disabled="scope.row.disabledVal" placement="top"
              :show-after="1500">
              <div>
                <el-button size="mini" :disabled="!scope.row.disabledVal" @click="choiseFunc(scope.row)">选择</el-button>
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
  <div v-show="datas.totalNum">
    <el-pagination v-model:currentPage="datas.currentPage" v-model:page-size="datas.pageSize" :small="datas.small"
      :background="datas.background" layout="prev, pager, next, jumper" :total="datas.totalNum"
      @current-change="handleCurrentChange" />
  </div>

  <div class="drawerBox">
    <el-drawer v-model="drawerDetails" :title="drawerDetailsTitle" destroy-on-close :before-close="closeDetails"
      size="92%">
      <DrawerDetails ref="drawerDetailsRef" />
    </el-drawer>
  </div>
</template>
<script>
import {
  reactive, toRefs, inject, computed, ref, nextTick,
} from 'vue';
import {
  ElNotification, ElMessageBox, ElMessage, ElLoading,
} from 'element-plus';
import myAxios from '../../utils/axios/index_v1';
import DrawerDetails from './DrawerDetails.vue';
import rulesClass from '../../utils/utilsClass/rulesClass';

export default {
  name: 'drawerHscode',
  components: { DrawerDetails },
  setup() {
    const drawerHscodeRef = ref(null);
    const createCustomsDatasBus = inject('createCustomsDatasBus');
    const { storeDatas } = createCustomsDatasBus; // 共享存储的开关按钮

    // $refs
    const drawerDetailsRef = ref(); // 获取申报明细中所有方法$el
    const tableFormref = ref(); // 操作scrollTop置顶 节点元素
    const inputHscodeRef = ref();

    const drawerDetailsTitle = computed(() => `海关申报要素明细【${storeDatas.argOfProductName.hsCode}】`); // 申报要素侧表栏的title
    // 数据
    const oldInputValue = ref(''); // 对比新输入值
    const datas = reactive({
      inputValue: '', // 查询输入框内容keyword
      totalNum: 0, // 总计条数
      currentPage: 1, // 当前页
      pageSize: 20, // 每页展示行数
      small: false, // 小型分布
      background: true, // 背景色
    });
    const formRules = reactive({
      inputValue: [rulesClass.HscodeKeyWord],
    });
    // 可以买单报关的代码
    const isInculuds = ['3', '4', '5', '8', 'B', 'D', 'E', 'G', 'I', 'J', 'S', 'T', 'U', 'W', 'X', 'r', 's', 't'];

    // 初始化之前数据（渲染页面内容数据集合）
    const tableDatas = ref([
      // {
      //   hsCode: '', // 海关编码
      //   hsDesc: '', // 海关描述信息
      //   productName: '', // 申报名称
      //   exampleRef: '', // 申报实例
      //   inspectionItem: '', // 监管条件
      //   unitJointStr: '', // 法定申报单位
      //   disabledVal: '', // 是否可以买单报关
      // },
    ]);
    nextTick(() => {
      inputHscodeRef.value.focus();
    });
    // 方法
    const myMethods = reactive({
      // 回车或change获取查询实例，e是输入的值
      // searchExampleHs: (e) => {
      //   // 空值
      //   if (e === '') {
      //     tableDatas.value = [];
      //     datas.totalNum = 0; // 总计条数
      //     return;
      //   }
      //   // 输入不同值
      //   datas.totalNum = 0; // 总计条数
      //   datas.currentPage = 1; // 当前页
      //   oldInputValue.value = datas.inputValue.trim(); // 将原值赋值给一个变量
      //   myMethods.opResults();
      // },
      // 点击【搜索】查询实例
      searchFunc: () => {
        // 值未发生变化，则不加载数据
        if (oldInputValue.value === datas.inputValue.trim()) {
          if (oldInputValue.value !== '') {
            // 假加载
            const eload = ElLoading.service({
              lock: true,
              text: '正在加载数据...',
              background: 'rgba(0, 0, 0, 0.5)',
            });
            eload.close();
          }
          return;
        }
        datas.totalNum = 0; // 总计条数
        datas.currentPage = 1; // 当前页
        myMethods.opResults();
      },
      // 获取后台数据库值
      opResults: () => {
        drawerHscodeRef.value.validate((volid, errObject) => {
          if (volid) {
            const keyword = datas.inputValue.trim();
            if (!keyword) {
              ElNotification({
                title: '错误提示',
                message: '请输入所需要查询内容',
                type: 'error',
              });
              return;
            }
            myAxios()
              .initAxios({
                method: 'POST',
                url: '/example_hscode',
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
                },
                data: {
                  keyword,
                  page: datas.currentPage - 1,
                },
              })
              .then((res) => {
                // 空数据
                if (res.datas === null) {
                  ElNotification({
                    title: '加载提示',
                    message: '未查询到相关数据',
                    type: 'info',
                  });
                  tableDatas.value = [];
                  return;
                }
                tableDatas.value = [];

                // 添加总数量
                datas.totalNum = res.totalNum;
                // TypeError: Cannot read properties of undefined (reading 'toUpperCase')
                res.datas.forEach((item) => {
                  const jgtj = item.hscode_ciq_jg; // 监管条件对象
                  let jgtjRes = '';
                  let isDisabled = true; // 【false不显示，true显示】
                  if (Object.prototype.hasOwnProperty.call(item, 'hscode_ciq_jg')) {
                    if (jgtj.length > 0) {
                      const jgtjObj = JSON.parse(jgtj);
                      jgtjObj.forEach((code) => {
                        jgtjRes += code;
                        // 判断是否可以买单报关
                        if (isInculuds.indexOf(code) !== -1) {
                          isDisabled = false;
                        }
                      });
                    }
                  }
                  // 获取单位名称
                  const unitStr = item.hscode_unit; // 单位对象
                  let unitStrs = ''; // 存放单位
                  if (unitStr !== 'null') {
                    const unitObj = JSON.parse(unitStr);
                    unitObj.forEach((unitItem) => {
                      const unitRes = unitItem.unit_name;
                      unitStrs += unitStrs !== '' ? ` | ${unitRes}` : unitRes;
                    });
                  }
                  // const k = myMethods.handleKeyWordColor(keyword, item.customs_name);
                  const tabledata = {
                    hsCode: item.hscode, // 海关编码
                    hsDesc: item.hscode_cn_name, // 海关描述
                    productName: myMethods.handleKeyWordColor(keyword, item.customs_name), // 申报名称
                    // productName: item.customs_name, // 申报名称
                    exampleRef: item.customs_content, // 实例参考
                    inspectionItem: jgtjRes, // 监管条件
                    unitJointStr: unitStrs, // 法定单位组合字符串
                    disabledVal: isDisabled, // 是否可以买单报关
                  };
                  const childEl = tableFormref.value.$el.children;
                  if (childEl.length > 2) {
                    childEl[2].scrollTop = 0;
                    childEl[2].scrollLeft = 0;
                  }
                  tableDatas.value.push(tabledata);
                });
              })
              .catch((err) => {
                let errMsg = err;
                if (Object.prototype.hasOwnProperty.call(err, 'state')) {
                  errMsg = err.state;
                } else if (Object.prototype.hasOwnProperty.call(err, 'message')) {
                  errMsg = err.message;
                }
                // 获取 数据出错 （状态码不等于200）
                ElNotification({
                  title: '加载错误提示1',
                  message: errMsg,
                  type: 'error',
                });
              });
          } else {
            ElNotification({
              title: '错误',
              message: errObject.inputValue[0].message,
              type: 'error',
            });
          }
        });
      },

      // 设置关键字颜色
      /**
       * @param{string} keyWords 输入查询关键字
       * @param{string} replaceValue 操作字符串
       */
      handleKeyWordColor: (keyWords, replaceValue) => {
        if (replaceValue === undefined) {
          return '';
        }

        replaceValue = replaceValue.toUpperCase();
        keyWords = keyWords.toUpperCase();
        const keyWordsAry = keyWords.split(' ');
        keyWordsAry.forEach((keyWord) => {
          replaceValue = replaceValue.replace(keyWord, `<font color="red" tag="myself">${keyWord}</font>`);
        });
        return replaceValue;
      },
      // 点击分页事件
      handleCurrentChange: (e) => {
        datas.currentPage = e;
        myMethods.opResults();
      },
      /** 关闭申报要素共享函数
       * @param refDrawerDetails 海关明细页$ref
       * @param done 关闭明细页的事件
       * @param isChange 判断是否有编辑过
       * @param moduleVal 当前是在哪个模式下[proNameClk,hscodeClk]
       * @param message 弹出框显示信息
       */
      shareOptionsFunc: (dwDetilsRefs, done, isChange, moduleVal, message) => {
        if (isChange) {
          // 未改动
          ElMessage({
            type: 'success',
            message: `正在返回【${message}】页`,
          });
          // 清除临时文件
          storeDatas.tempHscode = '';
          storeDatas.tempProductName = '';
          // storeDatas.tempDetailFormDatas = {};
          if (moduleVal === 'proNameClk') {
            storeDatas.drawerHscode = false; // 关闭海关信息侧边栏
          }
          done(); // 关闭当前页
        } else {
          // 修改了数据
          ElMessageBox.confirm('您的数据已修改尚未保存，是否需要关闭当前数据？', {
            title: '提示',
            type: 'warning',
            showClose: false,
            confirmButtonText: '取消',
            cancelButtonText: '关闭',
            closeOnClickModal: false, // 点击蒙层取消
            closeOnPressEscape: false, // 禁止按ESC
          })
            .then((res) => {
              // 点击保存
              if (res === 'confirm') {
                // 点击保存：校验值是否正确（正确：保存，不正确：返回申报明细编辑
                // storeDatas.drawerDetailsRefs.validate((volid, filed) => {
                //   // 对整个表单作验证
                //   if (volid) {
                //     // 完整填写
                //     drawerDetailsRef.value.addInfoToStore().then((r) => {
                //       if (r) {
                //         ElMessage.success({
                //           message: '添加【品名】成功',
                //         });
                //       }
                //     });
                //     // 清除临时文件
                //     storeDatas.tempHscode = '';
                //     storeDatas.tempProductName = '';
                //     // storeDatas.tempDetailFormDatas = {};
                //     done();
                //     storeDatas.drawerHscode = false;
                //   } else {
                //     // 填写数据有误
                //     const { baseDatas } = dwDetilsRefs;
                //     // 错误信息提示
                //     Object.keys(filed).forEach((e) => {
                //       const { label } = baseDatas[e];
                //       setTimeout(() => {
                //         ElNotification.error({
                //           title: '警告',
                //           message: `【${label}】:${filed[e][0].message}`,
                //           duration: 3000,
                //         });
                //       }, 500);
                //     });
                //   }
                // });
              }
            })
            .catch((err) => {
              // 点击关闭
              if (err === 'cancel') {
                // 清除临时文件
                storeDatas.tempHscode = '';
                storeDatas.tempProductName = '';
                // storeDatas.tempDetailFormDatas = {};
                done();
                if (moduleVal === 'hscodeClk') {
                  // 海关编码信息栏点击
                  ElMessage({
                    type: 'success',
                    message: '正在返回【海关编码信息】页',
                    duration: 2000,
                  });
                } else {
                  // 产品名称点击
                  storeDatas.drawerHscode = false;
                  ElMessage({
                    type: 'success',
                    message: '正在返回【产品信息】页',
                    duration: 2000,
                  });
                }
              }
            });
        }
      },
    });
    // const isInculuds = ['3', '4', '5', '8', 'B', 'D', 'E', 'G', 'I', 'J', 'S', 'T', 'U', 'W', 'X', 'r', 's', 't'];
    // 选择按钮事件
    function choiseFunc(hscode) {
      const {
        hsCode, productName, exampleRef, unitJointStr,
      } = hscode;
      storeDatas.drawerDetails = true;
      storeDatas.tempHscode = hscode.hsCode; // 海关编码

      storeDatas.argOfProductName = {
        hsCode,
        productName,
        exampleRef,
        unitJointStr,
        enterDetailsType: false, // 海关信息栏点击进入
      };
    }
    // 关闭申报要素侧边栏
    function closeDetails(done) {
      const { enterDetailsType } = storeDatas.argOfProductName; // 判断何种方式进入申报要素
      // const dwDetilsRefs = storeDatas.drawerDetailsRefs;
      const dwDetilsRefs = drawerDetailsRef.value;
      // 0, 先判断是从哪里传剃过来的值（产品信息 还是 海关编码查询侧边栏）
      // 从产品信息传递过来的值会将值传导给createCustomsDatas中临时变量
      // 海关编码查询 侧边栏只传递海关编码不传递产品名称
      if (enterDetailsType) {
        // 产品信息栏传递过来的值 并判断关闭时值是否有更改
        myMethods.shareOptionsFunc(dwDetilsRefs, done, !drawerDetailsRef.value.saveBtn, 'proNameClk', '产品信息');
      } else {
        // 海关编码查询 侧边栏传递过来的值 ，并判断关闭时值是否有更改
        myMethods.shareOptionsFunc(dwDetilsRefs, done, !drawerDetailsRef.value.saveBtn, 'hscodeClk', '海关编码信息');
      }
    }
    return {
      formRules,
      createCustomsDatasBus,
      // ...toRefs(datas),
      datas,
      ...toRefs(storeDatas),
      ...toRefs(myMethods),
      drawerDetailsTitle,
      tableDatas,
      drawerDetailsRef,
      tableFormref,
      inputHscodeRef,
      drawerHscodeRef,
      choiseFunc,
      closeDetails,
    };
  },
};
</script>
<style lang="scss" scoped>
// 错误提示信息向上提升

.drawerHscode {
  padding: $base;

  .searchinput {
    padding: $base * 3 0;
    display: flex;
    justify-content: center;

    :deep(.el-input .el-input__inner) {
      font-size: 18px;
      line-height: 20px;
      text-align: center;
    }

    :deep(.el-input-group) {
      width: 550px !important;
    }

    :deep(.el-form-item__content) {
      .el-form-item__error {
        margin-top: 5px;
        font-size: 15px;
      }
    }
  }

  // :deep(.el-table th.el-table__cell) {
  // 	background-color: rgba($mycard, 0.3);
  // }
}

.tableform {
  // :deep(.el-table .cell) {
  // 	white-space: nowrap;
  // }
}

.drawerBox {
  // :deep(.el-drawer.rtl){
  //   overflow: auto;
  // }
  // :deep(.el-drawer__body) {
  // 	overflow-y: auto;
  // }
}
</style>
