import {
  ElMessage,
  // ElNotification,
  // ElMessage,
  ElMessageBox,
} from 'element-plus';
import { nextTick } from 'vue';
// import { v4 as uuidv4 } from 'uuid';

// import mymethods from './methods';
import WeightInfoMethodsClass from './weightInfoMethodsClass';

export default class WeightClkMethodsClass extends WeightInfoMethodsClass {
  constructor(createCustomsDatasBus, baseDatas) {
    super(createCustomsDatasBus, baseDatas);
    this.createCustomsDatasBus = createCustomsDatasBus;
    this.baseDatas = baseDatas;
  }

  /** 保存按钮事件  保存数据至【表单】共享数据中 tableDatas
   *
   * 先验证【表单】中数据是否有重复数据，并提示查询结果
   * 将数据保存至表单成功后至如下操作：
   * 将initDatas备份数据清空
   * 高亮【表单】最新添加这行数据
   * 更改按钮区域状态为【新增|复制|修改|删除】状态
   *【产品信息】编辑区域为不可编辑
   * 将scorllTop回到第一行
   *
  */
  saveItem = () => {
    const {
      config, datas, tableDatas,
      // initDatas,
      rowClkBus,
    } = this.createCustomsDatasBus;
    const { detailsDatas } = datas;
    if (config.currentBtnState === 'modifyStateSave') { // 状态【保存|重置】且 修改
      this.modifySavetoTableDatas();
    } else if (config.currentBtnState === 'modifyStateAdd') { // 状态【取消|重置】且 非修改
      config.disabledForm = true;
      this.setBtnState();
      this.mixinInfoToFormDatas();// 将混装信息填充至录入框中[毛重，箱数]
    } else if (config.currentBtnState === 'editStateSave' && !config.modifyState) { // 状态【保存|重置】且 非修改
      this.saveItemBeforeFunc();// 保存到表单
    } else if (config.currentBtnState === 'editStateAdd' && !config.modifyState) { // 状态【取消|重置】且 非修改
      if (tableDatas.length > 0) { // 只有表单中有数据才进行如下操作
        // 点击【取消】则表示返回上次展示的值，并高亮当前行
        this.initFormDatas();// 初始化【产品信息】datas.detailsDatas
        // 还原之前高亮行
        const row = tableDatas[rowClkBus.rangeClkId] || tableDatas[0];
        rowClkBus.setCurrent(row);// 还原高亮
        // 设置不可编辑
        config.disabledForm = true;
        // 向【产品栏】数据添加备份数据

        Object.assign(detailsDatas, { ...rowClkBus.currentRow });

        // 修改为【新增|复制|修改|删除】
        this.setBtnState();// 设定按钮内容
        this.mixinInfoToFormDatas();// 混装情况下根据datas.detailsDatas.mixinInfo中的数据填充至formDatas中
      }
    }
  }

  /** 重置按钮事件
   *
   */
  resetForm = () => {
    const {
      initDatas,
      config,
      weightFormRef,
    } = this.createCustomsDatasBus;
    const p = ElMessageBox.confirm('确定重置清空当前输入内容？', {
      title: '提示',
      type: 'warning',
      showClose: false,
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      closeOnClickModal: false, // 点击蒙层取消
      closeOnPressEscape: false, // 禁止按ESC
    });
    // 重置成功、失败、取消提示与操作
    p.then((res) => {
      if (res === 'confirm') {
        // 值变动过[有问题，错误提示不能消除]
        if (config.modifyState) { //  【修改】状态 重置恢复到原有数据状态
          Object.assign(this.createCustomsDatasBus.datas.detailsDatas, { ...initDatas });
          this.createCustomsDatasBus.weightFormRef.value.clearValidate();
          ElMessage.success('重置成功');
        } else { // 其他状态 全部置空到初始状态
          this.initFormDatas();
        }
        nextTick(() => {
          weightFormRef.value.clearValidate();
        });
        this.setBtnState();// 重新设置按钮
      }
    }).catch((err) => {
      if (err === 'cancel') {
        ElMessage.success('取消重置操作');
      } else { // 重置失败
        ElMessage.success('重置失败 ');
      }
    });
  }

  /** 【上一步】【返回】按钮事件
   *
   */
  prevItem = () => {
    const {
      mixinpackOBJ,
      stateContent,
      datas,
    } = this.createCustomsDatasBus;

    const { nextStep } = stateContent;

    if (stateContent.nextStep === 0) {
      this.mixinBackToInit();// 点击 返回按钮 至【非混装】操作事宜
    } else if (JSON.stringify(mixinpackOBJ[nextStep]) !== JSON.stringify(datas.detailsDatas) && stateContent.nextStep !== 0) {
      ElMessageBox.confirm('[录入报关信息]正处于编辑中,数据尚未保存,是否跳转至编辑页保存数据?', { // 提示改动过数据
        title: '提示',
        type: 'success',
        showClose: false,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false, // 点击蒙层取消
        closeOnPressEscape: false, // 禁止按ESC
      }).then((res) => {
        if (res === 'confirm') {
          // 校验当前数据正确与否
          this.validateAllFileds(this.validateAry(), false).then((isError) => {
            if (!isError) { // 无误,保存数据
              // 提示是否保存
              this.mixinResetMixinInfo();// 添加混装备份值数组[标识符uuid，箱数，毛重，混合毛重的净重之合]
              this.mixinPrevItemSetState();// 设置状态 且 退减上一步数值
            }
          });
        }
      }).catch((err) => { // 取消修改
        if (err === 'cancel') {
          this.mixinPrevItemSetState();// 设置状态 且 退减上一步数值
          this.toolTipInfoOfNetAndGw();
        }
      });
    } else { // 没有修改过
      this.mixinPrevItemSetState();// 设置状态 且 退减上一步数值
    }
  }

  /** 【下一步|全部保存】按钮事件
   *  下一步：(判断当前nextStep 与 mixinpackOBJ.length的大小来判定如下)
   * 1,返回后再【下一步】
   * 2,直接【下一步】
   *
   * 下一上有3种状态：
   * mixinInit          【返回|下一步】
   * mixinEdite         【上一步|下一步】
   * mixinSave          【上一步|全部保存步】
   * 混装状态下要求：
   *  1，初始化时即【返回|下一步】，【毛重】不能为0，且不能小于【净重】
   *  2，
   */
  nextItem = () => {
    const {
      config,
      stateContent, // 提示toolTip信息数据
      datas,
      mixinpackOBJ, // 存放当前编辑混装组数据
      tableDatas, // 表格table中数据$BUS
      rowClkBus, // 设置表格行点击事件
      hsTabaleRef, // 表格中Refs
      stepAllDatas,
    } = this.createCustomsDatasBus;
    const {
      moreProductCtns, ctns,
    } = datas.detailsDatas;// 混装数量
    const { nextStep } = stateContent;// 具体操作到哪个步骤，同时也是混装数据下标索引

    /** *转化成数字方便后期比较* */
    const nextStepToNum = Number(nextStep);
    if (stateContent.uuid === null) {
      stateContent.uuid = `${new Date().valueOf()}${Math.random() * 0x1000 | 0}`;
    }
    // 用于占位
    const tempFormDatas = {
      hsCode: '', // 存放海关编码
      productName: '', // 存放产品名称（长编码)
      unit: '', // 法定单位
      unitQTY: '', // 法定单位值
      ctns: '0', // 箱数
      grossWt: '', // 毛重
      moreProductCtns: '', // 混装品名数量
      packageState: 1, // 是否混装【0：非混装，1：混装】
      netWt: '', // 净重
      productQTY: '', // 产品数量
      qtyUnit: '', // 产品数量单位
      totalAmount: '', // 总价
      unitPrice: '', // 产品单价
      unitJointStr: '', // 法定单位组合字符串
      mixinInfo: [], // 记录混装情况下数据，数组依序记录[标记ID,箱数,毛重,混合毛重的净重之合]
    };
    /** 编辑区展示 下一步所需的内容 */
    const goBackToNext = (myDatasNode) => {
      if (config.currentBtnState === 'mixinSave') { // 【上一步|全部保存】
        this.restoreBackUpDatas();// 还原【当前进度】
        this.mixinClearFunc();// 清空使用过的数据
        config.disabledForm = true;
        this.setBtnState();// 设定按钮内容
        myDatasNode.datas.detailsDatas = { ...tableDatas[0] };
        myDatasNode.rowClkBus.setCurrent(tableDatas[0]);
        myDatasNode.hsTabaleRef.value.hsDetailTableRef.$refs.bodyWrapper.scrollTop = 0;// 定位第一行
      } else {
        this.mixinChangeNextBtnState();// 更改下一步按钮信息，同时递增nextStep
      }
      this.toolTipInfoOfNetAndGw();
    };
    if (stateContent.nextStateus) { // 【下一步】无返回
      // console.log('【下一步】无返回');
      this.validateAllFileds(this.validateAry(), false).then((isError) => {
        if (!isError) { // 无误
          // 同时向tableDatas添加数据
          switch (config.currentBtnState) { // 当前按钮区域展示状态
            case 'mixinInit':// 当前状态为【返回 | 下一步】
              for (let i = moreProductCtns; i > 0; i -= 1) { // 向tableDatas添加空对象占位
                tempFormDatas.moreProductCtns = moreProductCtns;
                if (i === 1) {
                  tempFormDatas.ctns = ctns;
                  tableDatas.unshift({ ...tempFormDatas });
                } else {
                  tableDatas.unshift({ ...tempFormDatas });
                }
              }
              this.mixinResetMixinInfo();// 添加混装备份值数组[标识符uuid，箱数，毛重，混合毛重的净重之合]
              this.handleDisabledStateFunc(['packageState', 'moreProductCtns', 'ctns'], true);// 数组中相关禁止Disabled
              this.mixinChangeNextBtnState();// 更改下一步按钮信息，同时递增nextStep
              // 操作【当前进度】
              this.backUpStepValue();// 备份【当前进度】原有数据
              this.reSetStepDatas();// 添加【当前进度】现有数据
              break;
            case 'mixinEdite':// 当前状态为【上一步|下一步】
              this.mixinResetMixinInfo();// 添加混装备份值数组[标识符uuid，箱数，毛重，混合毛重的净重之合]
              this.mixinChangeNextBtnState();// 更改下一步按钮信息，同时递增nextStep

              // 操作【当前进度】

              stepAllDatas.active = nextStep + 1;
              break;
            case 'mixinSave':// 当前状态为【上一步|全部保存】
              this.mixinResetMixinInfo();// 添加混装备份值数组[标识符uuid，箱数，毛重，混合毛重的净重之合]
              this.restoreBackUpDatas();// 还原【当前进度】
              // 最后一步【全部保存】
              this.mixinClearFunc();// 清空使用过的数据
              config.disabledForm = true;
              this.setBtnState();// 设定按钮内容 当前应设置为【新增|复制|修改|删除】状态
              datas.detailsDatas = { ...tableDatas[0] };
              rowClkBus.setCurrent(tableDatas[0]);
              hsTabaleRef.value.hsDetailTableRef.$refs.bodyWrapper.scrollTop = 0;// 定位第一行
              break;
            default:// 其他
              break;
          }
        }
      });
    } else if (JSON.stringify(datas.detailsDatas) !== JSON.stringify(mixinpackOBJ[nextStepToNum])) { // 【下一步】有返回 且 有改动过
      this.validateAllFileds(this.validateAry(), false).then((isError) => {
        if (!isError) { // 无误
          /** 对比原有数据是否有变动，
                   *  有：则提示改动过是否要保存，
                   *  无：直接下一步任使用原有保存数据
                   */
          ElMessageBox.confirm('原有数据被更改,确定保存新数据?', { // 提示改动过数据
            title: '提示',
            type: 'success',
            showClose: false,
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            closeOnClickModal: false, // 点击蒙层取消
            closeOnPressEscape: false, // 禁止按ESC
          }).then((res) => {
            if (res === 'confirm') {
              this.mixinResetMixinInfo();// 添加混装备份值数组[标识符uuid，箱数，毛重，混合毛重的净重之合]
              goBackToNext(this.createCustomsDatasBus);
            }

            stepAllDatas.active = nextStep + 1;
          }).catch((err) => { // 取消修改
            if (err === 'cancel') {
              goBackToNext(this.createCustomsDatasBus);
            }

            stepAllDatas.active = nextStep + 1;
          });
        }
      });
    } else { // 【下一步】有返回 无改动过
      stepAllDatas.active = nextStep + 1;
      // 【上一步|全部保存】状态事件
      goBackToNext(this.createCustomsDatasBus);
    }
  }

  /** 【新增】按钮事件
   *
   */
  addItem = () => {
    const {
      rowClkBus,
      weightFormRef,
    } = this.createCustomsDatasBus;
    this.initFormDatas();// 初始化【产品信息】daymltas.detailsDatas
    // 取消高亮
    rowClkBus.setCurrent();
    nextTick(() => {
      weightFormRef.value.clearValidate();
    });
  }

  /** 【复制】按钮事件
  *
  */
  copyItem = () => {
    const {
      rowClkBus,
      tableDatas,
      weightFormRef,
    } = this.createCustomsDatasBus;
    const copyObj = { ...tableDatas[rowClkBus.rangeClkId] }; // 原有数据
    const res = this.resetCopyDatas(['hsCode', 'productName', 'unit', 'unitJointStr'], copyObj);// 清空重量，递增ID编号
    if (res instanceof Object) { // 返回结果为对象
      this.initFormDatas();// 格式表单
      this.insertToFormData(res);// 插入res数据
      this.setBtnState();// 设置按钮
      nextTick(() => {
        weightFormRef.value.clearValidate();
      });
      // 清空高亮
      rowClkBus.setCurrent();
    } else {
      console.error(res);
    }
  }

  /** 【编辑】按钮事件
      * 是否混装：是:混装品类数量不可编辑，反之可编辑
      * 修改前先备份数据至mixinpackObj,方便后期恢复数据备份
      * 修改毛重、净重需检测上下关系，同时修改关联的mixinInfo
      */
  editItem = () => {
    const {
      datas, config,
      rowClkBus, tableDatas,
    } = this.createCustomsDatasBus;
    const nowRowDatas = { ...tableDatas[rowClkBus.rangeClkId] };
    const isMixin = (Number(nowRowDatas.packageState) === 0); // 非混装true | 混装false

    this.initFormDatas();// 清空产品信息栏，同进将initDatas清空

    Object.assign(this.createCustomsDatasBus.datas.detailsDatas, { ...rowClkBus.currentRow });

    Object.assign(this.createCustomsDatasBus.datas.detailsDatas, {
      ctns: isMixin ? nowRowDatas.ctns.toString() : nowRowDatas.mixinInfo[1].toString(), // 非混装模式取其本身箱数,反之取mixinInfo中的数据
      grossWt: isMixin ? nowRowDatas.grossWt.toString() : nowRowDatas.grossWt.toString(), // 原理同上
    });// 箱数不显示0，毛重显示原数据
    this.baseDatas.unit.selectOptions = [...this.setUnitOptions(datas.detailsDatas.unitJointStr)];
    Object.assign(this.createCustomsDatasBus.initDatas, { ...datas.detailsDatas });// 备份当前编辑前的值

    // 设置当前状态
    config.disabledForm = false;// 可编辑状态
    config.modifyState = true;// 设置当前为修改状态
    this.setBtnState();// 设置状态初始值为修改状态的【取消|重置】
    this.toolTipInfoOfNetAndGw();// 修改毛重提示信息
  }

  /** 【删除】按钮事件
     * 注意事项：
     * 点击第一行时 startIdx为-1
     * 当前为【混装】：
     *    1，只要startIdx 等于 rangClkId 说明当前是【混装毛重】首行，有数据，删除时要向下移数据，同时修改mixinInfo中的混装总净重
     *       同时要判断当前批次的数据长度是否小于2条，小于2条：提示：改成非混装，大于或等于2条：则执行混装数据修改
     *    2，而startIdx 不等于 rangClkId 同时毛重有值，说明当前不是【混装毛重】首行，修改mixinInfo中的混装总净重
     * 当前为【非混装】直接删除
     *
     *
     * 删除到最后剩余0条时则显示编辑模式
     *
    */
  delItem = () => {
    this.createCustomsDatasBus.config.modifyState = true;// 必须开启【修改】模式，不然mixinNoteInfo函数获取不到下上文关系数据
    const mixinNoteInfo = this.mixinNoteInfo();
    const {
      startIdx, startCtnIdx, endIdx, endCtnIdx,
    } = mixinNoteInfo !== null && mixinNoteInfo;

    /** 删除前提示信息 */
    const dialogMsg = (callback) => {
      ElMessageBox.confirm('当前删除操作不可逆，确定删除当前高亮行数据？', {
        title: '警告提示',
        type: 'warning',
        showClose: false,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false, // 点击蒙层取消
        closeOnPressEscape: false, // 禁止按ESC
      }).then((res) => {
        if (res === 'confirm') {
          callback('confirm');
        } else {
          throw (new Error('other error'));
        }
      }).catch((err) => {
        if (err === 'cancel') {
          // console.log('点击了取消');
        } else {
          throw (new Error('other error'));
        }
      });
    };

    /** 设置【非混装】删除后的表格数据中的高亮行
     * 删除后下一行向上移一行
     *  tbLen === 0  不设置高亮 编辑区域为新增模式
     *  tbLen === 1  显示当前行 索引取0 编辑区域为不可编辑模式
     *  tbLen > 1 && clkRow < tbLen 向下高亮 编辑区域为不可编辑模式
     *  tbLen > 1 && clkRow ≥ tbLen 向上高亮
     */
    const setHLightRow = (myDatasNode) => {
      const {
        tableDatas, rowClkBus, config,
      } = myDatasNode;
      const tbLen = tableDatas.length;// 获取到删除后的tableDatas
      const clkRow = rowClkBus.rangeClkId;// 当前点击行
      let rowIdx = null;// 设置操作当前的row
      if (tbLen === 0) { // 表格中没有数据
        config.modifyState = false;// 非修改状态(还原)
        config.disabledForm = false;// 可编辑状态
        this.initFormDatas();// 初始化表单数据

        nextTick(() => {
          // 清除错误提示
          this.createCustomsDatasBus.weightFormRef.value.clearValidate();
        });

        rowIdx = null;
      } else if (tbLen === 1) { // 表格中只剩余1条数据
        rowIdx = 0;
      } else if (tbLen > 1) {
        // 点击行≥表单数据 向上高亮 反之向下高亮
        rowIdx = clkRow >= tbLen ? clkRow - 1 : clkRow;
      }

      rowClkBus.rangeClkId = rowIdx;
      rowClkBus.oldRangeClkId = rowClkBus.rangeClkId;
      rowClkBus.currentRow = rowIdx === null ? '' : { ...tableDatas[rowIdx] };

      if (tbLen !== 0) { // 表单中有数据
        this.clkRowFormState();// 点击【表格】中行【产品信息】栏响应事件
        this.mixinInfoToFormDatas();// 将混装信息填充至录入框中
        rowClkBus.setCurrent(tableDatas[rowIdx]);// 高亮行
      } else { // 表单中没有数据
        // console.log('不设置高亮');
      }

      // console.log(tableDatas.map((item) => [item.productName.split('  ')[0], item.packageState, item.moreProductCtns, item.ctns, item.grossWt, item.netWt, item.mixinInfo[1], item.mixinInfo[2], item.mixinInfo[3]]));
    };

    /** 操作删除首行 */
    const firstRowDelOp = (myDatasNode) => {
      const { tableDatas, rowClkBus } = myDatasNode;
      const clkIdx = rowClkBus.rangeClkId;
      const {
        packageState, ctns, grossWt,
        // netWt, ,
      } = tableDatas[clkIdx];
      if (Number(packageState) === 0) { // 非混装类型
        dialogMsg((res) => {
          try {
            if (res === 'confirm') {
              /** 点击了确定
               *  1， 删除当前点击行数据
               *  2， 高亮下一行
               */
              tableDatas.splice(clkIdx, 1);
              setHLightRow(this.createCustomsDatasBus);// 高亮
            }
          } catch (error) {
            console.error(error);
          }
        });
      } else if (Number(packageState) === 1) {
        dialogMsg((res) => {
          try {
            if (res === 'confirm') {
              /** 原理
               * 1，先备份毛重、箱数、净重
               * 2，判断剩余多少行≥2行，执行3，剩余1行，变成非混装，同时清除对应数据
               * 3，删除当前行时先将毛重、箱数给到下一行
               * 4，设置高亮规则
               *
               * 最终 ：都是根据mixinNoteInfo返回的值来判断数据
               */
              const tb = { ...tableDatas[clkIdx] };
              const gwT = Number(tb.grossWt) === 0 ? tableDatas[startCtnIdx].grossWt : tb.grossWt;
              const ntT = tb.netWt;
              const ctnsCopy = tableDatas[startCtnIdx].ctns;
              const mixinLen = endCtnIdx - startCtnIdx;// 开始行到结束行（本应结束行+1，但需要删除一行则-1，相互抵消）

              if (mixinLen === 1) { // 剩余一行，让下一行变成非混装
                /** 只剩下一行，让下一行变成非混装
                 *  删除minxinInfo为空
                 *  删除【混装产品数量】
                 *  赋值备份毛重、箱数给到下一行
                 *  */
                const idx = (clkIdx >= endCtnIdx) ? clkIdx - 1 : clkIdx + 1;// 判断向上还是向下修改
                tableDatas[idx].grossWt = gwT;
                tableDatas[idx].packageState = 0;
                tableDatas[idx].moreProductCtns = '';
                tableDatas[idx].ctns = ctnsCopy;
                tableDatas[idx].mixinInfo = [];
                tableDatas.splice(clkIdx, 1);
                setHLightRow(this.createCustomsDatasBus);// 高亮
              } else if (mixinLen > 1) { // 剩余多行，修改该批次的剩余信息
                /** 原理
                 * 向下
                //  * */
                const tlNetWt = tableDatas[clkIdx].mixinInfo[3] - Number(ntT);// 求出混装净重和减去当前要删除的净重
                const newProNum = tableDatas[clkIdx].moreProductCtns - 1;
                // 修改当前批次混装的合并行数
                for (let x = startCtnIdx; x < endCtnIdx + 1; x += 1) {
                  tableDatas[x].moreProductCtns = newProNum.toString();
                }
                // 修改当前混装毛重批次的数据
                if (Number(grossWt) !== 0) {
                  for (let i = clkIdx + 1; i < endIdx; i += 1) {
                    tableDatas[i].mixinInfo[3] = tlNetWt;
                    // 下一个第一个值时设置毛重
                    if (i === clkIdx + 1) tableDatas[clkIdx + 1].grossWt = gwT;
                  }
                } else if (Number(grossWt) === 0) {
                  for (let i = startIdx; i < endIdx; i += 1) {
                    tableDatas[i].mixinInfo[3] = tlNetWt;
                  }
                }
                // 点击行的箱数不为0：表示箱数为头部行，反之：表示非头部行，只是混装毛重首行
                if (Number(ctns) !== 0) tableDatas[clkIdx + 1].ctns = ctnsCopy;
                tableDatas.splice(clkIdx, 1);
                setHLightRow(this.createCustomsDatasBus);// 高亮
              }
            }
          } catch (error) {
            console.error(error);
          }
        });
      }
    };
    firstRowDelOp(this.createCustomsDatasBus);
  }
}
