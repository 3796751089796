/** 数据说明
 *
 * 【unitJointStr】记录由【海关编码查询】或【产品申报要素明细】页传递过来的单位，减少向服务器查询数据库
 * 数据为由上而下执行，只有在【packageState】为1时：表示混装状态
 * 混装状态：当【ctns】【grossWt】为0时，表示与上一个数据合并
 *
 * 着重说明【mixinInfo】数据
 * 混装状态下，记录箱数临时数据
 * 混装状态下，需要比较毛重与净重值，作为混装临时存储的数据
 */
export default function () {
  const testDatas = {
    hsCode: '5205150000',
    productName: '测试数据-0  1|0|粗梳|单纱|非零售|100%棉|97分特|自主品牌|环锭纺|2022-05-11|||other',
    packageState: 0,
    moreProductCtns: '',
    ctns: '10',
    productQTY: '50.00',
    qtyUnit: '007',
    grossWt: '10000.00',
    netWt: '100.00',
    unit: '035',
    unitQTY: '100.00',
    unitPrice: '1.0000',
    totalAmount: '100.0000',
    unitJointStr: '千克 | 个',
    mixinInfo: [], // 记录混装情况下数据，数组依序记录[箱数,毛重]
  };
  const testTableDatas = [
    // {
    //   hsCode: '5205150000',
    //   productName: '测试数据-1  1|0|粗梳|单纱|非零售|100%棉|97分特|自主品牌|环锭纺|2022-05-11|||other',
    //   packageState: 0,
    //   moreProductCtns: '',
    //   ctns: '10',
    //   productQTY: '50.00',
    //   qtyUnit: '007',
    //   grossWt: '10000.00',
    //   netWt: '100.00',
    //   unit: '035',
    //   unitQTY: '100.00',
    //   unitPrice: '1.0000',
    //   totalAmount: '100.0000',
    //   unitJointStr: '千克 | 个',
    //   mixinInfo: [], // 记录混装情况下数据，数组依序记录[箱数,毛重]
    // },
  ];

  return {
    testDatas, testTableDatas,
  };
}
