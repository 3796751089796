<template>
  <div class="weightInfo">
    <!-- 申报产品明细- 产品重量信息 -->
    <el-form ref="weightFormRef" :model="createCustomsDatasBus.datas.detailsDatas"
      :rules="createCustomsDatasBus.datas.rules" label-width="120px" class="weightForm"
      :disabled="createCustomsDatasBus.config.disabledForm">
      <!-- 其他数据循环 -->
      <el-form-item v-for="item, index in baseDatas" :key="index" :prop="index" :label="item.label"
        :ref="`${index}Ref`">
        <!-- 选择器 -->
        <el-tooltip v-if="item.selectOptions" class="item" effect="dark" :content="item.tooltip" placement="top-start"
          :show-after="1000" tabindex="-1">
          <el-select v-model="createCustomsDatasBus.datas.detailsDatas[index]" :placeholder="item.placeholder"
            size="small" filterable default-first-option @change="item.optionChangeFuncs"
            @visible-change="item.vsbChangeOptions" :filter-method="item.fileterOptions" :disabled="item.disabledState">
            <!-- @input="item.inputFuncs"  -->
            <el-option v-for="option in item.selectOptions" :key="option.value" :label="option.tagName"
              :value="option.value"></el-option>
          </el-select>
        </el-tooltip>
        <!-- 输入框-->
        <el-tooltip v-else class="item" effect="dark" :content="item.tooltip" placement="top-start" :show-after="1000">
          <el-input v-if="item.appendVal" size="small" :placeholder="item.placeholder"
            v-model.trim="createCustomsDatasBus.datas.detailsDatas[index]" clearable class="myAppendClass"
            :disabled="baseDatas[index].disabledState" @input="item.inputFuncs"
            @blur="item.blurFuncs(createCustomsDatasBus.datas.detailsDatas[index])">
            <template #append>
              <div :class="createCustomsDatasBus.config.disabledForm ? 'deepText' : 'grayText'"
                v-if="!item.appendSelect">
                {{ item.appendLabel }}</div>
              <div :class="createCustomsDatasBus.config.disabledForm ? 'deepText' : 'grayText'" v-else>
                <el-select v-model="createCustomsDatasBus.datas.detailsDatas.qtyUnit"
                  :placeholder="item.appendOptions.placeholder" filterable style="width: 90px"
                  @change="item.appendOptions.optionChangeFuncs" @visible-change="item.appendOptions.vsbChangeOptions"
                  :filter-method="item.appendOptions.fileterOptions">
                  <el-option v-for="option in item.appendOptions.selectOptions" :key="option.value"
                    :label="option.tagName" :value="option.value"></el-option>
                </el-select>
              </div>
            </template>
          </el-input>
          <el-input v-else size="small" :placeholder="item.placeholder"
            v-model.trim="createCustomsDatasBus.datas.detailsDatas[index]" clearable :readonly="item.readonlyVal"
            :disabled="baseDatas[index].disabledState" @click="item.clkFuncs" @input="item.inputFuncs"
            @blur="item.blurFuncs(createCustomsDatasBus.datas.detailsDatas[index])"></el-input>
        </el-tooltip>
      </el-form-item>
    </el-form>
    <div class="bttton">
      <el-button-group v-if="createCustomsDatasBus.config.buttonState">
        <!-- 非混装状态时显示按钮-->
        <div v-if="!createCustomsDatasBus.config.nextBTN">
          <el-button type="primary" @click="weightClkMethodsClass.saveItem">{{ createCustomsDatasBus.btnState.save ?
            "保存" :
            "取消" }}</el-button>
          <el-button type="info" @click="weightClkMethodsClass.resetForm">重置</el-button>
        </div>
        <!-- 混装状态时显示按钮，根据所填写n个数量显示n个下一步 -->
        <div v-else>
          <el-tooltip :content="createCustomsDatasBus.stateContent.prevTooltip" placement="top-start">
            <el-button type="success" @click="weightClkMethodsClass.prevItem">{{ createCustomsDatasBus.btnState.prev ?
              '返回'
              : '上一步' }}</el-button>
          </el-tooltip>
          <el-tooltip :content="createCustomsDatasBus.stateContent.nextTooltip" placement="top-start">
            <el-button type="success" @click="weightClkMethodsClass.nextItem">{{ createCustomsDatasBus.btnState.next ?
              '全部保存' : '下一步' }}</el-button>
          </el-tooltip>
        </div>
      </el-button-group>
      <el-button-group v-else>
        <el-button type="primary" :disabled="createCustomsDatasBus.btnState.add"
          @click="weightClkMethodsClass.addItem">新增</el-button>
        <el-button type="success" :disabled="createCustomsDatasBus.btnState.copy"
          @click="weightClkMethodsClass.copyItem">复制</el-button>
        <el-button type="warning" :disabled="createCustomsDatasBus.btnState.edit"
          @click="weightClkMethodsClass.editItem">修改</el-button>
        <el-button type="info" :disabled="createCustomsDatasBus.btnState.delete"
          @click="weightClkMethodsClass.delItem">删除</el-button>
      </el-button-group>
    </div>
  </div>
</template>
<script>
import {
  // onMounted,
  nextTick,
  inject,
  ref,
  onBeforeMount,
} from 'vue';

import mybasedatas from './models/weightinfo/basedatas';
import testdata from './models/weightinfo/testdata';
import WeightInfoMethodsClass from './models/weightinfo/weightInfoMethodsClass';
import WeightClkMethodsClass from './models/weightinfo/weightClkMethodsClass';

export default {
  name: 'weightInfo',
  setup() {
    /**
     * 初始化数据
     */

    const {
      // testDatas,
      testTableDatas,
    } = testdata(); // 测试数据

    // $ref
    const weightFormRef = ref(null);
    const moreProductCtnsRef = ref(null);

    // 子组件所有数据集合
    const createCustomsDatasBus = inject('createCustomsDatasBus');

    // 待页面加载完成，将$ref添加至共享$BUS数据中
    nextTick(() => {
      createCustomsDatasBus.weightFormRef.value = weightFormRef.value;
      createCustomsDatasBus.moreProductCtnsRef.value = moreProductCtnsRef.value;// 混装品类数量ref
    });

    const { baseDatas } = mybasedatas(createCustomsDatasBus); // 渲染数据
    const weightInfoMethodsClass = new WeightInfoMethodsClass(createCustomsDatasBus, baseDatas);
    const weightClkMethodsClass = new WeightClkMethodsClass(createCustomsDatasBus, baseDatas);

    weightInfoMethodsClass.addStoreMethod(); // 初始化store添加一个新的属性

    weightInfoMethodsClass.initForm(); // 初始化校验，表单信息

    // 向共享事件中添加方法
    createCustomsDatasBus.rowClkBus.clkRowFunc = {
      clkRowFormStateBus: () => {
        //  点击行事件驱动表单
        weightInfoMethodsClass.clkRowFormState();
        weightInfoMethodsClass.mixinInfoToFormDatas(); // 混装情况下根据datas.detailsDatas.mixinInfo中的数据填充至formDatas中
      },
    };

    onBeforeMount(() => {
      // 添加测试数据
      weightInfoMethodsClass.insertTestDatasToDatas(testTableDatas); // 加载数据到表单中
    });

    // 将设置inputdisabled方法赋值给共享数据
    createCustomsDatasBus.initDisabledStateFunc = weightInfoMethodsClass.initDisabledState;
    createCustomsDatasBus.setUnitOptionsFunc = weightInfoMethodsClass.setUnitOptionsFunc;

    return {
      moreProductCtnsRef,
      createCustomsDatasBus,
      weightClkMethodsClass,
      weightFormRef,
      baseDatas,
    };
  },
};
</script>
<style lang="scss" scoped>
.weightInfo {
  // padding: $base $base $base * 0.3 $base;
  @import '@/assets/scss/globalcss/elementsFromstyle.scss';

  :deep(.el-form.weightForm) {
    .el-form-item {

      // &:nth-child(2){
      //   width:calc(100% / 3 *2);
      // }
      &:last-child {
        flex: 1;
      }
    }
  }

  .myAppendClass {
    margin-top: 5px;
  }

  // bttton按钮
  .bttton {
    display: grid;
    justify-content: center;
    margin-top: $base * 0.8;
    padding-left: $base * 3.5;
  }

  :deep(.el-input-group__append) {
    overflow: hidden;

    .el-input__suffix-inner {
      display: flex;
    }

    .grayText {
      color: #666;

      .el-input__inner {
        margin: 0;
        padding: 0 1px;
        background: #fff;
        border-radius: 0;
      }
    }

    .deepText {
      color: rgba(153, 153, 153, 0.6);

      .el-input__inner {
        margin: 0;
        padding: 0 1px;
        background: none;
        border-radius: 0;
      }
    }
  }
}
</style>
