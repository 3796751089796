// 法定单位
const unitList = [
  {
    value: '001',
    label: '台',
    tagName: '台(001)',
  },
  {
    value: '002',
    label: '座',
    tagName: '座(002)',
  },
  {
    value: '003',
    label: '辆',
    tagName: '辆(003)',
  },
  {
    value: '004',
    label: '艘',
    tagName: '艘(004)',
  },
  {
    value: '005',
    label: '架',
    tagName: '架(005)',
  },
  {
    value: '006',
    label: '套',
    tagName: '套(006)',
  },
  {
    value: '007',
    label: '个',
    tagName: '个(007)',
  },
  {
    value: '008',
    label: '只',
    tagName: '只(008)',
  },
  {
    value: '009',
    label: '头',
    tagName: '头(009)',
  },
  {
    value: '010',
    label: '张',
    tagName: '张(010)',
  },
  {
    value: '011',
    label: '件',
    tagName: '件(011)',
  },
  {
    value: '012',
    label: '支',
    tagName: '支(012)',
  },
  {
    value: '013',
    label: '枝',
    tagName: '枝(013)',
  },
  {
    value: '014',
    label: '根',
    tagName: '根(014)',
  },
  {
    value: '015',
    label: '条',
    tagName: '条(015)',
  },
  {
    value: '016',
    label: '把',
    tagName: '把(016)',
  },
  {
    value: '017',
    label: '块',
    tagName: '块(017)',
  },
  {
    value: '018',
    label: '卷',
    tagName: '卷(018)',
  },
  {
    value: '019',
    label: '副',
    tagName: '副(019)',
  },
  {
    value: '020',
    label: '片',
    tagName: '片(020)',
  },
  {
    value: '021',
    label: '组',
    tagName: '组(021)',
  },
  {
    value: '022',
    label: '份',
    tagName: '份(022)',
  },
  {
    value: '023',
    label: '幅',
    tagName: '幅(023)',
  },
  {
    value: '025',
    label: '双',
    tagName: '双(025)',
  },
  {
    value: '026',
    label: '对',
    tagName: '对(026)',
  },
  {
    value: '027',
    label: '棵',
    tagName: '棵(027)',
  },
  {
    value: '028',
    label: '株',
    tagName: '株(028)',
  },
  {
    value: '029',
    label: '井',
    tagName: '井(029)',
  },
  {
    value: '030',
    label: '米',
    tagName: '米(030)',
  },
  {
    value: '031',
    label: '盘',
    tagName: '盘(031)',
  },
  {
    value: '032',
    label: '平方米',
    tagName: '平方米(032)',
  },
  {
    value: '033',
    label: '立方米',
    tagName: '立方米(033)',
  },
  {
    value: '034',
    label: '筒',
    tagName: '筒(034)',
  },
  {
    value: '035',
    label: '千克',
    tagName: '千克(035)',
  },
  {
    value: '036',
    label: '克',
    tagName: '克(036)',
  },
  {
    value: '037',
    label: '盆',
    tagName: '盆(037)',
  },
  {
    value: '038',
    label: '万个',
    tagName: '万个(038)',
  },
  {
    value: '039',
    label: '具',
    tagName: '具(039)',
  },
  {
    value: '040',
    label: '百副',
    tagName: '百副(040)',
  },
  {
    value: '041',
    label: '百支',
    tagName: '百支(041)',
  },
  {
    value: '042',
    label: '百把',
    tagName: '百把(042)',
  },
  {
    value: '043',
    label: '百个',
    tagName: '百个(043)',
  },
  {
    value: '044',
    label: '百片',
    tagName: '百片(044)',
  },
  {
    value: '045',
    label: '刀',
    tagName: '刀(045)',
  },
  {
    value: '046',
    label: '疋',
    tagName: '疋(046)',
  },
  {
    value: '047',
    label: '公担',
    tagName: '公担(047)',
  },
  {
    value: '048',
    label: '扇',
    tagName: '扇(048)',
  },
  {
    value: '049',
    label: '百枝',
    tagName: '百枝(049)',
  },
  {
    value: '050',
    label: '千只',
    tagName: '千只(050)',
  },
  {
    value: '051',
    label: '千块',
    tagName: '千块(051)',
  },
  {
    value: '052',
    label: '千盒',
    tagName: '千盒(052)',
  },
  {
    value: '053',
    label: '千枝',
    tagName: '千枝(053)',
  },
  {
    value: '054',
    label: '千个',
    tagName: '千个(054)',
  },
  {
    value: '055',
    label: '亿支',
    tagName: '亿支(055)',
  },
  {
    value: '056',
    label: '亿个',
    tagName: '亿个(056)',
  },
  {
    value: '057',
    label: '万套',
    tagName: '万套(057)',
  },
  {
    value: '058',
    label: '千张',
    tagName: '千张(058)',
  },
  {
    value: '059',
    label: '万张',
    tagName: '万张(059)',
  },
  {
    value: '060',
    label: '千伏安',
    tagName: '千伏安(060)',
  },
  {
    value: '061',
    label: '千瓦',
    tagName: '千瓦(061)',
  },
  {
    value: '062',
    label: '千瓦时',
    tagName: '千瓦时(062)',
  },
  {
    value: '063',
    label: '千升',
    tagName: '千升(063)',
  },
  {
    value: '067',
    label: '英尺',
    tagName: '英尺(067)',
  },
  {
    value: '070',
    label: '吨',
    tagName: '吨(070)',
  },
  {
    value: '071',
    label: '长吨',
    tagName: '长吨(071)',
  },
  {
    value: '072',
    label: '短吨',
    tagName: '短吨(072)',
  },
  {
    value: '073',
    label: '司马担',
    tagName: '司马担(073)',
  },
  {
    value: '074',
    label: '司马斤',
    tagName: '司马斤(074)',
  },
  {
    value: '075',
    label: '斤',
    tagName: '斤(075)',
  },
  {
    value: '076',
    label: '磅',
    tagName: '磅(076)',
  },
  {
    value: '077',
    label: '担',
    tagName: '担(077)',
  },
  {
    value: '078',
    label: '英担',
    tagName: '英担(078)',
  },
  {
    value: '079',
    label: '短担',
    tagName: '短担(079)',
  },
  {
    value: '080',
    label: '两',
    tagName: '两(080)',
  },
  {
    value: '081',
    label: '市担',
    tagName: '市担(081)',
  },
  {
    value: '083',
    label: '盎司',
    tagName: '盎司(083)',
  },
  {
    value: '084',
    label: '克拉',
    tagName: '克拉(084)',
  },
  {
    value: '085',
    label: '市尺',
    tagName: '市尺(085)',
  },
  {
    value: '086',
    label: '码',
    tagName: '码(086)',
  },
  {
    value: '088',
    label: '英寸',
    tagName: '英寸(088)',
  },
  {
    value: '089',
    label: '寸',
    tagName: '寸(089)',
  },
  {
    value: '095',
    label: '升',
    tagName: '升(095)',
  },
  {
    value: '096',
    label: '毫升',
    tagName: '毫升(096)',
  },
  {
    value: '097',
    label: '英加仑',
    tagName: '英加仑(097)',
  },
  {
    value: '098',
    label: '美加仑',
    tagName: '美加仑(098)',
  },
  {
    value: '099',
    label: '立方英尺',
    tagName: '立方英尺(099)',
  },
  {
    value: '101',
    label: '立方尺',
    tagName: '立方尺(101)',
  },
  {
    value: '110',
    label: '平方码',
    tagName: '平方码(110)',
  },
  {
    value: '111',
    label: '平方英尺',
    tagName: '平方英尺(111)',
  },
  {
    value: '112',
    label: '平方尺',
    tagName: '平方尺(112)',
  },
  {
    value: '115',
    label: '英制马力',
    tagName: '英制马力(115)',
  },
  {
    value: '116',
    label: '公制马力',
    tagName: '公制马力(116)',
  },
  {
    value: '118',
    label: '令',
    tagName: '令(118)',
  },
  {
    value: '120',
    label: '箱',
    tagName: '箱(120)',
  },
  {
    value: '121',
    label: '批',
    tagName: '批(121)',
  },
  {
    value: '122',
    label: '罐',
    tagName: '罐(122)',
  },
  {
    value: '123',
    label: '桶',
    tagName: '桶(123)',
  },
  {
    value: '124',
    label: '扎',
    tagName: '扎(124)',
  },
  {
    value: '125',
    label: '包',
    tagName: '包(125)',
  },
  {
    value: '126',
    label: '箩',
    tagName: '箩(126)',
  },
  {
    value: '127',
    label: '打',
    tagName: '打(127)',
  },
  {
    value: '128',
    label: '筐',
    tagName: '筐(128)',
  },
  {
    value: '129',
    label: '罗',
    tagName: '罗(129)',
  },
  {
    value: '130',
    label: '匹',
    tagName: '匹(130)',
  },
  {
    value: '131',
    label: '册',
    tagName: '册(131)',
  },
  {
    value: '132',
    label: '本',
    tagName: '本(132)',
  },
  {
    value: '133',
    label: '发',
    tagName: '发(133)',
  },
  {
    value: '134',
    label: '枚',
    tagName: '枚(134)',
  },
  {
    value: '135',
    label: '捆',
    tagName: '捆(135)',
  },
  {
    value: '136',
    label: '袋',
    tagName: '袋(136)',
  },
  {
    value: '139',
    label: '粒',
    tagName: '粒(139)',
  },
  {
    value: '140',
    label: '盒',
    tagName: '盒(140)',
  },
  {
    value: '141',
    label: '合',
    tagName: '合(141)',
  },
  {
    value: '142',
    label: '瓶',
    tagName: '瓶(142)',
  },
  {
    value: '143',
    label: '千支',
    tagName: '千支(143)',
  },
  {
    value: '144',
    label: '万双',
    tagName: '万双(144)',
  },
  {
    value: '145',
    label: '万粒',
    tagName: '万粒(145)',
  },
  {
    value: '146',
    label: '千粒',
    tagName: '千粒(146)',
  },
  {
    value: '147',
    label: '千米',
    tagName: '千米(147)',
  },
  {
    value: '148',
    label: '千英尺',
    tagName: '千英尺(148)',
  },
  {
    value: '149',
    label: '百万贝可',
    tagName: '百万贝可(149)',
  },
  {
    value: '163',
    label: '部',
    tagName: '部(163)',
  },
];

export default unitList;
