import {
  reactive, computed,
} from 'vue';

import {
  // ElMessage,
  // ElNotification,
  // ElMessageBox,
} from 'element-plus';

import unitAry from '../../../../configs/unit';// 单位数组
import WeightInfoMethodsClass from './weightInfoMethodsClass';

/**
 *
 * @param {*} myDatas  createCustomsDatasBus
 * @param {*} weightInfoMethodsClass  实例化的数据
 */
export default function (myDatas) {
  // 法定单位必须为整数的ID
  const initNums = ['001', '002', '003', '004', '005', '006', '007', '008', '010', '011', '012', '013', '014', '015', '016', '017', '018', '019', '020', '023', '025', '026', '028', '031', '034', '048', '131', '132', '133', '134', '135', '139', '140', '141', '142', '163'];

  const {
    MAXITEM, weightFormRef,
    tableDatas, storeDatas, datas,
    optionsAry,
  } = myDatas;

  const weightInfoMethodsClass = new WeightInfoMethodsClass(myDatas, null);

  /** 鼠标失去焦点重新修正值至datas中
   *
   * @param {any} e 验证过后的返回值
   * @param {string} currentKey 当前basedatas的key
   * @param {bool} isFixed 是否保留2位小数[true:保留2位小数]
   */
  const validatorFun = (e, currentKey, isFixed, baseDatas, toFixedNum = 2) => {
    const v = Number(e);// 去除空格
    // 解决为0报错
    if (!Number.isNaN(v)) {
      if (isFixed) {
        datas.detailsDatas[currentKey] = v.toFixed(toFixedNum);
      } else {
        datas.detailsDatas[currentKey] = v.toString();
      }
    }
    weightFormRef.value.validateField(`${currentKey}`);
  };

  /** 自定义select输入方式
     * @param {String} e 输入的信息
     * @param {String} nodeName 当前操作节点
     * @param {Object} baseDatasNode 当前操作节点对象
     * 原理：根据blur,focus来操作数据备份、改变、恢复的数据调配
     */

  const fileterOptionsHandleFunc = (e, nodeName, baseDatasNode) => {
    e = e.trim().replaceAll(' ', '');
    const currentOptionsAry = optionsAry[nodeName].backupAry;
    const tempAry = [];

    // 输入的值是否匹配
    currentOptionsAry.forEach((item) => {
      if (item.tagName.indexOf(e) !== -1) { // 找到输入对应数据
        tempAry.push(item);
      }
    });
    baseDatasNode[nodeName].selectOptions = tempAry; // 将查询结果展示到前端
    optionsAry[nodeName].resAry = tempAry;// 将查询结果放置共享$BUS
  };

  /** 【是否混装】切换时更新相关输入框样式及状态
   *
   *
   */
  function isBeforeConfirm(baseDatasNode) {
    weightInfoMethodsClass.baseDatas = baseDatasNode;
    weightInfoMethodsClass.mixinState();// 混装点击清除红点及输入框内容
    const { packageState } = datas.detailsDatas;
    // 改变按钮区域展示类别
    weightInfoMethodsClass.setBtnState();// 设定按钮内容
    baseDatasNode.moreProductCtns.disabledState = !packageState;// 编辑状态
  }

  /** 下拉框展示与隐藏
   * @param {Bool} e 展示true 隐藏false
   * @param {String} nodeName 节点信息
   * @param {Object} baseDatasNode
   */
  function vsbChangeOptions(e, nodeName, baseDatasNode) {
    weightInfoMethodsClass.baseDatas = baseDatasNode;
    if (e) { // 展示
      const { selectOptions } = baseDatasNode[nodeName];
      optionsAry[nodeName].backupAry = selectOptions;// 初始获取当前值
      optionsAry[nodeName].resAry = [];
    } else { // 隐藏
      if (nodeName === 'packageState') {
        weightInfoMethodsClass.toolTipInfoOfNetAndGw();// 设置混装提示信息
        const { grossWt, netWt } = myDatas.datas.detailsDatas;
        if (!!grossWt || !!netWt) {
          weightInfoMethodsClass.validateAllFileds(['grossWt', 'netWt'], false);
        }
      }

      // 将数据还原
      const { resAry } = optionsAry[nodeName];// 输入值
      if (resAry.length > 0) { // 有数据
        datas.detailsDatas[nodeName] = resAry[0].value;// 取查询结果第一个值
        if (nodeName === 'unit') {
          baseDatasNode[nodeName].optionChangeFuncs();
        } else if (nodeName === 'packageState') {
          isBeforeConfirm(baseDatasNode);// 【是否混装】切换时更新相关输入框样式及状态
        }
      }

      baseDatasNode[nodeName].selectOptions = optionsAry[nodeName].backupAry;// 还原备份值
      optionsAry[nodeName].backupAry = [];// 清除备份值
      optionsAry[nodeName].resAry = [];// 清除查找到的结果
      weightInfoMethodsClass.setBtnState();// 根据值是否更改来设置当前按钮;
    }
  }
  /** 删除不需要的【产品数量】单位值 */
  const setOptionQTY = () => {
    const tempAry = [];
    unitAry.forEach((item) => {
      if (!['克', '千克', '克拉'].includes(item.label)) {
        tempAry.push(item);
      }
    });
    return tempAry;
  };
  const delayTimeout = 500;

  /** 渲染页面的数据
   *  不要随意更改数据名称，有些函数以对象名为函数名称
   */
  const baseDatas = reactive({
    // 海关编码
    hsCode: {
      label: '海关编码',
      placeholder: '点击查询选择10位数海关编码',
      tooltip: '点击对话框查询或修改【海关编码】',
      value: '',
      required: true,
      disabledState: false,
      readonlyVal: true, // 只读
      rules: [
        {
          asyncValidator: (rule, value) => new Promise((resolve, reject) => {
            if (value === '') {
              reject(new Error('必填内容'));
            } else {
              resolve(value);
            }
          }),
          trigger: ['blur', 'change'],
        },
      ],
      clkFuncs: () => {
        // 打开查询hscode侧边栏
        storeDatas.drawerHscode = true;
      },
      inputFuncs: () => {
        weightInfoMethodsClass.baseDatas = baseDatas;
        weightInfoMethodsClass.setBtnState();// 根据值是否更改来设置当前按钮;
      },
      blurFuncs: () => { },
    },
    // 品名
    productName: {
      label: '品名',
      placeholder: '点击对话框录入或修改品名',
      tooltip: computed(() => (!datas.detailsDatas.productName ? '点击后查询产品名称' : datas.detailsDatas.productName)),
      value: '',
      required: true,
      readonlyVal: true,
      disabledState: false,
      rules: [
        {
          asyncValidator: (rule, value) => new Promise((resolve, reject) => {
            const { config, rowClkBus } = myDatas;
            const { nextStateus, nextStep } = myDatas.stateContent;
            const allProNameAry = tableDatas.map((item) => item.productName);

            // 如果是nextStateus是false,modifyState(修改状态)则删除当前步的品名再校验
            let sliceProNameAry = [];
            if (value === '') {
              reject(new Error('必填内容'));
            } else if (!nextStateus || config.modifyState) { // 修改状态或混装编辑状态时
              const idx = config.modifyState ? rowClkBus.rangeClkId : nextStep;
              sliceProNameAry = [...allProNameAry.slice(0, idx), ...allProNameAry.slice(idx + 1, allProNameAry.length)];
            } else {
              sliceProNameAry = tableDatas.map((item) => item.productName);
            }
            if (
              config.disabledForm === false// 可编辑状态下
              && sliceProNameAry.includes(value.toString())
            ) {
              reject(new Error('当前品名已存在,请修改当前值'));
            } else {
              resolve(value);
            }
          }),
          trigger: ['blur', 'change'],
        },
      ],
      clkFuncs: () => {
        // 先将productsInfo共享数据共享至createCustomsDats中的tempHscodee及tempProductName
        // 为了方便申报要素侧边栏判断是哪里传过来的数据
        // const { productName, hsCode, detailFormDatas } = productsInfo;
        const { productName, hsCode } = datas.detailsDatas;

        storeDatas.argOfProductName = {
          hsCode: hsCode || '',
          productName: productName ? productName.split('  ')[0] : '',
          exampleRef: productName ? productName.split('  ')[1] : '',
          enterDetailsType: true, // 录入信息页点击进入
          unitJointStr: datas.detailsDatas.unitJointStr, // 法定单位组合字符串
        };
        // 【海关编码】与【品名】均存在则打开【申报申报要素】反之打开【海关编码查询】
        if (!!hsCode && !!productName) {
          //  有
          storeDatas.drawerHscode = true; // 海关编码查
          storeDatas.drawerDetails = true; // 海关申报要素
        } else {
          // 无
          storeDatas.drawerHscode = true; // 海关申报要素
        }
      },
      inputFuncs: () => {
        weightInfoMethodsClass.baseDatas = baseDatas;
        weightInfoMethodsClass.setBtnState();// 根据值是否更改来设置当前按钮;
      },
      blurFuncs: () => { },
    },
    // 是否混装
    packageState: {
      label: '是否混装',
      placeholder: '请选择',
      tooltip: '【混装】:多个产品混合装箱 ,【非混装】:每箱为单一产品无参杂其他品类',
      value: 0,
      disabledState: false, // true：不可编辑，false:可编辑
      selectOptions: [
        { value: 0, label: '非混装', tagName: '0 - 非混装' },
        { value: 1, label: '混装', tagName: '1 - 混装' },
      ],
      optionChangeFuncs: async () => { // 改变值
        isBeforeConfirm(baseDatas);// 【是否混装】切换时更新相关输入框样式及状态
      },
      fileterOptions: (e) => { // 自定义输入
        fileterOptionsHandleFunc(e, 'packageState', baseDatas);
      },
      vsbChangeOptions: (e) => { // 下拉框出现与否
        vsbChangeOptions(e, 'packageState', baseDatas);
      },
      blurFuncs: () => { },
    },
    // 混装品名数量
    moreProductCtns: {
      label: '混装品类数量',
      placeholder: '请输入混装产品类目数量',
      setTimeoutVal: null,
      // 混装箱内混装了多少种不同的产品，值不能大于50个产品项
      tooltip: computed(() => {
        const { packageState } = datas.detailsDatas;
        const maxLen = MAXITEM - tableDatas.length;
        return Number(packageState) === 0 ? '非必填项' : `限量${MAXITEM}个品名,可录入最大值:${maxLen}`;
      }),
      value: '',
      required: false,
      disabledState: true,
      rules: [
        {

          asyncValidator: (rule, value) => new Promise((resolve, reject) => {
            if (value === '') {
              reject(new Error('必填内容'));
            } else if (Number.isNaN(Number(value))) {
              // datas.detailsDatas.moreProductCtns = '';
              reject(new Error('请输入数字内容'));
            } else if (!/^[1-9]{1}\d*$/.test(value)) {
              reject(new Error('请输入正整数'));
            } else if (Number(value) < 2) {
              reject(new Error('单独品类请选择【非混装】'));
            } else if (
              baseDatas.moreProductCtns.disabledState === false
              && datas.detailsDatas.packageState === 1
              && Number(value) > (MAXITEM - tableDatas.length)
            ) {
              reject(new Error(`超出可录总数限制${MAXITEM} 当前值不能超:${MAXITEM - tableDatas.length}`));
            } else {
              resolve(value);
            }
          }),
          trigger: ['blur', 'change'],
        },
      ],
      inputFuncs: (e) => { // 失去焦点
        clearTimeout(baseDatas.moreProductCtns.setTimeoutVal);
        baseDatas.moreProductCtns.setTimeoutVal = setTimeout(() => {
          weightInfoMethodsClass.baseDatas = baseDatas;
          weightInfoMethodsClass.setBtnState();// 根据值是否更改来设置当前按钮;
          const { stateContent } = myDatas;
          validatorFun(e, 'moreProductCtns', false, baseDatas);
          weightInfoMethodsClass.validateAllFileds('moreProductCtns', false).then((isError) => {
            if (!isError) { // 无误
              // 混装【下一步】按钮的提示内容变更
              stateContent.nextTooltip = `剩余${datas.detailsDatas.moreProductCtns - 1}个品名未录入`;
            } else {
              stateContent.nextTooltip = '【混装品类数量】未初始化完成';
            }
          });
        }, delayTimeout);
      },
      blurFuncs: () => { },
    },
    // 箱数
    ctns: {
      label: '箱数',
      placeholder: computed(() => (Number(datas.detailsDatas.packageState) === 1 ? '【混装】品类总箱数' : '本品名总箱数')),
      setTimeoutVal: null,
      tooltip: computed(() => (Number(datas.detailsDatas.packageState) === 1 ? '【混装】品类总箱数' : '本品名总箱数')),
      value: '',
      required: true,
      disabledState: false,
      rules: [
        {
          asyncValidator: (rule, value) => new Promise((resolve, reject) => {
            if (value === '') {
              reject(new Error('必填内容'));
            } else if (!Number(value)) {
              reject(new Error('请输入数字内容'));
            } else if (!/^[0-9]{1}\d*$/.test(value)) {
              reject(new Error('请输入正整数'));
            } else if (Number(value) < 1) {
              reject(new Error('请输入正确的箱数'));
            } else {
              resolve(value);
            }
          }),
          trigger: ['blur', 'change'],
        },
      ],
      inputFuncs: () => {
        clearTimeout(baseDatas.ctns.setTimeoutVal);
        baseDatas.ctns.setTimeoutVal = setTimeout(() => {
          weightInfoMethodsClass.baseDatas = baseDatas;
          weightInfoMethodsClass.setBtnState();// 根据值是否更改来设置当前按钮;
        }, delayTimeout);
      },
      blurFuncs: () => { },
    },

    // 毛重
    grossWt: {
      label: '毛重',
      placeholder: '当前品名毛重',
      setTimeoutVal: null,
      tooltip: '本产品毛重',
      value: '',
      required: true,
      appendVal: true, // 添加单位
      disabledState: false,
      appendLabel: '千克',
      rules: [
        {
          // 离开时判断值
          asyncValidator: (rule, value) => new Promise((resolve, reject) => {
            weightInfoMethodsClass.baseDatas = baseDatas;
            weightInfoMethodsClass.toolTipInfoOfNetAndGw();// 设置混装信息
            const { stateContent, config, rowClkBus } = myDatas;
            const { packageState, netWt, grossWt } = datas.detailsDatas;
            const { nextStep } = stateContent;

            if (config.disabledForm) {
              baseDatas.netWt.tooltip = '本产品净重';
              baseDatas.grossWt.tooltip = '本产品毛重';
              return; // 不可编辑再不需要作如下校验
            }
            const mixinNoteInfo = weightInfoMethodsClass.mixinNoteInfo();
            const {
              startIdx, downTotalNet, upTotalNet, startCtnIdx,
              // endIdx, endCtnIdx,
            } = mixinNoteInfo !== null && mixinNoteInfo;
            if (value === '') {
              reject(new Error('必填内容'));
            } else if (!/^[0-9]\d*\.?\d{0,2}$/.test(value)) {
              reject(new Error('请输入正确值:正数或浮点数保留两位小数'));
            } else if (
              Number(packageState) === 0
              && Number(value) <= Number(netWt)
            ) { // 混装状态下 可以为0，不为混装时则必须满足'毛重不能小于净重」
              reject(new Error('毛重需大于净重1'));
            } else if (
              (!config.modifyState)
              && Number(packageState) === 1
              && nextStep === 0
              && Number(value) === 0
            ) { // 混装初始化第一步时 毛重为0
              reject(new Error('混装状态时首个毛重不能为"0"'));
            } else if (
              (!config.modifyState)
              && Number(packageState) === 1
              && nextStep === 0
              && (Number(value) <= Number(netWt) + downTotalNet)
            ) { // 当前毛重<=混装向下的所有净重和
              reject(new Error('毛重需大于净重'));
            } else if (
              (!config.modifyState)
              && Number(packageState) === 1
              && nextStep !== 0
              && (Number(grossWt) === 0 ? Number(tableDatas[startIdx].grossWt) <= (downTotalNet + upTotalNet + Number(netWt)) : Number(grossWt) <= (downTotalNet + Number(netWt)))) { // 混装 毛重非混装时 毛重小于净重
              reject(new Error('毛重需大于净重！'));
            } else if (
              (config.modifyState)
              && Number(packageState) === 1
              && rowClkBus.rangeClkId === startCtnIdx
              && Number(value) === 0
            ) { // 编辑状态 混装首行不能为0
              reject(new Error('修改混装状态首个毛重不能为"0"'));
            } else if (
              (config.modifyState)
              && Number(packageState) === 1
              && (Number(grossWt) === 0 ? Number(tableDatas[startIdx].grossWt) <= (downTotalNet + upTotalNet + Number(netWt)) : Number(grossWt) <= (downTotalNet + Number(netWt)))//  修改状态下，混装模式，毛重混装:上下文净重之和小于混装毛重 非等于混装:小于毛重
            ) { // 编辑状态 非首行
              reject(new Error('毛重需大于净重!!'));
            } else {
              resolve(value);
            }
          }),
          trigger: ['blur', 'change'],
        },
      ],
      inputFuncs: () => {
        clearTimeout(baseDatas.grossWt.setTimeoutVal);
        baseDatas.grossWt.setTimeoutVal = setTimeout(() => {
          weightInfoMethodsClass.baseDatas = baseDatas;
          weightInfoMethodsClass.setBtnState();// 根据值是否更改来设置当前按钮;
        }, delayTimeout);
      },
      blurFuncs: (e) => {
        weightInfoMethodsClass.baseDatas = baseDatas;
        weightInfoMethodsClass.validateAllFileds(['netWt', 'grossWt'], false).then((isError) => {
          const { detailsDatas } = datas;
          validatorFun(e, 'grossWt', true, baseDatas);
          if (!isError && detailsDatas.unit === '035') {
            // 如果法定单位为 035千克时，法定单位数量即为净重
            detailsDatas.unitQTY = detailsDatas.netWt;
            // 重新计算价格
            weightInfoMethodsClass.autoComputedVal('unitQTY', false);
          }
        });
      },
    },
    // 产品数量
    productQTY: {
      label: '产品数量',
      placeholder: '请输入数量',
      setTimeoutVal: null,
      tooltip: '本品名下产品总数量',
      value: '',
      required: true,
      appendVal: true, // 添加选择器
      appendSelect: true, // 选择器
      disabledState: false,
      appendOptions: {
        key: 'qtyUnit',
        placeholder: '请选择单位',
        value: '007',
        selectOptions: setOptionQTY(),
        fileterOptions: (e) => {
          const tempAry = [];
          setOptionQTY().forEach((item) => {
            if (item.tagName.toLocaleUpperCase().includes(e.toLocaleUpperCase())) {
              tempAry.push({ ...item });
            }
          });
          baseDatas.productQTY.appendOptions.selectOptions = [...tempAry];
          if (tempAry.length > 0) {
            // 查询到有值
            myDatas.productQtyOptions.value = tempAry[0].value;
          } else {
            // 无值则将临时变量置空
            myDatas.productQtyOptions.value = '';
          }
        },
        vsbChangeOptions: (e) => {
          weightInfoMethodsClass.baseDatas = baseDatas;
          const { productQtyOptions, oldProductQTY } = myDatas;
          const { detailsDatas } = myDatas.datas;
          // 下拉框展示
          if (e) { // 显示下拉框
            oldProductQTY.value = detailsDatas.qtyUnit;
            detailsDatas.qtyUnit = '';
          } else { // 隐藏下拉框
            const res = productQtyOptions.value;
            detailsDatas.qtyUnit = (res !== '') ? res : oldProductQTY.value;
            baseDatas.productQTY.appendOptions.value = (res !== '') ? res : oldProductQTY.value;
            productQtyOptions.value = '';
            baseDatas.productQTY.appendOptions.selectOptions = [...setOptionQTY()];// 复原
            if (detailsDatas.unit.toString() === detailsDatas.qtyUnit.toString()) { // 与【法定单位】相同
              datas.detailsDatas.unitQTY = datas.detailsDatas.productQTY;
              // 重新计算价格
              weightInfoMethodsClass.autoComputedVal('unitQTY', false);
            }
          }
        },
        optionChangeFuncs: (e) => {
          // 下拉点击更新数据
          if (e !== '') {
            myDatas.productQtyOptions.value = e;
          }
        },

      },
      rules: [
        {
          asyncValidator: (rule, value) => new Promise((resolve, reject) => {
            if (value === '') {
              reject(new Error('必填内容'));
            } else if (!/^[1-9]\d*\.?\d{0,2}$/.test(value)) {
              reject(new Error('请输入正确值'));
            } else if (datas.detailsDatas.qtyUnit === '') {
              reject(new Error('请选择单位'));
            } else if (initNums.includes(datas.detailsDatas.qtyUnit) && !/^\d+\.?0{0,2}$/.test(value)) {
              reject(new Error('单位要求值为正整数'));
            } else {
              resolve(value);
            }
          }),
          trigger: ['blur', 'change'],
        },
      ],
      inputFuncs: () => {
        clearTimeout(baseDatas.productQTY.setTimeoutVal);
        baseDatas.productQTY.setTimeoutVal = setTimeout(() => {
          weightInfoMethodsClass.baseDatas = baseDatas;
          weightInfoMethodsClass.setBtnState();// 根据值是否更改来设置当前按钮;
        }, delayTimeout);
      },
      blurFuncs: (e) => {
        const { detailsDatas } = datas;
        validatorFun(e, 'productQTY', true, baseDatas);
        if (detailsDatas.unit === detailsDatas.qtyUnit) { // 单位与【产品数量】单位相同则值默认为相同
          detailsDatas.unitQTY = detailsDatas.productQTY;
          // 重新计算价格
          weightInfoMethodsClass.autoComputedVal('unitQTY', false);
        }
      },
    },
    // 净重
    netWt: {
      label: '净重',
      placeholder: '请输入净重',
      setTimeoutVal: null,
      tooltip: '本产品净重',
      value: '',
      required: true,
      appendVal: true, // 添加单位
      disabledState: false,
      appendLabel: '千克',
      rules: [
        {
          // 离开时判断值
          asyncValidator: (rule, value) => new Promise((resolve, reject) => {
            weightInfoMethodsClass.baseDatas = baseDatas;
            weightInfoMethodsClass.toolTipInfoOfNetAndGw();// 设置混装信息
            const {
              stateContent, config, rowClkBus,
              // mixinpackOBJ,
            } = myDatas;
            const { nextStep } = stateContent;
            const { grossWt, packageState, netWt } = datas.detailsDatas;

            if (config.disabledForm) {
              baseDatas.netWt.tooltip = '本产品净重';
              baseDatas.grossWt.tooltip = '本产品毛重';
              return; // 不可编辑再不需要作如下校验
            }

            const mixinNoteInfo = weightInfoMethodsClass.mixinNoteInfo();
            const {
              startIdx, downTotalNet, upTotalNet,
              startCtnIdx,
              // endCtnIdx, endIdx,
            } = mixinNoteInfo !== null && mixinNoteInfo;
            if (value === '') {
              reject(new Error('必填内容'));
            } else if (!Number(value)) {
              reject(new Error('请输入正确值'));
            } else if (!/^\d*\.?\d{0,2}$/.test(value)) {
              reject(new Error('请输入正确值，正数且保留两位小数'));
            } else if (
              Number(packageState) === 0
              && Number(value) >= Number(grossWt)
            ) {
              reject(new Error('净重需<毛重'));
            } else if (
              (!config.modifyState)
              && Number(packageState) === 1
              && nextStep === 0
              && ((Number(netWt) + downTotalNet) >= Number(grossWt))
            ) { // 非修改状态，第一步 净重≥毛重
              reject(new Error('净重需<毛重,鼠标停留查看详情!'));
            } else if (
              (!config.modifyState)
              && (Number(packageState) === 1)
              && nextStep !== 0
              && ((Number(grossWt) === 0) ? (downTotalNet + upTotalNet + Number(netWt)) >= Number(tableDatas[startIdx].grossWt) : ((downTotalNet + Number(netWt)) >= Number(grossWt)))
            ) { // 当前毛重有值：向下净重+当前净重>当前毛重 |  当前毛重无值：向下净重+当前净重+向上净重>startIdx的毛重
              reject(new Error('净重需<毛重,鼠标停留查看详情!!'));
            } else if (
              config.modifyState
              && (Number(packageState) === 1)
              && rowClkBus.rangeClkId === startCtnIdx
              && (((Number(grossWt) === 0) && (startIdx !== -1)) ? (downTotalNet + upTotalNet + Number(netWt)) >= Number(tableDatas[startIdx].grossWt) : ((downTotalNet + Number(netWt)) >= Number(grossWt)))
            ) { // 修改状态，此批次混装第一行
              reject(new Error('净重需<毛重,鼠标停留查看详情!!!'));
            } else if (
              config.modifyState
              && (Number(packageState) === 1)
              && rowClkBus.rangeClkId !== startCtnIdx
              && (((Number(grossWt) === 0) && (startIdx !== -1)) ? (downTotalNet + upTotalNet + Number(netWt)) >= Number(tableDatas[startIdx].grossWt) : ((downTotalNet + Number(netWt)) >= Number(grossWt)))
            ) { // 修改状态，此批次混装非首行
              reject(new Error('净重需<毛重,鼠标停留查看详情 !!!!'));
            } else {
              resolve(value);
            }
          }),
          trigger: ['blur', 'change'],
        },
      ],
      inputFuncs: () => {
        clearTimeout(baseDatas.netWt.setTimeoutVal);
        baseDatas.netWt.setTimeoutVal = setTimeout(() => {
          weightInfoMethodsClass.baseDatas = baseDatas;
          weightInfoMethodsClass.setBtnState();// 根据值是否更改来设置当前按钮;
        }, delayTimeout);
      },
      blurFuncs: (e) => {
        weightInfoMethodsClass.baseDatas = baseDatas;
        // 校验【净重】值是否有误
        weightInfoMethodsClass.validateAllFileds(['netWt', 'grossWt'], false).then((isError) => {
          const { detailsDatas } = datas;
          validatorFun(e, 'netWt', true, baseDatas);
          if (!isError && detailsDatas.unit === '035') { // 无误
            // 如果法定单位为 035千克时，法定单位数量即为净重
            detailsDatas.unitQTY = detailsDatas.netWt;
            // 重新计算价格
            weightInfoMethodsClass.autoComputedVal('unitQTY', false);
          }
        });
      },
    },

    // 法定单位
    unit: {
      label: '法定单位',
      placeholder: '请选择',
      tooltip: '计算价格单位属性',
      value: '',
      disabledState: false,
      // selectOptions: [
      //   { value: '035', label: '千克' },
      //   { value: '007', label: '个' },
      // ],
      selectOptions: [],
      optionChangeFuncs: () => {
        weightInfoMethodsClass.baseDatas = baseDatas;
        weightInfoMethodsClass.rangeUnitFunc();// 影响计算三要素
        weightInfoMethodsClass.setBtnState();// 根据值是否更改来设置当前按钮;
      },
      fileterOptions: (e) => { // 自定义输入
        fileterOptionsHandleFunc(e, 'unit', baseDatas);
        weightInfoMethodsClass.baseDatas = baseDatas;
        weightInfoMethodsClass.setBtnState();// 根据值是否更改来设置当前按钮;
      },
      vsbChangeOptions: (e) => { // 下拉框出现与否
        vsbChangeOptions(e, 'unit', baseDatas);
      },
      blurFuncs: () => { },
    },
    // 法定单位数量
    unitQTY: {
      label: '法定单位值',
      placeholder: '请输入法定单位数量',
      setTimeoutVal: null,
      tooltip: '根据【法定单位】填写值以此作为计价单元',
      value: '',
      required: true,
      appendVal: true, // 添加单位
      disabledState: false,
      appendLabel: computed(() => {
        // 后缀单位与法定单位保持一致
        // 获取到unit单位key
        const key = datas.detailsDatas.unit;
        let v = '';
        baseDatas.unit.selectOptions.forEach((e) => {
          if (e.value === key) {
            v = e.label;
          }
        });
        return v;
      }),
      rules: [
        {
          asyncValidator: (rule, value) => new Promise((resolve, reject) => {
            if (value === '') {
              reject(new Error('必填内容'));
            } else if (!Number(value)) {
              // 不为数字
              reject(new Error('请输入正确值~'));
            } else if (datas.detailsDatas.unit === '035' && Number(value) !== Number(datas.detailsDatas.netWt)) {
              // 判断单位为千克且净重存在，且净重不为空
              // 当法定单位为千克时并且法定数量值不等于净重
              reject(new Error('[法定单位值]须与[净重]值相等'));
            } else if (Number(datas.detailsDatas.unit) === Number(datas.detailsDatas.qtyUnit)
              && Number(value) !== Number(datas.detailsDatas.productQTY)) {
              // 判断单位为千克且净重存在，且净重不为空
              // 当法定单位为千克时并且法定数量值不等于净重
              reject(new Error('[法定单位值]须与[产品数量值]相等'));
            } else if (!/^[0-9]\d*\.?\d{0,2}$/.test(value)) {
              reject(new Error('请输入正确值~~'));
            } else if (initNums.includes(datas.detailsDatas.unit) && !/^\d+\.?0{0,2}$/.test(value)) {
              reject(new Error('【法定单位】要求值为正整数'));
            } else {
              resolve(value);
            }
          }),
          trigger: ['blur', 'change'],
        },
      ],
      inputFuncs: () => {
        clearTimeout(baseDatas.unitQTY.setTimeoutVal);
        baseDatas.unitQTY.setTimeoutVal = setTimeout(() => {
          weightInfoMethodsClass.baseDatas = baseDatas;
          weightInfoMethodsClass.setBtnState();// 根据值是否更改来设置当前按钮;
        }, delayTimeout);
      },
      blurFuncs: (e) => {
        weightInfoMethodsClass.baseDatas = baseDatas;
        validatorFun(e, 'unitQTY', true, baseDatas);
        weightInfoMethodsClass.autoComputedVal('unitQTY');
      },
    },
    // 单价
    unitPrice: {
      label: '单价(USD)',
      placeholder: '请输入单价',
      setTimeoutVal: null,
      tooltip: '联动计算 自动生成总价',
      value: '',
      required: true,
      disabledState: false,
      rules: [
        {
          // 离开时判断值
          asyncValidator: (rule, value) => new Promise((resolve, reject) => {
            if (value === '') {
              reject(new Error('必填内容'));
            } else if (Number.isNaN(Number(value))) {
              reject(new Error('请输入正确值!'));
            } else if (Number(value) === 0) {
              reject(new Error('请输入正数'));
            } else if (!/^\d*\.?\d{0,4}$/.test(value)) {
              reject(new Error('请输入正确值:正整数或保留四位小数'));
            } else {
              resolve(value);
            }
          }),
          trigger: ['blur', 'change'],
        },
      ],
      inputFuncs: () => {
        clearTimeout(baseDatas.unitPrice.setTimeoutVal);
        baseDatas.unitPrice.setTimeoutVal = setTimeout(() => {
          weightInfoMethodsClass.baseDatas = baseDatas;
          weightInfoMethodsClass.setBtnState();// 根据值是否更改来设置当前按钮;
        }, delayTimeout);
      },
      blurFuncs: (e) => {
        weightInfoMethodsClass.baseDatas = baseDatas;
        validatorFun(e, 'unitPrice', true, baseDatas, 4);
        weightInfoMethodsClass.autoComputedVal('unitPrice');
      },
    },
    // 总价
    totalAmount: {
      label: '总价(USD)',
      placeholder: '请输入总价',
      setTimeoutVal: null,
      tooltip: '联动计算 自动生成单价',
      value: '',
      required: true,
      disabledState: false,
      rules: [
        {
          // 离开时判断值
          asyncValidator: (rule, value) => new Promise((resolve, reject) => {
            if (value === '') {
              reject(new Error('必填内容'));
            } else if (Number.isNaN(Number(value))) {
              reject(new Error('请输入正确值!'));
            } else if (!/^\d*\.?\d{0,4}$/.test(value)) {
              reject(new Error('请输入正确值:正整数或保留四位小数'));
            } else {
              resolve(value);
            }
          }),
          trigger: ['blur', 'change'],
        },
      ],

      inputFuncs: () => {
        clearTimeout(baseDatas.totalAmount.setTimeoutVal);
        baseDatas.totalAmount.setTimeoutVal = setTimeout(() => {
          weightInfoMethodsClass.baseDatas = baseDatas;
          weightInfoMethodsClass.setBtnState();// 根据值是否更改来设置当前按钮;
        }, delayTimeout);
      },
      blurFuncs: (e) => {
        weightInfoMethodsClass.baseDatas = baseDatas;
        validatorFun(e, 'totalAmount', true, baseDatas, 4);
        weightInfoMethodsClass.autoComputedVal('totalAmount');
      },
    },
  });
  return {
    baseDatas,
  };
}
