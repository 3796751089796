<!-- 申报产品描述表格 -->
<template>
  <div class="hsDetailsTable">
    <el-table
      ref="hsDetailTableRef"
      :data="createCustomsDatasBus.tableDatas"
      style="width: 100%;"
      height="250px"
      highlight-current-row
      border
      stripe
      @current-change="handleCurrentChange"
      @row-click="rowClkFunc"
      show-summary
      :summary-method="getSummaries"
      :span-method="objectSpanMethod"
    >
      <el-table-column type="index" :index="mySetIndex" prop="ID" width="50px" label="ID"></el-table-column>
      <el-table-column prop="packageState" width="85px" label="是否混装">
        <template #header>混装状态</template>
        <template #default="scope">
          <span v-if="scope.row.packageState">混装</span>
          <span v-else>非混装</span>
        </template>
      </el-table-column>
      <el-table-column prop="hsCode" label="海关编码" width="120px" />
      <el-table-column prop="productName" label="品名" min-width="300px" show-overflow-tooltip />
      <el-table-column prop="ctns" label="箱数" />
      <el-table-column prop="grossWt" label="毛重(KGs)" min-width="100px" />
      <el-table-column prop="netWt" label="净重(KGs)" min-width="100px" />
      <el-table-column prop="productQTY" label="产品数量" width="110px" show-overflow-tooltip>
        <template #default="scope">
          <div>
            <span>{{ scope.row.productQTY }}</span>
            <span>{{ autoMakeProductCNUnit(scope.row.qtyUnit) }}</span>
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="unitQTY" label="法定单位值" width="110px" show-overflow-tooltip>
        <template #default="scope">
          <div>
            <span>{{ scope.row.unitQTY }}</span>
            <span>{{ autoMakeProductCNUnit(scope.row.unit) }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="totalAmount" label="总金额(美元)" width="110px" min-width="130px" />
    </el-table>
  </div>
</template>
<script>
import { ref, inject, nextTick } from 'vue';
import mymethods from './models/hsdetailTable/methods';

export default {
  name: 'hsDetailsTables',
  setup() {
    const hsDetailTableRef = ref(null); // $refs
    const createCustomsDatasBus = inject('createCustomsDatasBus');
    const {
      // tableDatas, // 表单响应式数据
      rowClkBus, // 行点击事件及属性
    } = createCustomsDatasBus; // 渲染数据
    nextTick(() => {
      // 页面加载完成后赋值
      createCustomsDatasBus.hsDetailTableRef.value = hsDetailTableRef.value;
    });

    // 方法
    const {
      autoMakeProductCNUnit, // 根据代码自动生成【产品数量】中文单位
      getSummaries, // 表单求和
      objectSpanMethod, // 合并行或列的计算方法
      rowClkFunc, // 行的点击事件
      mySetIndex, // 自定义表单索引ID
      handleCurrentChange, // 选中行发生变动函数
    } = mymethods(createCustomsDatasBus);
    return {
      hsDetailTableRef,
      createCustomsDatasBus,
      handleCurrentChange,
      autoMakeProductCNUnit,
      getSummaries,
      objectSpanMethod,
      mySetIndex,
      rowClkFunc, // 行点击事件
      rowClkBus,
    };
  },
};
</script>
<style lang="scss" scoped>
  .hsDetailsTable {
    border: 1pt solid rgba($hovefontcolor, 0.5);
    overflow: hidden;
    border-radius: 5pt;
    height: 100% !important;
    -webkit-overflow-scrolling: touch !important;
    &:hover {
      border-color: $hovefontcolor;
    }
    :deep(.el-table__header) {
      //头部背景色
      tr th {
        background-color: rgba($mycard, 0.5);
      }
    }
    :deep(.el-table .el-table__cell) {
      //每单元格padding
      padding: 5px 0 !important;
    }
    :deep(.el-table__empty-text) {
      //暂无数据
      position: absolute;
      left: 30%;
    }
    //设置高亮色
    :deep(.current-row) {
      .el-table__cell {
        background-color: var(--el-color-primary-light-2) !important;
      }
    }

    // 解决显示多个0出来
    :deep(.el-table__footer) {
      width: 100%;
      table-layout: fixed !important;
    }
  }
</style>
