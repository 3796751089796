// export default function () {
/**
       * @param fn 字符串函数
       * 返回一个函数
       */
// const copyFunc = (fn) => {
//   const FN = Function;
//   return FN(`return ${fn.toString()}`).call(this)();
// };
/**
     * 深拷贝
     */
const deepCopyDatas = (newDatas, oldDatas) => {
  Object.keys(oldDatas).forEach((key) => {
    switch (typeof (oldDatas[key])) {
      case 'object': { // 对象(null | Object | Array)
        if (oldDatas[key] === null) {
          newDatas[key] = null;
        } else if (oldDatas[key] instanceof Array) { // 数组
          newDatas[key] = [];
          deepCopyDatas(newDatas[key], oldDatas[key]);
        } else { // 对象
          newDatas[key] = {};
          deepCopyDatas(newDatas[key], oldDatas[key]);
        }
        break;
      }
      case 'function': { // 函数
        // newDatas[key] = copyFunc(oldDatas[key]);
        newDatas[key] = null;
        newDatas[key] = oldDatas[key];
        break;
      }
      case 'undefined': {
        newDatas[key] = undefined;
        break;
      }
      // 基本数据
      default: {
        newDatas[key] = oldDatas[key];
        break;
      }
    }
  });
  return newDatas;
};

export default {
  deepCopyDatas,
};
// }
