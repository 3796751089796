<template>
  <div class="consigneeinfo">
    <el-form ref="consigneeInfoRef" :model="consigneeInfoDatas.consneDatas" :rules="consneRules" label-width="120px"
      class="demo-ruleForm">
      <!-- <el-form-item v-for="item, index in baseDatas" :key="index" :label="item.label" :prop="index">
        <el-input size="small" :placeholder="item.placeholder"
          v-model="consigneeInfoDatas.consneDatas[index]" clearable></el-input>
      </el-form-item> -->
      <el-form-item :label="baseDatas.name.label" prop="name">
        <el-autocomplete size="small" v-model="consigneeInfoDatas.consneDatas['name']"
          :placeholder="baseDatas.name.placeholder" :hide-loading="true" :trigger-on-focus="true"
          :fetch-suggestions="manuFactureInfoClass.querySearchAsync" @select="manuFactureInfoClass.handleSelect"
          popper-class="test_class"></el-autocomplete>
      </el-form-item>
      <el-form-item :label="baseDatas.tel.label" prop="tel">
        <el-input size="small" :placeholder="baseDatas.tel.placeholder" v-model="consigneeInfoDatas.consneDatas['tel']"
          clearable></el-input>
      </el-form-item>
      <el-form-item :label="baseDatas.contactName.label" prop="contactName">
        <el-input size="small" :placeholder="baseDatas.contactName.placeholder"
          v-model="consigneeInfoDatas.consneDatas['contactName']" clearable></el-input>
      </el-form-item>
      <el-form-item :label="baseDatas.address.label" prop="address">
        <el-input size="small" :placeholder="baseDatas.address.placeholder"
          v-model="consigneeInfoDatas.consneDatas['address']" clearable></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import {
  reactive, inject, ref, onMounted, nextTick,
} from 'vue';

import Rules from '../../utils/utilsClass/rulesClass';
import ManuFactureInfoClass from './class/manufactureInfoClass';

export default {
  name: 'consigneeinfo',
  setup() {
    // $refs
    const consigneeInfoRef = ref();

    const createCustomsDatasBus = inject('createCustomsDatasBus'); // 共享数据
    const setFilterOfContainer = inject('setFilterOfContainer'); // 登陆相关数据（父组件共享）

    // $bus
    const {
      // consigneeInfo, // 全局事件变更
      elMainRef, // elMain的Refs
      consigneeInfoDatas, // 当前表单数据
    } = createCustomsDatasBus;

    const consneRules = reactive({
      name: [Rules.CompanyRules],
      tel: [Rules.MustInput],
      contactName: [Rules.MustInput],
      address: [Rules.CompanyRules],
    });

    // 待页面加载完成后，将$ref添加至共享数据中
    nextTick(() => {
      createCustomsDatasBus.consigneeInfoRef.value = consigneeInfoRef.value;
    });

    const baseDatas = reactive({
      name: {
        label: '收货人名称',
        placeholder: ' 请输入境外收货人全称（英文）',
        value: '',
      },

      tel: {
        label: '联系电话',
        placeholder: '请输入收货人联系电话',
        value: '',
      },
      contactName: {
        label: '联系人',
        placeholder: '请输入境外联系人（英文）',
        value: '',
      },
      address: {
        label: '地址',
        placeholder: '请输入境外联系地址（英文）',
        value: '',
      },
    });

    // 添加测试数据
    onMounted(() => {
      const testDatas = {
        address: '',
        contactName: '',
        name: '',
        tel: '',
      };
      Object.assign(consigneeInfoDatas.consneDatas, testDatas);
    });

    // 自动填写加载收货人方法 参数值：绑定数据，登陆相关数据,操作的关键key值（即是由哪个关键字的key去查询），访问后端的地址，回调函数（处理合并展示内容)
    const manuFactureInfoClass = new ManuFactureInfoClass(consigneeInfoDatas.consneDatas, setFilterOfContainer, 'name', 'consignee_info', (res) => {
      const options = [];
      // 合并内容获取
      res.results.forEach((item) => {
        item.value = `${item.name}`;
        options.push(item);
      });
      return options;
    });

    return {
      consigneeInfoDatas,
      createCustomsDatasBus,
      baseDatas,
      consigneeInfoRef,
      elMainRef,
      consneRules,
      manuFactureInfoClass,
      // saveDatasFuncsBus,
    };
  },
};
</script>
<style lang="scss" scoped>
.consigneeinfo {
  padding: $base $base $base * 0.3 $base;
  @import '@/assets/scss/globalcss/elementsFromstyle.scss';

  .myclass {

    //隐藏*数据
    :deep(.el-form-item__label) {
      position: relative;

      &::before {
        content: '' !important;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}
</style>
