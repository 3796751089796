<template>
  <div class="exportnameInfo">
    <el-form ref="exportnameInfoRef" :model="exportnameData" :rules="exportnameRules" label-width="120px"
      class="demoRuleForm">
      <!-- 经营单位公司名称 -->
      <el-form-item :label="baseDatas.companyName.label" prop="companyName">
        <el-autocomplete size="small" v-model="exportnameData['companyName']"
          :placeholder="baseDatas.companyName.placeholder" :hide-loading="true" :trigger-on-focus="true"
          :fetch-suggestions="exportnameInfoClass.querySearchAsync" @select="exportnameInfoClass.handleSelect"
          popper-class="test_class"></el-autocomplete>
      </el-form-item>
      <!--  经营单位十位海关注册码 -->
      <el-form-item :label="baseDatas.customsID.label" prop="customsID">
        <el-input size="small" :placeholder="baseDatas.customsID.placeholder" v-model="exportnameData['customsID']"
          clearable></el-input>
      </el-form-item>
      <!--  经营单位地址 -->
      <el-form-item :label="baseDatas.address.label" prop="address">
        <el-input size="small" :placeholder="baseDatas.address.placeholder" v-model="exportnameData['address']"
          clearable></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import {
  onBeforeMount,
  onMounted,
  reactive,
  toRefs,
  ref,
  inject,
  nextTick,
} from 'vue';

import Rules from '../../utils/utilsClass/rulesClass';
import ManuFactureInfoClass from './class/manufactureInfoClass';

export default {
  name: 'exportnameInfo',
  setup() {
    // 数据
    const exportnameInfoRef = ref(null);// 获取当前节点
    const createCustomsDatasBus = inject('createCustomsDatasBus');// 传递公共数据
    const setFilterOfContainer = inject('setFilterOfContainer'); // 登陆相关数据（父组件共享）

    const {
      elMainRef,
      // saveDatasFuncsBus, // 父组件调用子组件函数
      factoryInfo, // 父组件调存储
      exportnameInfodatas, // 数据
    } = createCustomsDatasBus;

    // 重新赋值地址的验证信息变成必须验证
    Rules.AddressRules.required = true;

    // 验证消息
    const exportnameRules = reactive({
      companyName: [Rules.CompanyCNRules],
      customsID: [Rules.CustomsID],
      address: [Rules.AddressRules],
    });

    // 页面加载完成后将$ref赋值给共享数据$bus
    nextTick(() => {
      createCustomsDatasBus.exportnameInfoRef.value = exportnameInfoRef.value;
    });

    const baseDatas = reactive({
      companyName: {
        label: '经营单位全称',
        placeholder: ' 请输入经营单位全称',
        value: '',
      },
      customsID: {
        label: '海关注册码',
        placeholder: '进出口企业10位海关注册编码',
        value: '',
      },
      address: {
        label: '地址',
        placeholder: '必填内容，经营单地址',
        value: '',
      },
    });

    // 参数值：绑定数据，登陆相关数据,操作的关键key值（即是由哪个关键字的key去查询），访问后端的地址
    const exportnameInfoClass = new ManuFactureInfoClass(exportnameInfodatas.exportnameData, setFilterOfContainer, 'companyName', 'exportname_info', (res) => {
      // 向数据库查询出数据组合成 'xxxx公司 海关注册码'用于展示
      const options = [];
      res.results.forEach((item) => {
        item.value = `${item.companyName} ${item.customsID} ${item.address}`;
        options.push(item);
      });
      return options;
    });// 后台加载生产厂商的执行方法类

    // 方法
    const myMethods = reactive({
      initDatas: () => {
        // 初始化数据,将所有数据清空，并将当前值赋值给到共享数据
        Object.keys(baseDatas).forEach((key) => {
          exportnameInfodatas.exportnameData[key] = '';// 所有数据清空
          exportnameInfodatas.exportnameData[key] = baseDatas[key].value; // 遍历出key添加到datas中
        });
      },
    });
    onBeforeMount(() => {
      myMethods.initDatas();
    });
    // 添加测试数据
    onMounted(() => {
      const testDatas = {
        address: '',
        companyCode: '',
        companyName: '',
        contactName: '',
        tel: '',
      };
      Object.assign(exportnameInfodatas.exportnameData, testDatas);// 拷贝对象
    });

    return {
      ...toRefs(exportnameInfodatas),
      ...toRefs(myMethods),
      exportnameRules,
      createCustomsDatasBus,
      baseDatas,
      exportnameInfoRef,
      factoryInfo,
      elMainRef,
      exportnameInfoClass,
    };
  },
};
</script>
<style lang="scss" scoped>
.mycard {
  padding: $base * 0.5 !important;
}

.exportnameInfo {
  padding: $base $base $base * 0.3 $base;
  @import '@/assets/scss/globalcss/elementsFromstyle.scss';

  .demoRuleForm {
    display: flex;
    flex-direction: row;

    // 平均分布占位
    :deep(.el-form-item) {
      width: 50%;

      &:last-child {
        width: 100% !important;
      }
    }
  }

  .myclass {

    //隐藏*数据
    :deep(.el-form-item__label) {
      position: relative;

      &::before {
        content: '' !important;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}
</style>
