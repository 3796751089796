<template>
  <el-steps
    :active="createCustomsDatasBus.stepAllDatas.active"
    finish-status="success"
    ref="boxRef"
  >
    <div ref="innerBoxRef">
      <el-step
        v-for="(item, index) in createCustomsDatasBus.stepAllDatas.stepDatas"
        :key="index"
        :title="item.title"
      ></el-step>
    </div>
  </el-steps>
</template>
<script>
import {
  reactive,
  toRefs,
  watch,
  ref,
  nextTick,
  onActivated,
  onDeactivated,
  onBeforeMount,
  inject,
} from 'vue';

export default {
  name: 'stepstatus',
  setup() {
    // $ref
    const boxRef = ref(null);
    const innerBoxRef = ref(null);

    // /** 数据 */
    // const datas = reactive({
    //   stepDatas: [// 初始化时会加状态state
    //     { title: '待提交', descp: '' },
    //     { title: '待审核', descp: '' },
    //     { title: '审核不通过', descp: '' },
    //     { title: '审核通过', descp: '' },
    //     { title: '扫码支付', descp: '' },
    //     { title: '电子授权', descp: '' },
    //     { title: '出单', descp: '' },
    //   ],
    //   active: 0,
    //   isMixinScroll: false, // 备注是否备份 ，false:未备份，true:备份
    // });
    const createCustomsDatasBus = inject('createCustomsDatasBus'); // 从父组件中获取共享数据
    let timer = null;
    const myMethods = reactive({
      /** 设置节点展示
       * 思路：
       * 条件1：内容器大于外容器且当前节点
       * 条件2：当前节点大于要移动节点
       * 初始时，判断谁是中点
       * 当前外容器宽度包含了多少个节点
       * 再取节点中间点
       */
      setNode: (activeNum) => {
        // 正确值范围
        if ((activeNum > -1) && (activeNum < createCustomsDatasBus.stepAllDatas.stepDatas.length)) {
          const boxRefval = boxRef.value;
          const innerBoxRefval = innerBoxRef.value;

          const boxWidth = boxRefval.$el.offsetWidth;
          const innerBoxWidth = innerBoxRefval.offsetWidth;

          if (innerBoxWidth > boxWidth) {
            // 内容器大于外容器且当前节点
            // 判断谁是中点
            // 获取每个单节点宽度，再计算能显示多少个；
            const perNodeWidth = innerBoxRefval.children[activeNum].offsetWidth; // 当前展示节点距离左边位置
            const totalNodeCount = Math.ceil(boxWidth / perNodeWidth); // 可视区域能展示节点数量
            const moveNodeNum = Math.floor(totalNodeCount / 2); // 选择开始移动的节点

            if (activeNum > moveNodeNum) {
              boxRefval.$el.scrollLeft = (activeNum - moveNodeNum) * perNodeWidth;
            } else {
              boxRefval.$el.scrollLeft = 0;
            }
          }
        }
      },
      /** 窗体发生改变事件
       *
       */
      onResizeFunc: () => {
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
          myMethods.setNode(createCustomsDatasBus.stepAllDatas.active);
        }, 2000);
      },
    });
    onBeforeMount(() => {
      // 设置初如始值
      const { active, stepDatas } = createCustomsDatasBus.stepAllDatas;
      Object.keys(stepDatas).forEach((key) => {
        key = Number(key);
        if (key < active) {
          stepDatas[key].status = 'success';
        } else if (key === active) {
          stepDatas[key].status = 'process';
        } else {
          stepDatas[key].status = 'wait';
        }
      });
    });
    // 切换为展示时
    onActivated(() => {
      window.addEventListener('resize', myMethods.onResizeFunc); // 添加监听事件
    });
    // 切换为关闭时
    onDeactivated(() => {
      window.removeEventListener('resize', myMethods.onResizeFunc); // 移除监听事件
    });
    watch(
      () => createCustomsDatasBus.stepAllDatas.active,
      (activeNum) => {
        nextTick(() => {
          myMethods.setNode(activeNum); // 节点展示信息
        });
      },
    );
    return {
      createCustomsDatasBus,
      ...toRefs(myMethods),
      boxRef,
      innerBoxRef,
    };
  },
};
</script>
<style lang="scss" scoped>
.el-steps {
  width: 100%;
  margin: 10px;
  min-height: 50px;
  // background: red;
  overflow-y: scroll;
  display: grid;
  :deep(.el-step.is-horizontal) {
    width: 150px;
  }
  :deep(.el-step__title) {
    font-size: $base * 0.8 !important;
  }
  :deep(.el-step__description) {
    font-size: $base * 0.7 !important;
  }
  // :deep(.el-step__title.is-process){
  //   color:$dark ;
  // }
  // :deep(.el-step__description.is-process){
  //   color:$dark;
  // }
  // :deep(.el-step__head.is-process){
  //   color:$dark;
  //   border-color: $dark;
  // }
}
</style>
