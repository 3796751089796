import {
  reactive, inject, ref,
  // watch,
  // nextTick,
} from 'vue';
import Rules from '../../../../utils/utilsClass/rulesClass';

export default function () {
  // const axiosLoad = ref(null);

  // form数据配置文件
  const datas = reactive({
    // 用于存放input值
    tradeDatas: {
      hsCode: '', // 海关编码
      productName: '', // 产品名称
      brandType: '', // 品牌类型
      enjoyDiscont: '', // 是否享受减免
    },
    rules: {
      hsCode: [Rules.MustInput],
      productName: [Rules.DrawDetails],
      brandType: [Rules.MustInput],
      enjoyDiscont: [Rules.MustInput],
    },
  });

  const ruleForm = ref(); // 用于重置表单$ref
  const { storeDatas } = inject('createCustomsDatasBus');
  const saveBtn = ref(false); // true:保存,false:取消
  const initDatas = reactive({}); // 初始化备份值
  const sbslContent = reactive({
    // 申报实例内容
    hscode: '', // 海关编码
    proName: '', // 产品名
    exampleRef: '', // 参考实例
    unitJointStr: '', // 法定单位组合字符串
    enterDetailsType: false, // 进入申报明细方式
    sbysObj: [], // 申报要素
  });

  /** select下拉框切换快捷输入 */
  const myselefMethods = {
    optionsAry: {
      brandType: { // 品牌类型，命名固定不要更改
        backupAry: [], // 备份原有值
        resAry: [], // 查找到的结果值
      },
      enjoyDiscont: { //  出口享惠情况，命名固定不要更改
        backupAry: [], // 备份原有值
        resAry: [], // 查找到的结果值
      },
    },
    /** 自定义select输入方式
     * @param {String} e 输入的信息
     * @param {String} nodeName 当前操作节点
     * @param {Object} baseDatasNode 当前操作节点对象
     * 原理：根据blur,focus来操作数据备份、改变、恢复的数据调配
     */
    fileterOptionsHandleFunc: (e, nodeName, baseDatasNode) => {
      e = e.trim().replaceAll(' ', '');
      const currentOptionsAry = myselefMethods.optionsAry[nodeName].backupAry;
      const tempAry = [];

      // 输入的值是否匹配
      currentOptionsAry.forEach((item) => {
        if (item.tagName.indexOf(e) !== -1) { // 找到输入对应数据
          tempAry.push(item);
        }
      });
      baseDatasNode[nodeName].selectOptions = tempAry; // 将查询结果展示到前端
      myselefMethods.optionsAry[nodeName].resAry = tempAry;// 将查询结果放置共享$BUS
    },

    /** 下拉框展示与隐藏
   * @param {Bool} e 展示true 隐藏false
   * @param {String} nodeName 节点信息
   * @param {Object} baseDatasNode
   */
    vsbChangeOptions: (e, nodeName, baseDatasNode) => {
      if (e) { // 展示
        const { selectOptions } = baseDatasNode[nodeName];
        myselefMethods.optionsAry[nodeName].backupAry = selectOptions;// 初始获取当前值
        myselefMethods.optionsAry[nodeName].resAry = [];
      } else { // 隐藏
        // 将数据还原
        const { resAry } = myselefMethods.optionsAry[nodeName];// 输入值
        if (resAry.length > 0) { // 有数据
          datas.tradeDatas[nodeName] = resAry[0].value;// 取查询结果第一个值
        }

        // 调用自身的inputFunc事件，处理品牌填写可否编辑（inputFunc函数由加载时加载)
        baseDatasNode[nodeName].inputFunc(datas.tradeDatas[nodeName], nodeName);

        baseDatasNode[nodeName].selectOptions = myselefMethods.optionsAry[nodeName].backupAry;// 还原备份值
        myselefMethods.optionsAry[nodeName].backupAry = [];// 清除备份值
        myselefMethods.optionsAry[nodeName].resAry = [];// 清除查找到的结果
      }
    },
  };
  const baseDatas = reactive({
    // 渲染数据（固定值+其他申报要素）
    hsCode: {
      label: '海关编码',
      placeholder: '请输入海关编码',
      toolTipVal: '只可读不可编辑',
      value: '',
      readonly: true,
    },
    productName: {
      label: '产品名称',
      placeholder: '请输入产品名称',
      toolTipVal: '请输入产品名称',
      value: '',
    },
    brandType: {
      label: '品牌类型',
      placeholder: '请选择',
      toolTipVal: '品牌类型',
      value: '0',
      selectOptions: [
        { value: '0', tagName: '0-无品牌' },
        { value: '1', tagName: '1-境内自主品牌' },
        { value: '2', tagName: '2-境内收购品牌' },
        { value: '3', tagName: '3-境外品牌(贴牌生产)' },
        { value: '4', tagName: '4-境外品牌(其它)' },
      ],
      fileterOptions: (e) => { // 自定义输入
        myselefMethods.fileterOptionsHandleFunc(e, 'brandType', baseDatas);
      },
      vsbChangeOptions: (e) => { // 下拉框出现与隐藏
        myselefMethods.vsbChangeOptions(e, 'brandType', baseDatas);
      },
    },
    enjoyDiscont: {
      label: '出口享惠情况',
      placeholder: '请选择',
      toolTipVal: '出口时优惠关税是否享受优惠',
      value: '0',
      selectOptions: [
        { value: '0', tagName: '0-出口货物在最终目的国(地区)不享受优惠关税' },
        { value: '1', tagName: '1-出口货物在最终目的国(地区)享受优惠关税' },
      ],
      fileterOptions: (e) => { // 自定义输入
        myselefMethods.fileterOptionsHandleFunc(e, 'enjoyDiscont', baseDatas);
      },
      vsbChangeOptions: (e) => { // 下拉框出现与隐藏
        myselefMethods.vsbChangeOptions(e, 'enjoyDiscont', baseDatas);
      },
    },
  });
  return {
    datas,
    ruleForm,
    storeDatas,
    saveBtn,
    initDatas,
    sbslContent,
    baseDatas,
  };
}
