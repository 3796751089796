<template>
  <div class="drawerDetails">
    <!-- 申报要素 -->
    <el-form
      ref="ruleForm"
      :model="tradeDatas"
      :rules="rules"
      label-width="120px"
      class="demo-ruleForm"
    >
      <el-form-item
        v-for="(item, index) in baseDatas"
        :key="index"
        :label="item.label"
        :prop="index"
      >
        <template #label>
          <el-tooltip :content="item.label" placement="top" tabindex="-1" :show-after="2000">
            <span>{{item.label}}</span>
          </el-tooltip>
        </template>

        <!-- 选择器<span>{{item.label}}</span> -->
        <el-tooltip
          v-if="item.selectOptions"
          :content="item.toolTipVal"
          placement="top-start"
          :show-after="1000"
          tabindex="-1"
        >
          <el-select
            v-model="tradeDatas[index]"
            :disabled="item.disabled"
            :placeholder="item.placeholder"
            filterable
            default-first-option
            :filter-method="item.fileterOptions"
            @visible-change="item.vsbChangeOptions"
            @change="item.inputFunc($event,index)"
            size="small"
          >
            <el-option
              v-for="option in item.selectOptions"
              :key="option.value"
              :label="option.tagName"
              :disabled="option.disabled"
              :value="option.value"
            ></el-option>
          </el-select>
        </el-tooltip>
        <!-- 时间选择器 -->
        <!-- <el-tooltip :content="item.toolTipVal" placement="top-start" :show-after="1000"> -->
        <el-date-picker
          v-else-if="item.datePicker"
          v-model="tradeDatas[index]"
          type="date"
          :placeholder="item.placeholder"
            @change="item.inputFunc($event,index)"
          size="small"
          clearable
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
        ></el-date-picker>
        <!-- </el-tooltip> -->
        <!-- 输入框-->
        <el-tooltip v-else :content="item.toolTipVal" placement="top-start" :show-after="1000">
          <el-input
            size="small"
            :placeholder="item.placeholder"
            v-model.trim="tradeDatas[index]"
            :disabled="item.disabled"
            :readonly="item.readonly"
            @blur="item.blurFuncs"
            :hide-required-asterisk="item.hideRequired"
            @input="item.inputFunc($event,index)"
            clearable
          >{{item.disabled}}</el-input>
        </el-tooltip>
      </el-form-item>
    </el-form>

    <div class="btns">
      <el-row>
        <el-button type="success" @click="drawerDetailsClass.saveInfo">{{saveBtn?"保存":"取消"}}</el-button>
        <el-button type="info" @click="drawerDetailsClass.resetForm">重置</el-button>
      </el-row>
    </div>
  </div>
</template>
<script>
import { toRefs, onBeforeMount } from 'vue';
import mydatas from './models/drawerdetails/datas';
import DrawerDetailsClass from './class/drawerdetailsClass';

// 加载工具包
// import utils from '@/utils/deepCopy';

export default {
  name: 'drawerDetails',
  setup() {
    /** 数据 */
    const myDatas = mydatas();
    const {
      datas, sbslContent, baseDatas, ruleForm, saveBtn, initDatas, tempLoad,
    } = myDatas;

    const drawerDetailsClass = new DrawerDetailsClass(myDatas);
    onBeforeMount(() => {
      drawerDetailsClass.onBeforeMountFunc();
    });

    return {
      drawerDetailsClass,
      ...toRefs(datas), // 保存form表单内容
      sbslContent,
      baseDatas, // 基本数据config
      ruleForm, // 重置表单$refs
      saveBtn, // true:保存,false:添加；
      initDatas,
      tempLoad,
    };
  },
};
</script>
<style lang="scss" scoped>
.sbsl {
	display: flex;
	flex-direction: row;
}
.drawerDetails {
	padding: $base $base $base * 0.3 $base;
	@import '@/assets/scss/globalcss/elementsFromstyle.scss';
	// .myclass {
	// 	//隐藏*数据
	// 	:deep(.el-form-item__label) {
	// 		position: relative;
	// 		&::before {
	// 			content: '' !important;
	// 			position: absolute;
	// 			top: 0;
	// 			left: 0;
	// 		}
	// 	}
	// }
	.btns {
		display: flex;
		justify-content: center;
		margin-top: $base;
	}
}
</style>
