<template>
  <div class="productinfo">
    <!-- 产品信息填写区 -->
    <div class="weightInfo">
      <HsDetails />
    </div>
    <div class="weightTable">
      <!-- 产品信息表格展示区 -->
      <HsDetailsTable ref="hsTabaleRef" />
    </div>
  </div>
</template>
<script>
import {
  toRefs, ref, inject, nextTick,
} from 'vue';
import HsDetails from './HsDetails.vue';
import HsDetailsTable from './HsDetailsTable.vue';

export default {
  name: 'productinfo',
  components: {
    HsDetails,
    HsDetailsTable,
  },
  setup() {
    // form数据配置文件
    const hsTabaleRef = ref(); // 用于产品信息保存点击按钮时table高亮显示，中间件用

    const createCustomsDatasBus = inject('createCustomsDatasBus');
    nextTick(() => {
      createCustomsDatasBus.hsTabaleRef.value = hsTabaleRef.value;
    });
    const { ProductInfoDatas, rowClkBus } = createCustomsDatasBus;
    return {
      ...toRefs(ProductInfoDatas),
      createCustomsDatasBus,
      hsTabaleRef,
      rowClkBus,
    };
  },
};
</script>
<style lang="scss" scoped>
$radius: 10px;
$border_show: 1px solid
	rgba(
		$color: red,
		$alpha: 1,
	);
$border_hidden: 1px solid
	rgba(
		$color: red,
		$alpha: 0,
	);
.productinfo {
	.weightInfo,
	.weightTable {
		border: $border_hidden; //隐藏的边框
	}
	.searchinput {
		padding: $base * 3 $base;
		display: flex;
		justify-content: center;
		:deep(.el-input .el-input__inner) {
			font-size: 18px;
			text-align: center;
		}
		:deep(.el-input-group) {
			width: 550px !important;
		}
	}
	.detailbox {
		margin: 0 $base * 2 $base * 2 $base * 2;
		border: 1px solid rgba($hovefontcolor, 0.6);
		min-height: 80px;
		border-radius: 0.3rem;
		position: relative;
		padding: $base;
		&::before {
			content: '申报产品描述';
			position: absolute;
			top: -13px;
			left: $base * 5;
			background-color: $mycard;
			padding: 0 $base;
		}
		&:hover {
			border-color: $hovefontcolor;
		}
	}
	.weightTable {
		margin: $base;
	}
}
.animal {
	animation: lightShow 3s 3;
	-webkit-animation: lightShow 3s 3;
}

@keyframes lightShow {
	0% {
		border: $border_show;
		border-radius: $radius;
	}
	25% {
		border: $border_hidden;
		border-radius: $radius;
	}
	50% {
		border: $border_show;
		border-radius: $radius;
	}
	75% {
		border: $border_hidden;
		border-radius: $radius;
	}
	100% {
		border: $border_show;
		border-radius: $radius;
	}
}
@-webkit-keyframes lightShow {
	0% {
		border: $border_show;
		border-radius: $radius;
	}
	25% {
		border: $border_hidden;
		border-radius: $radius;
	}
	50% {
		border: $border_show;
		border-radius: $radius;
	}
	75% {
		border: $border_hidden;
		border-radius: $radius;
	}
	100% {
		border: $border_show;
		border-radius: $radius;
	}
}
</style>
