<!-- 产品信息-海关编码输入框与产品名称输入框 -->
<template>
	<div class="createcustoms">
		<div class="scrollbody">
			<div class="mycardbox">
				<my-card>
					<template #mycard>
						<h1>当前进度</h1>
						<StepStatus />
					</template>
				</my-card>
			</div>

			<div class="mycardbox productInfo">
				<my-card>
					<template #mycard>
						<h1>产品信息</h1>
						<div class="productInfo">
							<ProductInfo />
						</div>
					</template>
				</my-card>
			</div>

			<div class="mycardbox">
				<my-card>
					<template #mycard>
						<h1>贸易信息</h1>
						<div class="tradeInfo">
							<TradeInfo />
						</div>
					</template>
				</my-card>
			</div>

			<div class="mycardbox">
				<my-card>
					<template #mycard>
						<h1>经营单位基本信息</h1>
						<div class="exportnameInfo">
							<ExportnameInfo />
						</div>
					</template>
				</my-card>
			</div>

			<div class="mycardbox">
				<my-card>
					<template #mycard>
						<h1>生产企业基本信息</h1>
						<div class="manufacturerInfo">
							<ManufacturerInfo />
						</div>
					</template>
				</my-card>
			</div>

			<div class="mycardbox">
				<my-card>
					<template #mycard>
						<h1>境外收货人信息</h1>
						<div class="consigneeInfo">
							<ConsigneeInfo />
						</div>
					</template>
				</my-card>
			</div>
		</div>
		<div class="fixedDiv">
			<el-button-group>
				<el-button type="success" @click="createCustomsClass.submitDatasFunc"
					:disabled="activeData.isDisabled">提交</el-button>
				<el-button type="primary" @click="createCustomsClass.storageDatasToService"
					:disabled="activeData.isDisabled">暂存</el-button>
				<el-button type="info" @click="createCustomsClass.goBackFunc">返回</el-button>
			</el-button-group>
		</div>
	</div>
</template>
<script>
import {
	inject,
	nextTick,
	onBeforeMount,
	provide,
	reactive,
	computed,
	//    defineExpose,
} from 'vue';

import { useRouter } from 'vue-router';
import MyCard from '../components/util/MyCard.vue';
import StepStatus from '../components/createcustoms/StepStatus.vue';
import ProductInfo from '../components/createcustoms/ProductInfo.vue';
import TradeInfo from '../components/createcustoms/TradeInfo.vue';
import ManufacturerInfo from '../components/createcustoms/ManufacturerInfo.vue';
import ExportnameInfo from '../components/createcustoms/ExportnameInfo.vue';
import ConsigneeInfo from '../components/createcustoms/ConsigneeInfo.vue';
import CreateCustomsDatasBus from '../components/createcustoms/store/createCustomsDatasBus.vue';
// 类
import CreateCustomsClass from '../components/createcustoms/class/createCustomsClass';

export default {
	name: 'createcustoms',
	components: {
		MyCard,
		StepStatus,
		ProductInfo,
		ManufacturerInfo,
		ExportnameInfo,
		TradeInfo,
		ConsigneeInfo,
	},
	setup() {
		const router = useRouter();
		// 事件总线
		const setFilterOfContainer = inject('setFilterOfContainer'); // 登陆信息数据
		const storeDatas = inject('storeDatas');
		const elMainRef = inject('elMainRef');
		const createCustomsDatasBus = CreateCustomsDatasBus.setup();
		const activeData = reactive({
			isDisabled: computed(() => createCustomsDatasBus.tableDatas.length === 0),
		});
		nextTick(() => {
			// 待页面加载完成将ref赋值到共享数据中
			createCustomsDatasBus.elMainRef.value = elMainRef.value;
		});

		const createCustomsClass = new CreateCustomsClass(createCustomsDatasBus, setFilterOfContainer, storeDatas, router);

		onBeforeMount(() => {
			// 根据路由参数向后台服务器加载数据
			createCustomsClass.queryDataFromRouter();
		});

		provide('createCustomsDatasBus', createCustomsDatasBus); // 各子组件共享数据集合
		return {
			elMainRef,
			createCustomsDatasBus,
			setFilterOfContainer,
			activeData,
			createCustomsClass,
		};
	},
};
</script>
<style lang="scss" scoped>
$radius: 5px;
$border_show: 1px solid rgba($color: red,
		$alpha: 1,
	);
$border_hidden: 1px solid rgba($color: red,
		$alpha: 0,
	);
$fixedBtn: 48px;
$currentViewHeight: calc(100vh - 100px - 20px); //header:100px,border:20px

.createcustoms {
	position: relative;
	height: $currentViewHeight !important;

	.scrollbody {
		height: calc(#{$currentViewHeight} - #{$fixedBtn});
		overflow-y: scroll !important;
		-webkit-overflow-scrolling: touch !important;

		.mycardbox {
			position: relative;
			margin-bottom: 1px;

			h1 {
				// font-size: $base;
				padding: 10px 0 0 35px;
				color: $dark;
				font-weight: bold;
				border-bottom: 1px solid $light;
				// font-family: '黑体';
				position: relative;

				&::before {
					content: '';
					position: absolute;
					left: 18px;
					bottom: 7px;
					width: 10px;
					height: 10px;
					background: $activebgcolor;
					transform: rotate(45deg);
				}
			}

			.mycard {
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;
				border-top-left-radius: 0;
				border-top-right-radius: 0;
				overflow: hidden;

				// 添加一个点位边框
				&:first-child>div {
					border: $border_hidden;
					border-radius: $radius;
				}
			}

			&:nth-last-child(1) {
				.mycard {
					border-top-left-radius: 0 !important;
					border-top-right-radius: 0 !important;
					border-bottom-left-radius: $radiusSize !important;
					border-bottom-right-radius: $radiusSize !important;
				}
			}

			&:first-child {
				.mycard {
					border-top-left-radius: $radiusSize !important;
					border-top-right-radius: $radiusSize !important;
					border-bottom-left-radius: 0 !important;
					border-bottom-right-radius: 0 !important;
				}
			}
		}

		.hiddenBorer {
			border: $border_hidden;
			border-radius: $radius;
		}

	}

	.fixedDiv {
		height: $fixedBtn;
		width: 100%;
		background: var(--el-svg-monochrome-grey);
		box-shadow: 5px 2px 8px 0px $dark;
		border-radius: 8px;
		bottom: 0;
		left: 0;
		display: flex;
		justify-content: flex-end;
		align-items: center;

		.el-button-group {
			// margin-bottom: 1px;
			margin-right: 20px;

			.el-button {
				width: 120px;
			}
		}
	}
}

.animal {
	animation: lightShow 2s 2;
	-webkit-animation: lightShow 2s 2;
}

@keyframes lightShow {
	0% {
		border: $border_show;
		border-radius: $radius;
	}

	25% {
		border: $border_hidden;
		border-radius: $radius;
	}

	50% {
		border: $border_show;
		border-radius: $radius;
	}

	75% {
		border: $border_hidden;
		border-radius: $radius;
	}

	100% {
		border: $border_show;
		border-radius: $radius;
	}
}

@-webkit-keyframes lightShow {
	0% {
		border: $border_show;
		border-radius: $radius;
	}

	25% {
		border: $border_hidden;
		border-radius: $radius;
	}

	50% {
		border: $border_show;
		border-radius: $radius;
	}

	75% {
		border: $border_hidden;
		border-radius: $radius;
	}

	100% {
		border: $border_show;
		border-radius: $radius;
	}
}
</style>
