<!-- 产品信息-海关编码输入框与产品名称输入框 -->
<template>
  <div class="hsDetails">
    <!-- 产品重量信息 -->
    <WeightInfo />

    <!--drawer侧边展示栏 海关编码信息  -->
    <div class="drawerBox">
      <el-drawer v-model="drawerHscode" title="海关编码信息" destroy-on-close :size="drawerBoxWidth">
        <DrawerHscode />
      </el-drawer>
    </div>
  </div>
</template>
<script>
import {
  ref, inject, reactive, toRefs, watch,
} from 'vue';
import WeightInfo from './WeightInfo.vue';
// 子组件
import DrawerHscode from './DrawerHscode.vue';
// $bus组件
// import createStore from './store/productInfo';

export default {
  name: 'hsdetails',
  components: { WeightInfo, DrawerHscode },
  setup() {
    const createCustomsDatasBus = inject('createCustomsDatasBus');
    const store = createCustomsDatasBus.storeDatas;

    // form数据配置文件
    // const store = reactive(createStore()); // provide数据
    const sideBarObj = reactive(inject('sideBarObj')); // 获取侧边菜单栏宽度值
    const drawerBoxWidth = ref(`${window.innerWidth - sideBarObj.widthVal.replace('px', '')}px`); // 根据侧边栏的值来设置弹出侧边框的宽度
    const datas = reactive({});
    watch(
      () => sideBarObj,
      () => {
        // 监听侧边菜单栏宽度值变化
        drawerBoxWidth.value = `${window.innerWidth - sideBarObj.widthVal.replace('px', '')}px`;
      },
      { deep: true },
    );
    window.onresize = () => {
      // 宽口大小改变时重新设置侧边弹出栏宽度
      drawerBoxWidth.value = `${window.innerWidth - sideBarObj.widthVal.replace('px', '')}px`;
    };

    return {
      createCustomsDatasBus,
      ...toRefs(datas),
      ...toRefs(store),
      drawerBoxWidth,
    };
  },
};
</script>
<style lang="scss" scoped>
.hsDetails {
	padding: $base $base $base * 0.3 $base;
	@import '@/assets/scss/globalcss/elementsFromstyle.scss';
	.myclass {
		//隐藏*数据
		:deep(.el-form-item__label) {
			position: relative;
			&::before {
				content: '' !important;
				position: absolute;
				top: 0;
				left: 0;
			}
		}
	}
	.drawerBox {
		:deep(.el-drawer__header) {
			text-align: center;
			font-size: $base;
			font-weight: bold;
			margin-bottom: 0;
			padding-bottom: 20px;
			border-bottom: 1pt solid rgba(#999, 0.5);
		}
		:deep(.el-drawer.rtl) {
			background-color: $mycard;
			min-width: 680px;
			overflow-y: scroll;
		}
	}
}
</style>
