import { ElMessage } from 'element-plus';
import myAxios from '../../../utils/axios/index_v1';

// ManuFactureInfoClass 生产企业基本信息操作类
export default class ManuFactureInfoClass {
  /**
   * @param {object} data 当前操作数据
   * @param {object} setFilterOfContainer 共享绑定表单数据
   * @param {string} operationKey 操作当前的key值名称
   * @param {string} fetchUrl 向服务器操作地址
   * @param {function} callBack 回调函数
   */
  constructor(data, setFilterOfContainer, operationKey, fetchUrl, callBack) {
    this.baseDatas = data; // 数据
    this.setFilterOfContainer = setFilterOfContainer;// 判断登陆类
    this.operationKey = operationKey;// 操作当前健值,只适用于生产企业基本信息
    this.fetchUrl = fetchUrl;// 访问后端服务器地址
    this.options = []; // 当前操作象
    this.oldVal = ''; // 记录上次输入的值
    this.timeOut = null; // 记录timeOut数据
    this.callBack = callBack;// 回调函数（每个主体自行合并展示option,因为每个主体的key值是不同)
  }

  // 用户修改了内容
  changeInputContent = () => {
    if (this.options.length > 0) {
      // 表示当前输入的值与选择器中的值不同，则清除后面所有自动填充的其他内容
      Object.keys(this.baseDatas).forEach((key) => {
        if (key !== this.operationKey) {
          this.baseDatas[key] = '';
        }
      });
    }
  };

  // 用户选择内容处理函数
  handleSelect = (e) => {
    // 清除options中其他数据，只保留用户选中的数据
    const newOptions = this.options.filter((item) => item.value === e.value);
    this.options = newOptions;
    Object.assign(this.baseDatas, e);// 将数据赋值给到响应式数据中
    this.oldVal = newOptions[0].companyName;
  };

  // 清洗数据成[{value:xxxx ,xxxx:xxx}]
  washLoadData = (res) => {
    this.options = [];
    // 原有数据为[{address: '', companyCode: '91440300MA5DN31X8E', companyName: '深圳市三特眼镜工具有限公司', contactName: '', tel: '']
    // 更改为数据[value:"91440300MA5DN31X8E 深圳市三特眼镜工具有限公司"]
    if (res.code === 200 && res.results != null) {
      // 这里使用回调函数，让每个主体选择合并的内容（每个定义主键是不同）
      const newOption = this.callBack(res);
      this.options = newOption;
    }
  };

  // 加载后台数据
  loadingData = async (queryString) => {
    if (queryString === '') {
      return;
    }
    // 处理异步获取数据
    await myAxios()
      .initAxios({
        method: 'POST',
        url: this.fetchUrl,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: {
          queryString,
        },
      }).then((res) => {
        // 清洗数据
        this.washLoadData(res);
      }).catch((err) => {
        ElMessage.log(err);
      });
  };

  // 用户录入数据监控内容
  querySearchAsync = (queryString, cb) => {
    // 要求为登陆用户并且查询字段至少2位数，未登陆，与之前执行的值相同，或已选择过的值不再options中有值，防止多次向服务请求。
    const changedState = this.options.findIndex((item) => item.companyName === queryString) !== -1;
    if (!this.setFilterOfContainer.isLogin || queryString.length < 2 || this.oldVal === queryString || changedState) {
      cb(this.options);
      return;
    }
    if (!changedState) {
      // 更改了数据
      this.changeInputContent();
    }
    // 输入加载数据
    clearTimeout(this.timeOut);
    this.timeOut = setTimeout(() => {
      // 加载数据
      this.loadingData(queryString).then(() => {
        cb(this.options);
        this.oldVal = queryString;
      }).catch((err) => {
        console.error(err);
      });
    }, 1500);
  }
}
