<script>
import {
  reactive,
  ref,
  computed,
  // inject,
} from 'vue';

export default {
  setup() {
    /** *************submit总数据**************** */
    const uuid = ref('');
    /** *************当前进度 - StepStatus.Vue数据**************** */
    const stepAllDatas = reactive({
      // 初始化时会加状态state
      stepDatas: [
        { title: '待提交', descp: '' },
        { title: '待审核', descp: '' },
        { title: '审核不通过', descp: '' },
        { title: '审核通过', descp: '' },
        { title: '扫码支付', descp: '' },
        { title: '电子授权', descp: '' },
        { title: '出单', descp: '' },
      ],
      active: 0,
      isMixinScroll: false, // 备注是否备份 ，false:未备份，true:备份
    });

    const backupStepDatas = reactive({
      // 备份【当前进度】原始数据
      backupStepDatas: [], // 步聚值
      backupActive: null, // 当前进度值
    });

    /** *************产品信息栏 - ProductInfo.vue**************** */

    const ProductInfoDatas = reactive({
      inputValue: '',
    });
    // 表单行的点击共享事件
    const rowClkBus = reactive({
      // 【表单】中行点击事件
      currentBtnState: null, // 当前按钮状态值
      rangeClkId: null, // 行ID号
      oldRangeClkId: null, // 旧行ID号.防止再次点击行事件
      setCurrent: null, // 设置高亮方法：表格中初始化当前数据，参数row为tabaleDatas中对象，则高亮此行。空值为清除高亮
      currentRow: null, // 当前点击行的数据对象
      clkRowFunc: null, // 行点击事件
    });

    /** *************产品信息栏 - productinfo/index.js **************** */
    const storeDatas = reactive({
      argOfProductName: {
        hsCode: '', // 申报实例海关编码
        productName: '', // 申报名称
        exampleRef: '', // 申报实例
        unitJointStr: '', // 法定单位组合字符串
        enterDetailsType: false, // 进入申报明细方式[true:录入报关信息点击进入,false:海关编码信息点击进入]
      },

      drawerHscode: false, // 海关编码信息侧边栏开关
      drawerDetails: false, // 申报明细侧边栏开关
      saveState: false, // 保存状态
      drawerDetailsRefs: null, // drawerDetails $refs
      setWeightInfoHscode: null, // 动态改变产品录入信息页数据【海关编码，产品名称，单位】
    });

    /** *************产品信息栏- weightinfo/datas.js**************** */
    // $refs数据
    const weightFormRef = ref(null); // 当前$ref
    const tableDatas = reactive([]); // 存放表格数据 unshift存入

    // 基本表单信息
    const datas = reactive({
      detailsDatas: {
        hsCode: '', // 存放海关编码
        productName: '', // 存放产品名称（长编码)
        unit: '', // 法定单位
        unitQTY: '', // 法定单位值
        ctns: '', // 箱数
        grossWt: '', // 毛重
        moreProductCtns: '', // 混装品名数量
        packageState: 0, // 是否混装【0：非混装，1：混装】
        netWt: '', // 净重
        productQTY: '', // 产品数量
        qtyUnit: '007', // 产品数量单位
        totalAmount: '', // 总价
        unitPrice: '', // 产品单价
        unitJointStr: '', // 法定单位组合字符串
        mixinInfo: [], // 记录混装情况下数据，数组依序记录[标记uuID,箱数,毛重,混合毛重的净重之合]
      }, // Form表单数据
      rules: {}, // Form表单校验规则
    });
    // 存放el-option中事件数据
    const productQtyOptions = ref('');
    const oldProductQTY = ref('');
    // const myFileterVal = reactive([]);// 【产品数量】自定义输入方式变量存储
    const optionsAry = reactive({
      // 【是否混装】【法定单位】自定义输入方式变量存储
      packageState: {
        // 混装，命名固定不要更改
        backupAry: [], // 备份原有值
        resAry: [], // 查找到的结果值
      },
      unit: {
        //  法定单位，命名固定不要更改
        backupAry: [], // 备份原有值
        resAry: [], // 查找到的结果值
      },
    });

    // 按钮信息
    const btnState = reactive({
      // 有监听事件对比初始值与现有内容是否一致
      save: true, // 保存[false:取消，true:保存]
      reset: false, // 重置
      delete: false, // 删除按钮
      copy: false, // 复制
      edit: false, // 编辑
      add: false, // 新增
      next: false, // 下一步 【false:下一步，true：全部保存】
      prev: false, // 上一步 【false:上一步，true:返回】
    });

    // 状态信息
    const config = reactive({
      buttonState: true, // 控制按钮展示true:【（nextBtn:true 【保存/取消]|重置】/nextBtn:false 【上一步/返回|全部保存/下一步】） false:【新增|复制|修改|删除】
      currentBtnState: 'editStateSave', // 记录按钮当前状态【mixinInit,mixinEdite,mixinSave,editStateAdd,editStateSave,modifyStateAdd,modifyStateSave,tableStateShow】
      modifyState: false, // 当前状态是否处于【修改】状态 修改状态：true  非修改状态：false
      isNextShowAry: [], // 混装要素是否填写【0是否混装|1混装品类数量|2混装箱数|3混装毛重】，数字表示索引
      nextBTN: false, // 【下一步】状态，false:不显示，true:显示
      disabledForm: false, // 表单编辑状态,false:可编辑,true:不可编辑
    });
    // 备份信息
    const initDatas = reactive({}); // 用于存储第一次初始化的datas数据

    // 通过$ref获取数据（加载子组件再添加）
    const hsTabaleRef = ref(null); // 表单$refs
    const moreProductCtnsRef = ref(null);// 混装品类数量ref
    // const elMainRef = reactive(inject('elMainRef')); // 展示区内容$ref
    const elMainRef = ref(null); // 展示区内容$ref

    // 表单或混装数据
    const MAXITEM = 40; // 【混装】最多可以输入多少条数据
    const mixinpackOBJ = reactive([]); // 存放混装信息(存放datas.detailsDatas数据)

    // 混装信息
    const stateContent = reactive({
      uuid: null, // 记录当前批次的UUID
      nextStep: 0, // 当前操作混装第几步
      nextTooltip: '【混装品类数量】未初始化完成', // 初始值，后期会变动
      prevTooltip: '返回至【非混装】状态', //  初始值，后期会变动
      // nextStateus: computed(() => Number(stateContent.nextStep) > mixinpackOBJ.length - 1), // true:未返回 ；false：返回修改状态
      nextStateus: computed({
        // true:未返回 ；false：返回修改状态
        get: () => Number(stateContent.nextStep) > mixinpackOBJ.length - 1,
        set: (val) => val,
      }),
    });

    /** *************产品信息 - 表单数据HsdetailsTable.vue **************** */
    const hsDetailTableRef = ref(null);

    /** *************贸易信息 - tradeinfo.vue **************** */
    // $refs集合
    const tradeInfoFormRef = ref(null);
    // 贸易信息表单录入信息数据
    const tradeInfoDatas = reactive({
      tradeDatas: {
        contractNum: '', // 合同号
        destCountry: '', // 最终目的国
        invoiceNum: '', // 发票号
        originCountry: 'CHN', // 原产国
        packageMark: '', // 箱唛
        packingNum: '', // 箱单号
        payMode: '3', // 成交方式
        productOrigin: '44031', // 货源地
        refundDuty: '0', // 是否退税
        tradetype: '0110', // 贸易方式
      },
    });
    /** *************经营单位 - ExportnameInfo.vue **************** */
    const exportnameInfoRef = ref(null);
    const exportnameInfodatas = reactive({
      // 经营单位基本信息
      exportnameData: {
        customsID: '', // 经营单位海关注册码
        companyName: '', // 经营单位公司名称
        address: '', // 经营单位地址
      },
    });
    /** *************生产企业基本信息 - ManufacturerInfo.vue **************** */
    const factoryInfoRef = ref(null);
    const factoryInfodatas = reactive({
      // 生产企业基本信息
      manufacturer: {
        address: '', // 生产企业地址
        companyCode: '', // 单位信用代码
        companyName: '', // 生产企业公司名称
        contactName: '', // 联系人
        tel: '', // 电话
      },
    });

    /** *************境外收货人信息 - ConsigneeInfo.vue **************** */
    const consigneeInfoRef = ref();
    const consigneeInfoDatas = reactive({
      consneDatas: {
        address: '', // 地址
        contactName: '', // 收货人联系人
        name: '', // 收货人名称
        tel: '', // 收货人电话
      },
    });

    // 初始化产品信息栏中输入框可编辑disabled状态函数
    const initDisabledStateFunc = () => { };
    // 初始化产品信息栏中法定单位数据函数
    const setUnitOptionsFunc = () => { };

    return {
      setUnitOptionsFunc, // 初始化产品信息栏中法定单位数据
      initDisabledStateFunc, // 初始化产品信息栏中输入框可编辑disabled状态
      // saveDatasFuncsBus,
      rowClkBus, // 【表格】行点击事件与参数$bus
      stepAllDatas, // 当前进度数据集合
      ProductInfoDatas, // 产品信息栏数据
      tradeInfoDatas, // 贸易信息表单录入信息数据
      exportnameInfodatas, // 经营单位基本信息数据
      // tradeInfo, // 贸易信息总数据(集合)
      factoryInfodatas, // 生产企业基本信息数据
      consigneeInfoDatas, // 境外收货人信息数据
      uuid,
      tableDatas,
      storeDatas,
      datas, // 表单数据与验证数据
      initDatas, // 初始化备份信息
      elMainRef, // 整个展示区内容
      consigneeInfoRef, // 境外收货人信息$ref
      hsDetailTableRef, // 产品信息栏表单$ref
      exportnameInfoRef, // 经营单位栏表单$ef
      moreProductCtnsRef, // $refs数据
      weightFormRef, // $refs数据
      factoryInfoRef, // 生产企业基本信息$ref
      hsTabaleRef, // 海关编码$ref
      tradeInfoFormRef, // 贸易信息栏form $ref
      btnState, // 按钮信息
      config, // 状态信息
      MAXITEM, // 【混装】最多可以输入多少条数据
      mixinpackOBJ, // 混装操作临时存放数据
      stateContent, // 混装toolTip动态提示信息
      productQtyOptions, // 【产品数量】自定义输入方式变量存储
      oldProductQTY, // 【产品数量】备份旧值

      optionsAry, // 【是否混装】【法定单位】自定义输入方式变量存储
      backupStepDatas, // 备份【当前进度】原始值
    };
  },
};
</script>
