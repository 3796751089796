export default ([
  { label: '中关村国家自主创新示范区（东城园）', value: '11013', tagName: '中关村国家自主创新示范区（东城园）(11013)' },
  { label: '东城区', value: '11019', tagName: '东城区(11019)' },
  { label: '中关村国家自主创新示范区（西城园）', value: '11023', tagName: '中关村国家自主创新示范区（西城园）(11023)' },
  { label: '西城区', value: '11029', tagName: '西城区(11029)' },
  { label: '崇文区', value: '11039', tagName: '崇文区(11039)' },
  { label: '宣武区', value: '11049', tagName: '宣武区(11049)' },
  { label: '中关村国家自主创新示范区（朝阳园）', value: '11053', tagName: '中关村国家自主创新示范区（朝阳园）(11053)' },
  { label: '朝阳区', value: '11059', tagName: '朝阳区(11059)' },
  { label: '中关村国家自主创新示范区（丰台园）', value: '11063', tagName: '中关村国家自主创新示范区（丰台园）(11063)' },
  { label: '丰台区', value: '11069', tagName: '丰台区(11069)' },
  { label: '中关村国家自主创新示范区（石景山园）', value: '11073', tagName: '中关村国家自主创新示范区（石景山园）(11073)' },
  { label: '石景山', value: '11079', tagName: '石景山(11079)' },
  { label: '中关村国家自主创新示范区（海淀园）', value: '11083', tagName: '中关村国家自主创新示范区（海淀园）(11083)' },
  { label: '海淀区其他', value: '11089', tagName: '海淀区其他(11089)' },
  { label: '中关村国家自主创新示范区（门头沟园）', value: '11093', tagName: '中关村国家自主创新示范区（门头沟园）(11093)' },
  { label: '门头沟', value: '11099', tagName: '门头沟(11099)' },
  { label: '中关村国家自主创新示范区（房山园）', value: '11103', tagName: '中关村国家自主创新示范区（房山园）(11103)' },
  { label: '房山', value: '11109', tagName: '房山(11109)' },
  { label: '中关村国家自主创新示范区（顺义园）', value: '11113', tagName: '中关村国家自主创新示范区（顺义园）(11113)' },
  { label: '北京天竺出口加工区', value: '11115', tagName: '北京天竺出口加工区(11115)' },
  { label: '北京天竺综合保税区', value: '11116', tagName: '北京天竺综合保税区(11116)' },
  { label: '顺义', value: '11119', tagName: '顺义(11119)' },
  { label: '中关村国家自主创新示范区（昌平园）', value: '11123', tagName: '中关村国家自主创新示范区（昌平园）(11123)' },
  { label: '昌平', value: '11129', tagName: '昌平(11129)' },
  { label: '北京经济技术开发区', value: '11132', tagName: '北京经济技术开发区(11132)' },
  { label: '中关村国家自主创新示范区（大兴-亦庄园）', value: '11133', tagName: '中关村国家自主创新示范区（大兴-亦庄园）(11133)' },
  { label: '大兴其他', value: '11139', tagName: '大兴其他(11139)' },
  { label: '北京亦庄保税物流中心', value: '1113W', tagName: '北京亦庄保税物流中心(1113W)' },
  { label: '中关村国家自主创新示范区（通州园）', value: '11143', tagName: '中关村国家自主创新示范区（通州园）(11143)' },
  { label: '通县', value: '11149', tagName: '通县(11149)' },
  { label: '中关村国家自主创新示范区（怀柔园）', value: '11153', tagName: '中关村国家自主创新示范区（怀柔园）(11153)' },
  { label: '怀柔', value: '11159', tagName: '怀柔(11159)' },
  { label: '中关村国家自主创新示范区（平谷园）', value: '11163', tagName: '中关村国家自主创新示范区（平谷园）(11163)' },
  { label: '平谷', value: '11169', tagName: '平谷(11169)' },
  { label: '中关村国家自主创新示范区（延庆园）', value: '11173', tagName: '中关村国家自主创新示范区（延庆园）(11173)' },
  { label: '延庆', value: '11179', tagName: '延庆(11179)' },
  { label: '中关村国家自主创新示范区（密云园）', value: '11183', tagName: '中关村国家自主创新示范区（密云园）(11183)' },
  { label: '密云', value: '11189', tagName: '密云(11189)' },
  { label: '北京其他', value: '11909', tagName: '北京其他(11909)' },
  { label: '和平区', value: '12019', tagName: '和平区(12019)' },
  { label: '河东区', value: '12029', tagName: '河东区(12029)' },
  { label: '河西区', value: '12039', tagName: '河西区(12039)' },
  { label: '天津新技术产业园区', value: '12043', tagName: '天津新技术产业园区(12043)' },
  { label: '南开区其他', value: '12049', tagName: '南开区其他(12049)' },
  { label: '河北区', value: '12059', tagName: '河北区(12059)' },
  { label: '红桥区', value: '12069', tagName: '红桥区(12069)' },
  { label: '天津经济技术开发区', value: '12072', tagName: '天津经济技术开发区(12072)' },
  { label: '天津港保税区', value: '12074', tagName: '天津港保税区(12074)' },
  { label: '天津出口加工区', value: '12075', tagName: '天津出口加工区(12075)' },
  { label: '天津东疆、泰达综合保税区', value: '12076', tagName: '天津东疆、泰达综合保税区(12076)' },
  { label: '天津保税物流园', value: '12077', tagName: '天津保税物流园(12077)' },
  { label: '滨海新区(塘沽其他)', value: '12079', tagName: '滨海新区(塘沽其他)(12079)' },
  { label: '天津经济技术开发区保税物流中心', value: '1207W', tagName: '天津经济技术开发区保税物流中心(1207W)' },
  { label: '滨海新区(汉沽)', value: '12089', tagName: '滨海新区(汉沽)(12089)' },
  { label: '滨海新区(大港)', value: '12099', tagName: '滨海新区(大港)(12099)' },
  { label: '天津滨海新区综合保税区', value: '12106', tagName: '天津滨海新区综合保税区(12106)' },
  { label: '东丽区', value: '12109', tagName: '东丽区(12109)' },
  { label: '西青区', value: '12119', tagName: '西青区(12119)' },
  { label: '津南区', value: '12129', tagName: '津南区(12129)' },
  { label: '北辰区', value: '12139', tagName: '北辰区(12139)' },
  { label: '宁河县', value: '12149', tagName: '宁河县(12149)' },
  { label: '武清县', value: '12159', tagName: '武清县(12159)' },
  { label: '静海县', value: '12169', tagName: '静海县(12169)' },
  { label: '宝坻县', value: '12179', tagName: '宝坻县(12179)' },
  { label: '蓟县', value: '12189', tagName: '蓟县(12189)' },
  { label: '蓟州保税物流中心（B型）', value: '1218W', tagName: '蓟州保税物流中心（B型）(1218W)' },
  { label: '天津其他', value: '12909', tagName: '天津其他(12909)' },
  { label: '石家庄高新技术产业开发实验区', value: '13013', tagName: '石家庄高新技术产业开发实验区(13013)' },
  { label: '石家庄综合保税区', value: '13016', tagName: '石家庄综合保税区(13016)' },
  { label: '石家庄其他', value: '13019', tagName: '石家庄其他(13019)' },
  { label: '辛集保税物流中心（B型）', value: '1301W', tagName: '辛集保税物流中心（B型）(1301W)' },
  { label: '曹妃甸经济技术开发区', value: '13022', tagName: '曹妃甸经济技术开发区(13022)' },
  { label: '曹妃甸综合保税区', value: '13026', tagName: '曹妃甸综合保税区(13026)' },
  { label: '唐山', value: '13029', tagName: '唐山(13029)' },
  { label: '唐山港京唐港区保税物流中心（B型）', value: '1302W', tagName: '唐山港京唐港区保税物流中心（B型）(1302W)' },
  { label: '秦皇岛经济技术开发区', value: '13032', tagName: '秦皇岛经济技术开发区(13032)' },
  { label: '河北秦皇岛出口加工区', value: '13035', tagName: '河北秦皇岛出口加工区(13035)' },
  { label: '秦皇岛综合保税区', value: '13036', tagName: '秦皇岛综合保税区(13036)' },
  { label: '秦皇岛其他', value: '13039', tagName: '秦皇岛其他(13039)' },
  { label: '邯郸', value: '13049', tagName: '邯郸(13049)' },
  { label: '邢台', value: '13059', tagName: '邢台(13059)' },
  { label: '保定高新技术产业开发区', value: '13063', tagName: '保定高新技术产业开发区(13063)' },
  { label: '保定其他', value: '13069', tagName: '保定其他(13069)' },
  { label: '雄安新区（保定）', value: '1306N', tagName: '雄安新区（保定）(1306N)' },
  { label: '张家口', value: '13079', tagName: '张家口(13079)' },
  { label: '承德', value: '13089', tagName: '承德(13089)' },
  { label: '沧州', value: '13099', tagName: '沧州(13099)' },
  { label: '雄安新区（沧州）', value: '1309N', tagName: '雄安新区（沧州）(1309N)' },
  { label: '廊坊经济技术开发区', value: '13102', tagName: '廊坊经济技术开发区(13102)' },
  { label: '河北廊坊出口加工区', value: '13105', tagName: '河北廊坊出口加工区(13105)' },
  { label: '廊坊综合保税区', value: '13106', tagName: '廊坊综合保税区(13106)' },
  { label: '廊坊', value: '13109', tagName: '廊坊(13109)' },
  { label: '衡水', value: '13119', tagName: '衡水(13119)' },
  { label: '武安', value: '13129', tagName: '武安(13129)' },
  { label: '河北武安保税物流中心（B型）', value: '1312W', tagName: '河北武安保税物流中心（B型）(1312W)' },
  { label: '河北雄安新区', value: '1331N', tagName: '河北雄安新区(1331N)' },
  { label: '河北其他', value: '13909', tagName: '河北其他(13909)' },
  { label: '山西太原经济技术开发区', value: '14012', tagName: '山西太原经济技术开发区(14012)' },
  { label: '太原高新技术产业开发区', value: '14013', tagName: '太原高新技术产业开发区(14013)' },
  { label: '太原武宿综合保税区', value: '14016', tagName: '太原武宿综合保税区(14016)' },
  { label: '太原其他', value: '14019', tagName: '太原其他(14019)' },
  { label: '大同经济技术开发区', value: '14022', tagName: '大同经济技术开发区(14022)' },
  { label: '大同', value: '14029', tagName: '大同(14029)' },
  { label: '大同国际陆港保税物流中心（B型）', value: '1402W', tagName: '大同国际陆港保税物流中心（B型）(1402W)' },
  { label: '阳泉', value: '14039', tagName: '阳泉(14039)' },
  { label: '长治高新技术产业开发区', value: '14043', tagName: '长治高新技术产业开发区(14043)' },
  { label: '长治', value: '14049', tagName: '长治(14049)' },
  { label: '晋城', value: '14059', tagName: '晋城(14059)' },
  { label: '山西兰花保税物流中心（B型）', value: '1405W', tagName: '山西兰花保税物流中心（B型）(1405W)' },
  { label: '朔州', value: '14069', tagName: '朔州(14069)' },
  { label: '雁北', value: '14079', tagName: '雁北(14079)' },
  { label: '忻州', value: '14089', tagName: '忻州(14089)' },
  { label: '吕梁', value: '14099', tagName: '吕梁(14099)' },
  { label: '晋中经济技术开发区', value: '14102', tagName: '晋中经济技术开发区(14102)' },
  { label: '晋中', value: '14109', tagName: '晋中(14109)' },
  { label: '临汾', value: '14119', tagName: '临汾(14119)' },
  { label: '山西方略保税物流中心', value: '1411W', tagName: '山西方略保税物流中心(1411W)' },
  { label: '运城', value: '14129', tagName: '运城(14129)' },
  { label: '古交', value: '14139', tagName: '古交(14139)' },
  { label: '山西其他', value: '14909', tagName: '山西其他(14909)' },
  { label: '内蒙古呼和浩特出口加工区', value: '15015', tagName: '内蒙古呼和浩特出口加工区(15015)' },
  { label: '呼和浩特综合保税区', value: '15016', tagName: '呼和浩特综合保税区(15016)' },
  { label: '呼和浩特', value: '15019', tagName: '呼和浩特(15019)' },
  { label: '包头高新技术产业开发区', value: '15023', tagName: '包头高新技术产业开发区(15023)' },
  { label: '包头其他', value: '15029', tagName: '包头其他(15029)' },
  { label: '包头保税物流中心（B型）', value: '1502W', tagName: '包头保税物流中心（B型）(1502W)' },
  { label: '乌海', value: '15039', tagName: '乌海(15039)' },
  { label: '赤峰', value: '15049', tagName: '赤峰(15049)' },
  { label: '赤峰保税物流中心', value: '1504W', tagName: '赤峰保税物流中心(1504W)' },
  { label: '二连', value: '15059', tagName: '二连(15059)' },
  { label: '满洲里综合保税区', value: '15066', tagName: '满洲里综合保税区(15066)' },
  { label: '满洲里', value: '15069', tagName: '满洲里(15069)' },
  { label: '呼伦贝尔盟', value: '15079', tagName: '呼伦贝尔盟(15079)' },
  { label: '哲里木盟', value: '15089', tagName: '哲里木盟(15089)' },
  { label: '兴安盟', value: '15099', tagName: '兴安盟(15099)' },
  { label: '乌兰察布盟', value: '15109', tagName: '乌兰察布盟(15109)' },
  { label: '七苏木保税物流中心（B型）', value: '1510W', tagName: '七苏木保税物流中心（B型）(1510W)' },
  { label: '巴彦淖尔市', value: '15119', tagName: '巴彦淖尔市(15119)' },
  { label: '巴彦淖尔市保税物流中心（B型）', value: '1511W', tagName: '巴彦淖尔市保税物流中心（B型）(1511W)' },
  { label: '鄂尔多斯综合保税区', value: '15126', tagName: '鄂尔多斯综合保税区(15126)' },
  { label: '伊克昭盟', value: '15129', tagName: '伊克昭盟(15129)' },
  { label: '阿拉善盟', value: '15139', tagName: '阿拉善盟(15139)' },
  { label: '锡林郭勒盟', value: '15149', tagName: '锡林郭勒盟(15149)' },
  { label: '内蒙古其他', value: '15909', tagName: '内蒙古其他(15909)' },
  { label: '沈阳经济技术开发区', value: '21012', tagName: '沈阳经济技术开发区(21012)' },
  { label: '沈阳南湖科技开发区', value: '21013', tagName: '沈阳南湖科技开发区(21013)' },
  { label: '辽宁沈阳、张士出口加工区', value: '21015', tagName: '辽宁沈阳、张士出口加工区(21015)' },
  { label: '沈阳综合保税区', value: '21016', tagName: '沈阳综合保税区(21016)' },
  { label: '沈阳其他', value: '21019', tagName: '沈阳其他(21019)' },
  { label: '大连经济技术开发区', value: '21022', tagName: '大连经济技术开发区(21022)' },
  { label: '大连高新技术产业园区', value: '21023', tagName: '大连高新技术产业园区(21023)' },
  { label: '大连大窑湾保税区', value: '21024', tagName: '大连大窑湾保税区(21024)' },
  { label: '辽宁大连出口加工区', value: '21025', tagName: '辽宁大连出口加工区(21025)' },
  { label: '大连大窑湾综合保税区', value: '21026', tagName: '大连大窑湾综合保税区(21026)' },
  { label: '大连保税物流园区', value: '21027', tagName: '大连保税物流园区(21027)' },
  { label: '大连其他', value: '21029', tagName: '大连其他(21029)' },
  { label: '鞍山高新技术产业开发区', value: '21033', tagName: '鞍山高新技术产业开发区(21033)' },
  { label: '鞍山其他', value: '21039', tagName: '鞍山其他(21039)' },
  { label: '抚顺', value: '21049', tagName: '抚顺(21049)' },
  { label: '本溪', value: '21059', tagName: '本溪(21059)' },
  { label: '丹东', value: '21069', tagName: '丹东(21069)' },
  { label: '锦州', value: '21079', tagName: '锦州(21079)' },
  { label: '营口经济技术开发区', value: '21082', tagName: '营口经济技术开发区(21082)' },
  { label: '营口综合保税区', value: '21086', tagName: '营口综合保税区(21086)' },
  { label: '营口', value: '21089', tagName: '营口(21089)' },
  { label: '营口港保税物流中心', value: '2108W', tagName: '营口港保税物流中心(2108W)' },
  { label: '阜新', value: '21099', tagName: '阜新(21099)' },
  { label: '辽阳', value: '21109', tagName: '辽阳(21109)' },
  { label: '盘锦', value: '21119', tagName: '盘锦(21119)' },
  { label: '盘锦港保税物流中心（B型）', value: '2111W', tagName: '盘锦港保税物流中心（B型）(2111W)' },
  { label: '铁岭', value: '21129', tagName: '铁岭(21129)' },
  { label: '铁岭保税物流中心（B型）', value: '2112W', tagName: '铁岭保税物流中心（B型）(2112W)' },
  { label: '朝阳', value: '21139', tagName: '朝阳(21139)' },
  { label: '葫芦岛市', value: '21149', tagName: '葫芦岛市(21149)' },
  { label: '瓦房店', value: '21159', tagName: '瓦房店(21159)' },
  { label: '海城', value: '21169', tagName: '海城(21169)' },
  { label: '兴城', value: '21179', tagName: '兴城(21179)' },
  { label: '铁法', value: '21189', tagName: '铁法(21189)' },
  { label: '北票', value: '21199', tagName: '北票(21199)' },
  { label: '开源', value: '21209', tagName: '开源(21209)' },
  { label: '辽宁其他', value: '21909', tagName: '辽宁其他(21909)' },
  { label: '长春经济技术开发区', value: '22012', tagName: '长春经济技术开发区(22012)' },
  { label: '长春南湖-南岭新技术产业园区', value: '22013', tagName: '长春南湖-南岭新技术产业园区(22013)' },
  { label: '长春兴隆综合保税区', value: '22016', tagName: '长春兴隆综合保税区(22016)' },
  { label: '长春其他', value: '22019', tagName: '长春其他(22019)' },
  { label: '吉林高新技术产业开发区', value: '22023', tagName: '吉林高新技术产业开发区(22023)' },
  { label: '吉林其他', value: '22029', tagName: '吉林其他(22029)' },
  { label: '吉林市保税物流中心（B型）', value: '2202W', tagName: '吉林市保税物流中心（B型）(2202W)' },
  { label: '四平', value: '22039', tagName: '四平(22039)' },
  { label: '辽源', value: '22049', tagName: '辽源(22049)' },
  { label: '通化', value: '22059', tagName: '通化(22059)' },
  { label: '白山', value: '22069', tagName: '白山(22069)' },
  { label: '吉林珲春出口加工区', value: '22075', tagName: '吉林珲春出口加工区(22075)' },
  { label: '珲春综合保税区', value: '22076', tagName: '珲春综合保税区(22076)' },
  { label: '珲春', value: '22079', tagName: '珲春(22079)' },
  { label: '图们', value: '22089', tagName: '图们(22089)' },
  { label: '白城', value: '22099', tagName: '白城(22099)' },
  { label: '延边', value: '22109', tagName: '延边(22109)' },
  { label: '公主岭', value: '22119', tagName: '公主岭(22119)' },
  { label: '梅河口', value: '22129', tagName: '梅河口(22129)' },
  { label: '集安', value: '22139', tagName: '集安(22139)' },
  { label: '桦甸', value: '22149', tagName: '桦甸(22149)' },
  { label: '九台', value: '22159', tagName: '九台(22159)' },
  { label: '蛟河', value: '22169', tagName: '蛟河(22169)' },
  { label: '松原', value: '22179', tagName: '松原(22179)' },
  { label: '延吉市', value: '22189', tagName: '延吉市(22189)' },
  { label: '延吉国际空港经济开发区保税物流中心（B型）', value: '2218W', tagName: '延吉国际空港经济开发区保税物流中心（B型）(2218W)' },
  { label: '吉林其他', value: '22909', tagName: '吉林其他(22909)' },
  { label: '哈尔滨经济技术开发区', value: '23012', tagName: '哈尔滨经济技术开发区(23012)' },
  { label: '哈尔滨高技术开发区', value: '23013', tagName: '哈尔滨高技术开发区(23013)' },
  { label: '哈尔滨综合保税区', value: '23016', tagName: '哈尔滨综合保税区(23016)' },
  { label: '哈尔滨其他', value: '23019', tagName: '哈尔滨其他(23019)' },
  { label: '齐齐哈尔', value: '23029', tagName: '齐齐哈尔(23029)' },
  { label: '鸡西', value: '23039', tagName: '鸡西(23039)' },
  { label: '鹤岗', value: '23049', tagName: '鹤岗(23049)' },
  { label: '双鸭山', value: '23059', tagName: '双鸭山(23059)' },
  { label: '大庆高新技术产业开发区', value: '23063', tagName: '大庆高新技术产业开发区(23063)' },
  { label: '大庆其他', value: '23069', tagName: '大庆其他(23069)' },
  { label: '伊春', value: '23079', tagName: '伊春(23079)' },
  { label: '佳木斯', value: '23089', tagName: '佳木斯(23089)' },
  { label: '七台河', value: '23099', tagName: '七台河(23099)' },
  { label: '牡丹江', value: '23109', tagName: '牡丹江(23109)' },
  { label: '牡丹江保税物流中心（B型）', value: '2310W', tagName: '牡丹江保税物流中心（B型）(2310W)' },
  { label: '黑河', value: '23119', tagName: '黑河(23119)' },
  { label: '黑河保税物流中心（B型）', value: '2311W', tagName: '黑河保税物流中心（B型）(2311W)' },
  { label: '绥芬河综合保税区', value: '23126', tagName: '绥芬河综合保税区(23126)' },
  { label: '绥芬河', value: '23129', tagName: '绥芬河(23129)' },
  { label: '松花江', value: '23139', tagName: '松花江(23139)' },
  { label: '绥化', value: '23149', tagName: '绥化(23149)' },
  { label: '大兴安岭', value: '23159', tagName: '大兴安岭(23159)' },
  { label: '阿城', value: '23169', tagName: '阿城(23169)' },
  { label: '同江', value: '23179', tagName: '同江(23179)' },
  { label: '富锦', value: '23189', tagName: '富锦(23189)' },
  { label: '铁力', value: '23199', tagName: '铁力(23199)' },
  { label: '密山', value: '23209', tagName: '密山(23209)' },
  { label: '黑龙江其他', value: '23909', tagName: '黑龙江其他(23909)' },
  { label: '黄浦', value: '31019', tagName: '黄浦(31019)' },
  { label: '南市', value: '31029', tagName: '南市(31029)' },
  { label: '卢湾', value: '31039', tagName: '卢湾(31039)' },
  { label: '上海漕河泾新技术开发区', value: '31043', tagName: '上海漕河泾新技术开发区(31043)' },
  { label: '徐汇其他', value: '31049', tagName: '徐汇其他(31049)' },
  { label: '上海经济技术开发区', value: '31052', tagName: '上海经济技术开发区(31052)' },
  { label: '长宁', value: '31059', tagName: '长宁(31059)' },
  { label: '静安', value: '31069', tagName: '静安(31069)' },
  { label: '普陀', value: '31079', tagName: '普陀(31079)' },
  { label: '上海西北物流园区保税物流中心', value: '3107W', tagName: '上海西北物流园区保税物流中心(3107W)' },
  { label: '闸北', value: '31089', tagName: '闸北(31089)' },
  { label: '虹口', value: '31099', tagName: '虹口(31099)' },
  { label: '杨浦', value: '31109', tagName: '杨浦(31109)' },
  { label: '上海闵行经济技术开发区', value: '31112', tagName: '上海闵行经济技术开发区(31112)' },
  { label: '上海浦江高科技园区', value: '31113', tagName: '上海浦江高科技园区(31113)' },
  { label: '上海漕河泾出口加工区', value: '31115', tagName: '上海漕河泾出口加工区(31115)' },
  { label: '漕河泾综合保税区', value: '31116', tagName: '漕河泾综合保税区(31116)' },
  { label: '闵行其他', value: '31119', tagName: '闵行其他(31119)' },
  { label: '虹桥商务区保税物流中心（B型）', value: '3111W', tagName: '虹桥商务区保税物流中心（B型）(3111W)' },
  { label: '宝山', value: '31129', tagName: '宝山(31129)' },
  { label: '上海嘉定出口加工区', value: '31145', tagName: '上海嘉定出口加工区(31145)' },
  { label: '上海嘉定综合保税区', value: '31146', tagName: '上海嘉定综合保税区(31146)' },
  { label: '嘉定', value: '31149', tagName: '嘉定(31149)' },
  { label: '川沙', value: '31159', tagName: '川沙(31159)' },
  { label: '上海闵行经济技术开发区（临港新城）', value: '31162', tagName: '上海闵行经济技术开发区（临港新城）(31162)' },
  { label: '洋山保税港区', value: '31166', tagName: '洋山保税港区(31166)' },
  { label: '南汇', value: '31169', tagName: '南汇(31169)' },
  { label: '上海闵行出口加工区', value: '31175', tagName: '上海闵行出口加工区(31175)' },
  { label: '奉贤综合保税区', value: '31176', tagName: '奉贤综合保税区(31176)' },
  { label: '奉贤', value: '31179', tagName: '奉贤(31179)' },
  { label: '上海松江出口加工区', value: '31185', tagName: '上海松江出口加工区(31185)' },
  { label: '松江综合保税区', value: '31186', tagName: '松江综合保税区(31186)' },
  { label: '松江', value: '31189', tagName: '松江(31189)' },
  { label: '金山', value: '31199', tagName: '金山(31199)' },
  { label: '上海青浦出口加工区', value: '31205', tagName: '上海青浦出口加工区(31205)' },
  { label: '青浦综合保税区', value: '31206', tagName: '青浦综合保税区(31206)' },
  { label: '青浦', value: '31209', tagName: '青浦(31209)' },
  { label: '崇明', value: '31219', tagName: '崇明(31219)' },
  { label: '上海浦东新区', value: '31222', tagName: '上海浦东新区(31222)' },
  { label: '上海外高桥保税区', value: '31224', tagName: '上海外高桥保税区(31224)' },
  { label: '上海金桥出口加工区南区', value: '31225', tagName: '上海金桥出口加工区南区(31225)' },
  { label: '上海浦东机场或金桥综合保税区', value: '31226', tagName: '上海浦东机场或金桥综合保税区(31226)' },
  { label: '上海保税物流园区', value: '31227', tagName: '上海保税物流园区(31227)' },
  { label: '浦东其他', value: '31229', tagName: '浦东其他(31229)' },
  { label: '洋山特殊综合保税区', value: '3122S', tagName: '洋山特殊综合保税区(3122S)' },
  { label: '上海其他', value: '31909', tagName: '上海其他(31909)' },
  { label: '南京浦口高新技术外向型开发区', value: '32013', tagName: '南京浦口高新技术外向型开发区(32013)' },
  { label: '江苏南京出口加工区', value: '32015', tagName: '江苏南京出口加工区(32015)' },
  { label: '南京综合保税区', value: '32016', tagName: '南京综合保税区(32016)' },
  { label: '南京其他', value: '32019', tagName: '南京其他(32019)' },
  { label: '南京空港保税物流中心（B型）', value: '3201W', tagName: '南京空港保税物流中心（B型）(3201W)' },
  { label: '无锡高新技术产业开发区', value: '32023', tagName: '无锡高新技术产业开发区(32023)' },
  { label: '江苏无锡出口加工区', value: '32025', tagName: '江苏无锡出口加工区(32025)' },
  { label: '无锡高新区综合保税区', value: '32026', tagName: '无锡高新区综合保税区(32026)' },
  { label: '无锡其他', value: '32029', tagName: '无锡其他(32029)' },
  { label: '徐州综合保税区', value: '32036', tagName: '徐州综合保税区(32036)' },
  { label: '徐州', value: '32039', tagName: '徐州(32039)' },
  { label: '徐州、新沂保税物流中心（B型）', value: '3203W', tagName: '徐州、新沂保税物流中心（B型）(3203W)' },
  { label: '常州高新技术产业开发区', value: '32043', tagName: '常州高新技术产业开发区(32043)' },
  { label: '江苏常州出口加工区', value: '32045', tagName: '江苏常州出口加工区(32045)' },
  { label: '常州综合保税区', value: '32046', tagName: '常州综合保税区(32046)' },
  { label: '常州其他', value: '32049', tagName: '常州其他(32049)' },
  { label: '苏州工业园区', value: '32052', tagName: '苏州工业园区(32052)' },
  { label: '苏州高新技术产业开发区', value: '32053', tagName: '苏州高新技术产业开发区(32053)' },
  { label: '江苏苏州工业园区加工区', value: '32055', tagName: '江苏苏州工业园区加工区(32055)' },
  { label: '苏州工业园综合保税区、苏州高新综保区', value: '32056', tagName: '苏州工业园综合保税区、苏州高新综保区(32056)' },
  { label: '苏州其他', value: '32059', tagName: '苏州其他(32059)' },
  { label: '南通经济技术开发区', value: '32062', tagName: '南通经济技术开发区(32062)' },
  { label: '江苏南通出口加工区', value: '32065', tagName: '江苏南通出口加工区(32065)' },
  { label: '南通综合保税区', value: '32066', tagName: '南通综合保税区(32066)' },
  { label: '南通其他', value: '32069', tagName: '南通其他(32069)' },
  { label: '如皋港保税物流中心（B型）', value: '3206W', tagName: '如皋港保税物流中心（B型）(3206W)' },
  { label: '连云港经济技术开发区', value: '32072', tagName: '连云港经济技术开发区(32072)' },
  { label: '江苏连云港出口加工区', value: '32075', tagName: '江苏连云港出口加工区(32075)' },
  { label: '连云港综合保税区', value: '32076', tagName: '连云港综合保税区(32076)' },
  { label: '连云港其他', value: '32079', tagName: '连云港其他(32079)' },
  { label: '连云港保税物流中心', value: '3207W', tagName: '连云港保税物流中心(3207W)' },
  { label: '江苏省淮安出口加工区', value: '32085', tagName: '江苏省淮安出口加工区(32085)' },
  { label: '淮安综合保税区', value: '32086', tagName: '淮安综合保税区(32086)' },
  { label: '淮安市', value: '32089', tagName: '淮安市(32089)' },
  { label: '盐城综合保税区', value: '32096', tagName: '盐城综合保税区(32096)' },
  { label: '盐城', value: '32099', tagName: '盐城(32099)' },
  { label: '大丰港保税物流中心（B型）', value: '3209W', tagName: '大丰港保税物流中心（B型）(3209W)' },
  { label: '江苏扬州出口加工区', value: '32105', tagName: '江苏扬州出口加工区(32105)' },
  { label: '扬州综合保税区', value: '32106', tagName: '扬州综合保税区(32106)' },
  { label: '扬州', value: '32109', tagName: '扬州(32109)' },
  { label: '江苏镇江出口加工区', value: '32115', tagName: '江苏镇江出口加工区(32115)' },
  { label: '镇江综合保税区', value: '32116', tagName: '镇江综合保税区(32116)' },
  { label: '镇江', value: '32119', tagName: '镇江(32119)' },
  { label: '黑河保税物流中心B型', value: '3211W', tagName: '黑河保税物流中心B型(3211W)' },
  { label: '江苏泰州出口加工区', value: '32125', tagName: '江苏泰州出口加工区(32125)' },
  { label: '泰州综合保税区', value: '32126', tagName: '泰州综合保税区(32126)' },
  { label: '泰州', value: '32129', tagName: '泰州(32129)' },
  { label: '仪征', value: '32139', tagName: '仪征(32139)' },
  { label: '江苏常熟出口加工区', value: '32145', tagName: '江苏常熟出口加工区(32145)' },
  { label: '常熟综合保税区', value: '32146', tagName: '常熟综合保税区(32146)' },
  { label: '常熟', value: '32149', tagName: '常熟(32149)' },
  { label: '江苏张家港保税区', value: '32154', tagName: '江苏张家港保税区(32154)' },
  { label: '张家港保税港区', value: '32156', tagName: '张家港保税港区(32156)' },
  { label: '张家港保税物流园', value: '32157', tagName: '张家港保税物流园(32157)' },
  { label: '张家港其他', value: '32159', tagName: '张家港其他(32159)' },
  { label: '江阴综合保税区', value: '32166', tagName: '江阴综合保税区(32166)' },
  { label: '江阴', value: '32169', tagName: '江阴(32169)' },
  { label: '江阴保税物流中心', value: '3216W', tagName: '江阴保税物流中心(3216W)' },
  { label: '宿迁', value: '32179', tagName: '宿迁(32179)' },
  { label: '丹阳', value: '32189', tagName: '丹阳(32189)' },
  { label: '东台', value: '32199', tagName: '东台(32199)' },
  { label: '兴化', value: '32209', tagName: '兴化(32209)' },
  { label: '宜兴', value: '32229', tagName: '宜兴(32229)' },
  { label: '江苏昆山出口加工区', value: '32235', tagName: '江苏昆山出口加工区(32235)' },
  { label: '昆山综合保税区', value: '32236', tagName: '昆山综合保税区(32236)' },
  { label: '昆山', value: '32239', tagName: '昆山(32239)' },
  { label: '启东', value: '32249', tagName: '启东(32249)' },
  { label: '江苏吴江出口加工区', value: '32255', tagName: '江苏吴江出口加工区(32255)' },
  { label: '吴江综合保税区', value: '32256', tagName: '吴江综合保税区(32256)' },
  { label: '吴江市', value: '32259', tagName: '吴江市(32259)' },
  { label: '太仓港综合保税区', value: '32266', tagName: '太仓港综合保税区(32266)' },
  { label: '太仓市', value: '32269', tagName: '太仓市(32269)' },
  { label: '太仓保税物流中心', value: '3226W', tagName: '太仓保税物流中心(3226W)' },
  { label: '武进综合保税区', value: '32546', tagName: '武进综合保税区(32546)' },
  { label: '江苏海安保税物流中心（B型）', value: '3256W', tagName: '江苏海安保税物流中心（B型）(3256W)' },
  { label: '江苏其他', value: '32909', tagName: '江苏其他(32909)' },
  { label: '杭州经济技术开发区', value: '33012', tagName: '杭州经济技术开发区(33012)' },
  { label: '杭州高新技术产业开发区', value: '33013', tagName: '杭州高新技术产业开发区(33013)' },
  { label: '浙江杭州出口加工区', value: '33015', tagName: '浙江杭州出口加工区(33015)' },
  { label: '杭州综合保税区', value: '33016', tagName: '杭州综合保税区(33016)' },
  { label: '杭州其他', value: '33019', tagName: '杭州其他(33019)' },
  { label: '宁波经济技术开发区', value: '33022', tagName: '宁波经济技术开发区(33022)' },
  { label: '宁波高新技术产业开发区', value: '33023', tagName: '宁波高新技术产业开发区(33023)' },
  { label: '宁波北仑港保税区', value: '33024', tagName: '宁波北仑港保税区(33024)' },
  { label: '浙江宁波出口加工区', value: '33025', tagName: '浙江宁波出口加工区(33025)' },
  { label: '宁波梅山综合保税区', value: '33026', tagName: '宁波梅山综合保税区(33026)' },
  { label: '宁波保税物流园', value: '33027', tagName: '宁波保税物流园(33027)' },
  { label: '宁波其他', value: '33029', tagName: '宁波其他(33029)' },
  { label: '宁波栎社保税物流中心', value: '3302W', tagName: '宁波栎社保税物流中心(3302W)' },
  { label: '温州经济技术开发区', value: '33032', tagName: '温州经济技术开发区(33032)' },
  { label: '温州综合保税区', value: '33036', tagName: '温州综合保税区(33036)' },
  { label: '温州其他', value: '33039', tagName: '温州其他(33039)' },
  { label: '温州保税物流中心（B型）', value: '3303W', tagName: '温州保税物流中心（B型）(3303W)' },
  { label: '浙江嘉兴出口加工区', value: '33045', tagName: '浙江嘉兴出口加工区(33045)' },
  { label: '嘉兴综合保税区', value: '33046', tagName: '嘉兴综合保税区(33046)' },
  { label: '嘉兴', value: '33049', tagName: '嘉兴(33049)' },
  { label: '湖州经济技术开发区', value: '33052', tagName: '湖州经济技术开发区(33052)' },
  { label: '湖州', value: '33059', tagName: '湖州(33059)' },
  { label: '湖州保税物流中心（B型）', value: '3305W', tagName: '湖州保税物流中心（B型）(3305W)' },
  { label: '绍兴综合保税区', value: '33066', tagName: '绍兴综合保税区(33066)' },
  { label: '绍兴', value: '33069', tagName: '绍兴(33069)' },
  { label: '金华经济技术开发区', value: '33072', tagName: '金华经济技术开发区(33072)' },
  { label: '金义综合保税区（一期）', value: '33076', tagName: '金义综合保税区（一期）(33076)' },
  { label: '金华', value: '33079', tagName: '金华(33079)' },
  { label: '衢州', value: '33089', tagName: '衢州(33089)' },
  { label: '舟山港综合保税区', value: '33096', tagName: '舟山港综合保税区(33096)' },
  { label: '舟山', value: '33099', tagName: '舟山(33099)' },
  { label: '丽水', value: '33109', tagName: '丽水(33109)' },
  { label: '台州', value: '33119', tagName: '台州(33119)' },
  { label: '余姚', value: '33129', tagName: '余姚(33129)' },
  { label: '海宁', value: '33139', tagName: '海宁(33139)' },
  { label: '兰溪', value: '33149', tagName: '兰溪(33149)' },
  { label: '瑞安', value: '33159', tagName: '瑞安(33159)' },
  { label: '萧山', value: '33169', tagName: '萧山(33169)' },
  { label: '杭州保税物流中心', value: '3316W', tagName: '杭州保税物流中心(3316W)' },
  { label: '江山', value: '33179', tagName: '江山(33179)' },
  { label: '义乌综合保税区', value: '33186', tagName: '义乌综合保税区(33186)' },
  { label: '义乌', value: '33189', tagName: '义乌(33189)' },
  { label: '义乌保税物流中心', value: '3318W', tagName: '义乌保税物流中心(3318W)' },
  { label: '东阳', value: '33199', tagName: '东阳(33199)' },
  { label: '宁波杭州湾经济技术开发区', value: '33202', tagName: '宁波杭州湾经济技术开发区(33202)' },
  { label: '浙江慈溪出口加工区', value: '33205', tagName: '浙江慈溪出口加工区(33205)' },
  { label: '慈溪', value: '33209', tagName: '慈溪(33209)' },
  { label: '奉化', value: '33219', tagName: '奉化(33219)' },
  { label: '诸暨', value: '33229', tagName: '诸暨(33229)' },
  { label: '黄岩', value: '33239', tagName: '黄岩(33239)' },
  { label: '宁波镇海保税物流中心（B型）', value: '3352W', tagName: '宁波镇海保税物流中心（B型）(3352W)' },
  { label: '浙江其他', value: '33909', tagName: '浙江其他(33909)' },
  { label: '合肥经济技术开发区', value: '34012', tagName: '合肥经济技术开发区(34012)' },
  { label: '合肥科技工业园区', value: '34013', tagName: '合肥科技工业园区(34013)' },
  { label: '安徽合肥出口加工区', value: '34015', tagName: '安徽合肥出口加工区(34015)' },
  { label: '合肥或合肥经济技术开发区综合保税区', value: '34016', tagName: '合肥或合肥经济技术开发区综合保税区(34016)' },
  { label: '合肥其他', value: '34019', tagName: '合肥其他(34019)' },
  { label: '合肥空港保税物流中心（B型）', value: '3401W', tagName: '合肥空港保税物流中心（B型）(3401W)' },
  { label: '芜湖经济技术开发区', value: '34022', tagName: '芜湖经济技术开发区(34022)' },
  { label: '芜湖高新技术产业开发区', value: '34023', tagName: '芜湖高新技术产业开发区(34023)' },
  { label: '安徽芜湖出口加工区', value: '34025', tagName: '安徽芜湖出口加工区(34025)' },
  { label: '芜湖综合保税区', value: '34026', tagName: '芜湖综合保税区(34026)' },
  { label: '芜湖其他', value: '34029', tagName: '芜湖其他(34029)' },
  { label: '蚌埠高新技术产业开发区', value: '34033', tagName: '蚌埠高新技术产业开发区(34033)' },
  { label: '蚌埠', value: '34039', tagName: '蚌埠(34039)' },
  { label: '蚌埠（皖北）保税物流中心', value: '3403W', tagName: '蚌埠（皖北）保税物流中心(3403W)' },
  { label: '安徽淮南经济技术开发区', value: '34042', tagName: '安徽淮南经济技术开发区(34042)' },
  { label: '淮南高新技术产业开发区', value: '34043', tagName: '淮南高新技术产业开发区(34043)' },
  { label: '淮南', value: '34049', tagName: '淮南(34049)' },
  { label: '马鞍山经济技术开发区', value: '34052', tagName: '马鞍山经济技术开发区(34052)' },
  { label: '马鞍山慈湖高新技术产业开发区', value: '34053', tagName: '马鞍山慈湖高新技术产业开发区(34053)' },
  { label: '马鞍山综合保税区', value: '34056', tagName: '马鞍山综合保税区(34056)' },
  { label: '马鞍山', value: '34059', tagName: '马鞍山(34059)' },
  { label: '淮北', value: '34069', tagName: '淮北(34069)' },
  { label: '铜陵经济技术开发区', value: '34072', tagName: '铜陵经济技术开发区(34072)' },
  { label: '铜陵', value: '34079', tagName: '铜陵(34079)' },
  { label: '铜陵（皖中南）保税物流中心（B型）', value: '3407W', tagName: '铜陵（皖中南）保税物流中心（B型）(3407W)' },
  { label: '安庆桐城经济技术开发区', value: '34082', tagName: '安庆桐城经济技术开发区(34082)' },
  { label: '安庆', value: '34089', tagName: '安庆(34089)' },
  { label: '安庆（皖西南）保税物流中心（B型）', value: '3408W', tagName: '安庆（皖西南）保税物流中心（B型）(3408W)' },
  { label: '黄山', value: '34099', tagName: '黄山(34099)' },
  { label: '阜阳', value: '34109', tagName: '阜阳(34109)' },
  { label: '宿州', value: '34119', tagName: '宿州(34119)' },
  { label: '滁州经济技术开发区', value: '34122', tagName: '滁州经济技术开发区(34122)' },
  { label: '滁州', value: '34129', tagName: '滁州(34129)' },
  { label: '安徽六安经济技术开发区', value: '34132', tagName: '安徽六安经济技术开发区(34132)' },
  { label: '六安', value: '34139', tagName: '六安(34139)' },
  { label: '宣城宁国经济技术开发区', value: '34142', tagName: '宣城宁国经济技术开发区(34142)' },
  { label: '宣城', value: '34149', tagName: '宣城(34149)' },
  { label: '安徽皖东南保税物流中心（B型）', value: '3414W', tagName: '安徽皖东南保税物流中心（B型）(3414W)' },
  { label: '巢湖', value: '34159', tagName: '巢湖(34159)' },
  { label: '池州经济技术开发区', value: '34162', tagName: '池州经济技术开发区(34162)' },
  { label: '池州', value: '34169', tagName: '池州(34169)' },
  { label: '亳州', value: '34179', tagName: '亳州(34179)' },
  { label: '安徽其他', value: '34909', tagName: '安徽其他(34909)' },
  { label: '福州经济技术开发区', value: '35012', tagName: '福州经济技术开发区(35012)' },
  { label: '福州科技园区', value: '35013', tagName: '福州科技园区(35013)' },
  { label: '福建马尾保税区', value: '35014', tagName: '福建马尾保税区(35014)' },
  { label: '福建福州、福清出口加工区', value: '35015', tagName: '福建福州、福清出口加工区(35015)' },
  { label: '福州江阴港综合保税区', value: '35016', tagName: '福州江阴港综合保税区(35016)' },
  { label: '福州保税物流园区', value: '35017', tagName: '福州保税物流园区(35017)' },
  { label: '福州其他', value: '35019', tagName: '福州其他(35019)' },
  { label: '翔福保税物流中心（B型）', value: '3501W', tagName: '翔福保税物流中心（B型）(3501W)' },
  { label: '厦门特区', value: '35021', tagName: '厦门特区(35021)' },
  { label: '厦门火炬高技术产业开发区', value: '35023', tagName: '厦门火炬高技术产业开发区(35023)' },
  { label: '厦门象屿保税区', value: '35024', tagName: '厦门象屿保税区(35024)' },
  { label: '福建厦门出口加工区', value: '35025', tagName: '福建厦门出口加工区(35025)' },
  { label: '厦门海沧港综合保税区', value: '35026', tagName: '厦门海沧港综合保税区(35026)' },
  { label: '厦门象屿保税物流园', value: '35027', tagName: '厦门象屿保税物流园(35027)' },
  { label: '厦门其他', value: '35029', tagName: '厦门其他(35029)' },
  { label: '厦门火炬（翔安）保税物流中心', value: '3502W', tagName: '厦门火炬（翔安）保税物流中心(3502W)' },
  { label: '莆田', value: '35039', tagName: '莆田(35039)' },
  { label: '三明', value: '35049', tagName: '三明(35049)' },
  { label: '福建泉州出口加工区', value: '35055', tagName: '福建泉州出口加工区(35055)' },
  { label: '泉州综合保税区', value: '35056', tagName: '泉州综合保税区(35056)' },
  { label: '泉州', value: '35059', tagName: '泉州(35059)' },
  { label: '漳州', value: '35069', tagName: '漳州(35069)' },
  { label: '漳州台商投资区保税物流中心（B型）', value: '3506W', tagName: '漳州台商投资区保税物流中心（B型）(3506W)' },
  { label: '南平', value: '35079', tagName: '南平(35079)' },
  { label: '宁德', value: '35089', tagName: '宁德(35089)' },
  { label: '龙岩', value: '35099', tagName: '龙岩(35099)' },
  { label: '永安', value: '35109', tagName: '永安(35109)' },
  { label: '石狮', value: '35119', tagName: '石狮(35119)' },
  { label: '平潭综合试验区', value: '35128', tagName: '平潭综合试验区(35128)' },
  { label: '平潭', value: '35129', tagName: '平潭(35129)' },
  { label: '福建其他', value: '35909', tagName: '福建其他(35909)' },
  { label: '南昌及南昌小蓝经济技术开发区', value: '36012', tagName: '南昌及南昌小蓝经济技术开发区(36012)' },
  { label: '南昌高新技术产业开发区', value: '36013', tagName: '南昌高新技术产业开发区(36013)' },
  { label: '江西南昌出口加工区', value: '36015', tagName: '江西南昌出口加工区(36015)' },
  { label: '南昌综合保税区', value: '36016', tagName: '南昌综合保税区(36016)' },
  { label: '南昌其他', value: '36019', tagName: '南昌其他(36019)' },
  { label: '南昌保税物流中心', value: '3601W', tagName: '南昌保税物流中心(3601W)' },
  { label: '景德镇高新技术产业开发区', value: '36023', tagName: '景德镇高新技术产业开发区(36023)' },
  { label: '景德镇', value: '36029', tagName: '景德镇(36029)' },
  { label: '萍乡经济技术开发区', value: '36032', tagName: '萍乡经济技术开发区(36032)' },
  { label: '萍乡', value: '36039', tagName: '萍乡(36039)' },
  { label: '九江经济技术开发区', value: '36042', tagName: '九江经济技术开发区(36042)' },
  { label: '九江共青城高新技术产业开发区', value: '36043', tagName: '九江共青城高新技术产业开发区(36043)' },
  { label: '江西九江出口加工区', value: '36045', tagName: '江西九江出口加工区(36045)' },
  { label: '九江综合保税区', value: '36046', tagName: '九江综合保税区(36046)' },
  { label: '九江', value: '36049', tagName: '九江(36049)' },
  { label: '新余高新技术产业开发区', value: '36053', tagName: '新余高新技术产业开发区(36053)' },
  { label: '新余', value: '36059', tagName: '新余(36059)' },
  { label: '鹰潭高新技术产业开发区', value: '36063', tagName: '鹰潭高新技术产业开发区(36063)' },
  { label: '鹰潭', value: '36069', tagName: '鹰潭(36069)' },
  { label: '赣州、龙南及瑞金经济技术开发区', value: '36072', tagName: '赣州、龙南及瑞金经济技术开发区(36072)' },
  { label: '赣州高新技术产业开发区', value: '36073', tagName: '赣州高新技术产业开发区(36073)' },
  { label: '江西赣州出口加工区', value: '36075', tagName: '江西赣州出口加工区(36075)' },
  { label: '赣州综合保税区', value: '36076', tagName: '赣州综合保税区(36076)' },
  { label: '赣州', value: '36079', tagName: '赣州(36079)' },
  { label: '龙南保税物流中心（B型）', value: '3607W', tagName: '龙南保税物流中心（B型）(3607W)' },
  { label: '宜春经济技术开发区', value: '36082', tagName: '宜春经济技术开发区(36082)' },
  { label: '宜春丰城高新技术产业开发区', value: '36083', tagName: '宜春丰城高新技术产业开发区(36083)' },
  { label: '宜春', value: '36089', tagName: '宜春(36089)' },
  { label: '上饶经济技术开发区', value: '36092', tagName: '上饶经济技术开发区(36092)' },
  { label: '上饶', value: '36099', tagName: '上饶(36099)' },
  { label: '井冈山经济技术开发区', value: '36102', tagName: '井冈山经济技术开发区(36102)' },
  { label: '吉安高新技术产业开发区', value: '36103', tagName: '吉安高新技术产业开发区(36103)' },
  { label: '井冈山出口加工区', value: '36105', tagName: '井冈山出口加工区(36105)' },
  { label: '井冈山综合保税区', value: '36106', tagName: '井冈山综合保税区(36106)' },
  { label: '吉安', value: '36109', tagName: '吉安(36109)' },
  { label: '抚州高新技术产业开发区', value: '36113', tagName: '抚州高新技术产业开发区(36113)' },
  { label: '抚州', value: '36119', tagName: '抚州(36119)' },
  { label: '瑞昌', value: '36129', tagName: '瑞昌(36129)' },
  { label: '江西其他', value: '36909', tagName: '江西其他(36909)' },
  { label: '济南高技术产业开发区', value: '37013', tagName: '济南高技术产业开发区(37013)' },
  { label: '山东济南出口加工区', value: '37015', tagName: '山东济南出口加工区(37015)' },
  { label: '济南或济南章锦综合保税区', value: '37016', tagName: '济南或济南章锦综合保税区(37016)' },
  { label: '济南其他', value: '37019', tagName: '济南其他(37019)' },
  { label: '青岛经济技术开发区', value: '37022', tagName: '青岛经济技术开发区(37022)' },
  { label: '青岛高新技术产业开发区', value: '37023', tagName: '青岛高新技术产业开发区(37023)' },
  { label: '青岛保税区', value: '37024', tagName: '青岛保税区(37024)' },
  { label: '山东青岛出口加工区', value: '37025', tagName: '山东青岛出口加工区(37025)' },
  { label: '青岛前湾保税港区、青岛西海岸和胶州湾综合保税区', value: '37026', tagName: '青岛前湾保税港区、青岛西海岸和胶州湾综合保税区(37026)' },
  { label: '青岛保税物流园', value: '37027', tagName: '青岛保税物流园(37027)' },
  { label: '青岛其他', value: '37029', tagName: '青岛其他(37029)' },
  { label: '青岛保税物流中心', value: '3702W', tagName: '青岛保税物流中心(3702W)' },
  { label: '淄博高新技术产业开发区', value: '37033', tagName: '淄博高新技术产业开发区(37033)' },
  { label: '淄博综合保税区', value: '37036', tagName: '淄博综合保税区(37036)' },
  { label: '淄博', value: '37039', tagName: '淄博(37039)' },
  { label: '淄博保税物流中心', value: '3703W', tagName: '淄博保税物流中心(3703W)' },
  { label: '枣庄', value: '37049', tagName: '枣庄(37049)' },
  { label: '东营综合保税区', value: '37056', tagName: '东营综合保税区(37056)' },
  { label: '东营', value: '37059', tagName: '东营(37059)' },
  { label: '烟台经济技术开发区', value: '37062', tagName: '烟台经济技术开发区(37062)' },
  { label: '山东烟台出口加工区', value: '37065', tagName: '山东烟台出口加工区(37065)' },
  { label: '烟台综合保税区', value: '37066', tagName: '烟台综合保税区(37066)' },
  { label: '烟台其他', value: '37069', tagName: '烟台其他(37069)' },
  { label: '烟台福山回里保税物流中心（B型）', value: '3706W', tagName: '烟台福山回里保税物流中心（B型）(3706W)' },
  { label: '潍坊高新技术产业开发区', value: '37073', tagName: '潍坊高新技术产业开发区(37073)' },
  { label: '山东潍坊出口加工区', value: '37075', tagName: '山东潍坊出口加工区(37075)' },
  { label: '潍坊综合保税区', value: '37076', tagName: '潍坊综合保税区(37076)' },
  { label: '潍坊其他', value: '37079', tagName: '潍坊其他(37079)' },
  { label: '济宁', value: '37089', tagName: '济宁(37089)' },
  { label: '泰安', value: '37099', tagName: '泰安(37099)' },
  { label: '威海火炬高技术产业开发区', value: '37103', tagName: '威海火炬高技术产业开发区(37103)' },
  { label: '山东威海出口加工区', value: '37105', tagName: '山东威海出口加工区(37105)' },
  { label: '威海综合保税区北区', value: '37106', tagName: '威海综合保税区北区(37106)' },
  { label: '威海其他', value: '37109', tagName: '威海其他(37109)' },
  { label: '日照经济技术开发区', value: '37112', tagName: '日照经济技术开发区(37112)' },
  { label: '日照综合保税区', value: '37116', tagName: '日照综合保税区(37116)' },
  { label: '日照', value: '37119', tagName: '日照(37119)' },
  { label: '日照保税物流中心', value: '3711W', tagName: '日照保税物流中心(3711W)' },
  { label: '山东省滨州其他', value: '37129', tagName: '山东省滨州其他(37129)' },
  { label: '鲁中运达保税物流中心', value: '3712W', tagName: '鲁中运达保税物流中心(3712W)' },
  { label: '德州', value: '37139', tagName: '德州(37139)' },
  { label: '聊城', value: '37149', tagName: '聊城(37149)' },
  { label: '临沂综合保税区', value: '37156', tagName: '临沂综合保税区(37156)' },
  { label: '临沂', value: '37159', tagName: '临沂(37159)' },
  { label: '菏泽', value: '37169', tagName: '菏泽(37169)' },
  { label: '菏泽内陆港保税物流中心（B型）', value: '3716W', tagName: '菏泽内陆港保税物流中心（B型）(3716W)' },
  { label: '青州', value: '37179', tagName: '青州(37179)' },
  { label: '龙口', value: '37189', tagName: '龙口(37189)' },
  { label: '曲阜', value: '37199', tagName: '曲阜(37199)' },
  { label: '莱芜', value: '37209', tagName: '莱芜(37209)' },
  { label: '新泰', value: '37219', tagName: '新泰(37219)' },
  { label: '胶州', value: '37229', tagName: '胶州(37229)' },
  { label: '诸城', value: '37239', tagName: '诸城(37239)' },
  { label: '青岛保税港区诸城功能区保税物流中心（B型）', value: '3723W', tagName: '青岛保税港区诸城功能区保税物流中心（B型）(3723W)' },
  { label: '莱阳', value: '37249', tagName: '莱阳(37249)' },
  { label: '滕州', value: '37259', tagName: '滕州(37259)' },
  { label: '威海综合保税区南区', value: '37266', tagName: '威海综合保税区南区(37266)' },
  { label: '文登', value: '37269', tagName: '文登(37269)' },
  { label: '荣城', value: '37279', tagName: '荣城(37279)' },
  { label: '青岛即墨综合保税区', value: '37286', tagName: '青岛即墨综合保税区(37286)' },
  { label: '即墨', value: '37289', tagName: '即墨(37289)' },
  { label: '平度', value: '37299', tagName: '平度(37299)' },
  { label: '山东其他', value: '37909', tagName: '山东其他(37909)' },
  { label: '郑州经济技术开发区', value: '41012', tagName: '郑州经济技术开发区(41012)' },
  { label: '郑州高新技术开发区', value: '41013', tagName: '郑州高新技术开发区(41013)' },
  { label: '河南郑州出口加工区', value: '41015', tagName: '河南郑州出口加工区(41015)' },
  { label: '郑州新郑、经开综合保税区', value: '41016', tagName: '郑州新郑、经开综合保税区(41016)' },
  { label: '郑州航空港经济综合实验区', value: '41018', tagName: '郑州航空港经济综合实验区(41018)' },
  { label: '郑州其他', value: '41019', tagName: '郑州其他(41019)' },
  { label: '河南保税物流中心', value: '4101W', tagName: '河南保税物流中心(4101W)' },
  { label: '开封', value: '41029', tagName: '开封(41029)' },
  { label: '洛阳高新技术产业开发区', value: '41033', tagName: '洛阳高新技术产业开发区(41033)' },
  { label: '洛阳其他', value: '41039', tagName: '洛阳其他(41039)' },
  { label: '平顶山', value: '41049', tagName: '平顶山(41049)' },
  { label: '安阳', value: '41059', tagName: '安阳(41059)' },
  { label: '鹤壁', value: '41069', tagName: '鹤壁(41069)' },
  { label: '新乡', value: '41079', tagName: '新乡(41079)' },
  { label: '焦作', value: '41089', tagName: '焦作(41089)' },
  { label: '河南德众保税物流中心', value: '4108W', tagName: '河南德众保税物流中心(4108W)' },
  { label: '濮阳', value: '41099', tagName: '濮阳(41099)' },
  { label: '许昌', value: '41109', tagName: '许昌(41109)' },
  { label: '漯河', value: '41119', tagName: '漯河(41119)' },
  { label: '三门峡', value: '41129', tagName: '三门峡(41129)' },
  { label: '商丘', value: '41139', tagName: '商丘(41139)' },
  { label: '河南商丘、民权保税物流中心（B型）', value: '4113W', tagName: '河南商丘、民权保税物流中心（B型）(4113W)' },
  { label: '周口', value: '41149', tagName: '周口(41149)' },
  { label: '驻马店', value: '41159', tagName: '驻马店(41159)' },
  { label: '南阳卧龙综合保税区', value: '41166', tagName: '南阳卧龙综合保税区(41166)' },
  { label: '南阳', value: '41169', tagName: '南阳(41169)' },
  { label: '信阳', value: '41179', tagName: '信阳(41179)' },
  { label: '义马', value: '41189', tagName: '义马(41189)' },
  { label: '汝州', value: '41199', tagName: '汝州(41199)' },
  { label: '济源', value: '41209', tagName: '济源(41209)' },
  { label: '禹州', value: '41219', tagName: '禹州(41219)' },
  { label: '卫辉', value: '41229', tagName: '卫辉(41229)' },
  { label: '辉县', value: '41239', tagName: '辉县(41239)' },
  { label: '泌阳', value: '41249', tagName: '泌阳(41249)' },
  { label: '河南其他', value: '41909', tagName: '河南其他(41909)' },
  { label: '武汉经济技术开发区', value: '42012', tagName: '武汉经济技术开发区(42012)' },
  { label: '武汉东湖新技术开发区', value: '42013', tagName: '武汉东湖新技术开发区(42013)' },
  { label: '湖北武汉出口加工区', value: '42015', tagName: '湖北武汉出口加工区(42015)' },
  { label: '武汉东湖综合保税区', value: '42016', tagName: '武汉东湖综合保税区(42016)' },
  { label: '武汉其他', value: '42019', tagName: '武汉其他(42019)' },
  { label: '武汉东西湖保税物流中心', value: '4201W', tagName: '武汉东西湖保税物流中心(4201W)' },
  { label: '黄石经济技术开发区', value: '42022', tagName: '黄石经济技术开发区(42022)' },
  { label: '黄石', value: '42029', tagName: '黄石(42029)' },
  { label: '黄石棋盘洲保税物流中心', value: '4202W', tagName: '黄石棋盘洲保税物流中心(4202W)' },
  { label: '十堰', value: '42039', tagName: '十堰(42039)' },
  { label: '沙市', value: '42049', tagName: '沙市(42049)' },
  { label: '宜昌综合保税区', value: '42056', tagName: '宜昌综合保税区(42056)' },
  { label: '宜昌', value: '42059', tagName: '宜昌(42059)' },
  { label: '宜昌三峡保税物流中心（B型）', value: '4205W', tagName: '宜昌三峡保税物流中心（B型）(4205W)' },
  { label: '襄阳经济技术开发区', value: '42062', tagName: '襄阳经济技术开发区(42062)' },
  { label: '襄阳高新技术产业开发区', value: '42063', tagName: '襄阳高新技术产业开发区(42063)' },
  { label: '襄阳其他', value: '42069', tagName: '襄阳其他(42069)' },
  { label: '襄阳保税物流中心（B型）', value: '4206W', tagName: '襄阳保税物流中心（B型）(4206W)' },
  { label: '鄂州', value: '42079', tagName: '鄂州(42079)' },
  { label: '荆门', value: '42089', tagName: '荆门(42089)' },
  { label: '荆门保税物流中心（B型）', value: '4208W', tagName: '荆门保税物流中心（B型）(4208W)' },
  { label: '黄冈', value: '42099', tagName: '黄冈(42099)' },
  { label: '孝感', value: '42109', tagName: '孝感(42109)' },
  { label: '咸宁', value: '42119', tagName: '咸宁(42119)' },
  { label: '荆州经济技术开发区', value: '42122', tagName: '荆州经济技术开发区(42122)' },
  { label: '荆州', value: '42129', tagName: '荆州(42129)' },
  { label: '郧阳', value: '42139', tagName: '郧阳(42139)' },
  { label: '鄂西', value: '42149', tagName: '鄂西(42149)' },
  { label: '随州', value: '42159', tagName: '随州(42159)' },
  { label: '老河口', value: '42169', tagName: '老河口(42169)' },
  { label: '枣阳', value: '42179', tagName: '枣阳(42179)' },
  { label: '神农架', value: '42189', tagName: '神农架(42189)' },
  { label: '仙桃市', value: '42199', tagName: '仙桃市(42199)' },
  { label: '仙桃保税物流中心（B型）', value: '4219W', tagName: '仙桃保税物流中心（B型）(4219W)' },
  { label: '潜江市', value: '42209', tagName: '潜江市(42209)' },
  { label: '天门市', value: '42219', tagName: '天门市(42219)' },
  { label: '武汉新港空港', value: '42506', tagName: '武汉新港空港(42506)' },
  { label: '湖北其他', value: '42909', tagName: '湖北其他(42909)' },
  { label: '长沙经济技术开发区', value: '43012', tagName: '长沙经济技术开发区(43012)' },
  { label: '长沙科技开发区', value: '43013', tagName: '长沙科技开发区(43013)' },
  { label: '长沙黄花综合保税区', value: '43016', tagName: '长沙黄花综合保税区(43016)' },
  { label: '长沙其他', value: '43019', tagName: '长沙其他(43019)' },
  { label: '长沙金霞保税物流中心', value: '4301W', tagName: '长沙金霞保税物流中心(4301W)' },
  { label: '株州高新技术产业开发区', value: '43023', tagName: '株州高新技术产业开发区(43023)' },
  { label: '株州其他', value: '43029', tagName: '株州其他(43029)' },
  { label: '株洲铜塘湾保税物流中心（B型）', value: '4302W', tagName: '株洲铜塘湾保税物流中心（B型）(4302W)' },
  { label: '湘潭经济技术开发区', value: '43032', tagName: '湘潭经济技术开发区(43032)' },
  { label: '湘潭高新技术产业开发区', value: '43033', tagName: '湘潭高新技术产业开发区(43033)' },
  { label: '湘潭综合保税区', value: '43036', tagName: '湘潭综合保税区(43036)' },
  { label: '湘潭', value: '43039', tagName: '湘潭(43039)' },
  { label: '衡阳高新技术产业开发区', value: '43043', tagName: '衡阳高新技术产业开发区(43043)' },
  { label: '衡阳综合保税区', value: '43046', tagName: '衡阳综合保税区(43046)' },
  { label: '衡阳', value: '43049', tagName: '衡阳(43049)' },
  { label: '邵阳', value: '43059', tagName: '邵阳(43059)' },
  { label: '岳阳经济技术开发区', value: '43062', tagName: '岳阳经济技术开发区(43062)' },
  { label: '岳阳城陵矶综合保税区', value: '43066', tagName: '岳阳城陵矶综合保税区(43066)' },
  { label: '岳阳', value: '43069', tagName: '岳阳(43069)' },
  { label: '常德经济技术开发区', value: '43072', tagName: '常德经济技术开发区(43072)' },
  { label: '常德高新技术产业开发区', value: '43073', tagName: '常德高新技术产业开发区(43073)' },
  { label: '常德', value: '43079', tagName: '常德(43079)' },
  { label: '张家界', value: '43089', tagName: '张家界(43089)' },
  { label: '益阳高新技术产业开发区', value: '43093', tagName: '益阳高新技术产业开发区(43093)' },
  { label: '益阳', value: '43099', tagName: '益阳(43099)' },
  { label: '娄底经济技术开发区', value: '43102', tagName: '娄底经济技术开发区(43102)' },
  { label: '娄底', value: '43109', tagName: '娄底(43109)' },
  { label: '郴州高新技术产业开发区', value: '43113', tagName: '郴州高新技术产业开发区(43113)' },
  { label: '湖南郴州出口加工区', value: '43115', tagName: '湖南郴州出口加工区(43115)' },
  { label: '郴州综合保税区', value: '43116', tagName: '郴州综合保税区(43116)' },
  { label: '郴州', value: '43119', tagName: '郴州(43119)' },
  { label: '永州', value: '43129', tagName: '永州(43129)' },
  { label: '怀化高新技术产业开发区', value: '43133', tagName: '怀化高新技术产业开发区(43133)' },
  { label: '怀化', value: '43139', tagName: '怀化(43139)' },
  { label: '湘西', value: '43149', tagName: '湘西(43149)' },
  { label: '醴陵', value: '43159', tagName: '醴陵(43159)' },
  { label: '湘乡', value: '43169', tagName: '湘乡(43169)' },
  { label: '耒阳', value: '43179', tagName: '耒阳(43179)' },
  { label: '汩罗', value: '43189', tagName: '汩罗(43189)' },
  { label: '津市', value: '43199', tagName: '津市(43199)' },
  { label: '浏阳经济技术开发区', value: '43202', tagName: '浏阳经济技术开发区(43202)' },
  { label: '浏阳其他', value: '43209', tagName: '浏阳其他(43209)' },
  { label: '湖南其他', value: '43909', tagName: '湖南其他(43909)' },
  { label: '广州经济技术开发区', value: '44012', tagName: '广州经济技术开发区(44012)' },
  { label: '广州天河高新技术产业开发区', value: '44013', tagName: '广州天河高新技术产业开发区(44013)' },
  { label: '广州保税区', value: '44014', tagName: '广州保税区(44014)' },
  { label: '广东广州出口加工区', value: '44015', tagName: '广东广州出口加工区(44015)' },
  { label: '广州白云机场、黄埔综合保税区', value: '44016', tagName: '广州白云机场、黄埔综合保税区(44016)' },
  { label: '广州保税物流园区', value: '44017', tagName: '广州保税物流园区(44017)' },
  { label: '广州其他', value: '44019', tagName: '广州其他(44019)' },
  { label: '韶关', value: '44029', tagName: '韶关(44029)' },
  { label: '深圳特区', value: '44031', tagName: '深圳特区(44031)' },
  { label: '深圳科技工业园', value: '44033', tagName: '深圳科技工业园(44033)' },
  { label: '福田盐田沙头角保税区', value: '44034', tagName: '福田盐田沙头角保税区(44034)' },
  { label: '广东深圳出口加工区', value: '44035', tagName: '广东深圳出口加工区(44035)' },
  { label: '深圳前海、坪山综合保税区', value: '44036', tagName: '深圳前海、坪山综合保税区(44036)' },
  { label: '深圳盐田保税物流园', value: '44037', tagName: '深圳盐田保税物流园(44037)' },
  { label: '深圳其他', value: '44039', tagName: '深圳其他(44039)' },
  { label: '深圳机场保税物流中心', value: '4403W', tagName: '深圳机场保税物流中心(4403W)' },
  { label: '珠海特区', value: '44041', tagName: '珠海特区(44041)' },
  { label: '珠海高新技术产业开发区', value: '44043', tagName: '珠海高新技术产业开发区(44043)' },
  { label: '珠海保税区', value: '44044', tagName: '珠海保税区(44044)' },
  { label: '珠澳跨境工业区珠海园区', value: '44045', tagName: '珠澳跨境工业区珠海园区(44045)' },
  { label: '珠海高栏港综合保税区', value: '44046', tagName: '珠海高栏港综合保税区(44046)' },
  { label: '珠海横琴新区', value: '44048', tagName: '珠海横琴新区(44048)' },
  { label: '珠海其他', value: '44049', tagName: '珠海其他(44049)' },
  { label: '汕头特区', value: '44051', tagName: '汕头特区(44051)' },
  { label: '汕头保税区', value: '44054', tagName: '汕头保税区(44054)' },
  { label: '汕头综合保税区', value: '44056', tagName: '汕头综合保税区(44056)' },
  { label: '汕头其他', value: '44059', tagName: '汕头其他(44059)' },
  { label: '汕头保税物流中心（B型）', value: '4405W', tagName: '汕头保税物流中心（B型）(4405W)' },
  { label: '佛山高新技术产业开发区', value: '44063', tagName: '佛山高新技术产业开发区(44063)' },
  { label: '佛山其他', value: '44069', tagName: '佛山其他(44069)' },
  { label: '江门', value: '44079', tagName: '江门(44079)' },
  { label: '江门大广海湾保税物流中心（B型）', value: '4407W', tagName: '江门大广海湾保税物流中心（B型）(4407W)' },
  { label: '湛江经济技术开发区', value: '44082', tagName: '湛江经济技术开发区(44082)' },
  { label: '湛江高新技术产业开发区', value: '44083', tagName: '湛江高新技术产业开发区(44083)' },
  { label: '湛江其他', value: '44089', tagName: '湛江其他(44089)' },
  { label: '湛江保税物流中心（B型）', value: '4408W', tagName: '湛江保税物流中心（B型）(4408W)' },
  { label: '茂名高新技术产业开发区', value: '44093', tagName: '茂名高新技术产业开发区(44093)' },
  { label: '茂名', value: '44099', tagName: '茂名(44099)' },
  { label: '肇庆', value: '44129', tagName: '肇庆(44129)' },
  { label: '惠州高新技术产业开发区', value: '44133', tagName: '惠州高新技术产业开发区(44133)' },
  { label: '惠州其他', value: '44139', tagName: '惠州其他(44139)' },
  { label: '梅州综合保税区', value: '44146', tagName: '梅州综合保税区(44146)' },
  { label: '梅州', value: '44149', tagName: '梅州(44149)' },
  { label: '汕尾', value: '44159', tagName: '汕尾(44159)' },
  { label: '河源', value: '44169', tagName: '河源(44169)' },
  { label: '阳江', value: '44179', tagName: '阳江(44179)' },
  { label: '清远', value: '44189', tagName: '清远(44189)' },
  { label: '东莞松山湖高新技术产业开发区', value: '44193', tagName: '东莞松山湖高新技术产业开发区(44193)' },
  { label: '东莞虎门港综合保税区', value: '44196', tagName: '东莞虎门港综合保税区(44196)' },
  { label: '东莞', value: '44199', tagName: '东莞(44199)' },
  { label: '东莞保税物流中心', value: '4419W', tagName: '东莞保税物流中心(4419W)' },
  { label: '中山火炬高技术产业开发区', value: '44203', tagName: '中山火炬高技术产业开发区(44203)' },
  { label: '中山其他', value: '44209', tagName: '中山其他(44209)' },
  { label: '中山保税物流中心', value: '4420W', tagName: '中山保税物流中心(4420W)' },
  { label: '潮州', value: '44219', tagName: '潮州(44219)' },
  { label: '顺德', value: '44229', tagName: '顺德(44229)' },
  { label: '佛山国通保税物流中心（B型）', value: '4422W', tagName: '佛山国通保税物流中心（B型）(4422W)' },
  { label: '广东南沙出口加工区', value: '44235', tagName: '广东南沙出口加工区(44235)' },
  { label: '广州南沙保税港区', value: '44236', tagName: '广州南沙保税港区(44236)' },
  { label: '番禺', value: '44239', tagName: '番禺(44239)' },
  { label: '揭阳', value: '44249', tagName: '揭阳(44249)' },
  { label: '南海', value: '44289', tagName: '南海(44289)' },
  { label: '云浮市', value: '44299', tagName: '云浮市(44299)' },
  { label: '广州南沙综合保税区', value: '44306', tagName: '广州南沙综合保税区(44306)' },
  { label: '南沙其他', value: '44309', tagName: '南沙其他(44309)' },
  { label: '深圳盐田综合保税区（一期）', value: '44536', tagName: '深圳盐田综合保税区（一期）(44536)' },
  { label: '东莞清溪保税物流中心（B型）', value: '4469W', tagName: '东莞清溪保税物流中心（B型）(4469W)' },
  { label: '广东其他', value: '44909', tagName: '广东其他(44909)' },
  { label: '南宁高新技术产业开发区', value: '45013', tagName: '南宁高新技术产业开发区(45013)' },
  { label: '南宁综合保税区', value: '45016', tagName: '南宁综合保税区(45016)' },
  { label: '南宁其他', value: '45019', tagName: '南宁其他(45019)' },
  { label: '南宁保税物流中心', value: '4501W', tagName: '南宁保税物流中心(4501W)' },
  { label: '柳州', value: '45029', tagName: '柳州(45029)' },
  { label: '柳州保税物流中心（B型）', value: '4502W', tagName: '柳州保税物流中心（B型）(4502W)' },
  { label: '桂林高新技术产业开发区', value: '45033', tagName: '桂林高新技术产业开发区(45033)' },
  { label: '桂林其他', value: '45039', tagName: '桂林其他(45039)' },
  { label: '梧州', value: '45049', tagName: '梧州(45049)' },
  { label: '广西北海出口加工区', value: '45055', tagName: '广西北海出口加工区(45055)' },
  { label: '北海综合保税区', value: '45056', tagName: '北海综合保税区(45056)' },
  { label: '北海', value: '45059', tagName: '北海(45059)' },
  { label: '玉林', value: '45069', tagName: '玉林(45069)' },
  { label: '百色', value: '45079', tagName: '百色(45079)' },
  { label: '河池', value: '45089', tagName: '河池(45089)' },
  { label: '钦州综合保税区', value: '45096', tagName: '钦州综合保税区(45096)' },
  { label: '钦州', value: '45099', tagName: '钦州(45099)' },
  { label: '广西凭祥综合保税区', value: '45106', tagName: '广西凭祥综合保税区(45106)' },
  { label: '凭祥', value: '45109', tagName: '凭祥(45109)' },
  { label: '东兴', value: '45119', tagName: '东兴(45119)' },
  { label: '防城港市', value: '45129', tagName: '防城港市(45129)' },
  { label: '防城港保税物流中心（B型）', value: '4512W', tagName: '防城港保税物流中心（B型）(4512W)' },
  { label: '贵港市', value: '45139', tagName: '贵港市(45139)' },
  { label: '崇左', value: '45149', tagName: '崇左(45149)' },
  { label: '来宾', value: '45159', tagName: '来宾(45159)' },
  { label: '贺州', value: '45169', tagName: '贺州(45169)' },
  { label: '广西其他', value: '45909', tagName: '广西其他(45909)' },
  { label: '海口', value: '46011', tagName: '海口(46011)' },
  { label: '海南国际科技园区', value: '46013', tagName: '海南国际科技园区(46013)' },
  { label: '海南海口保税区', value: '46014', tagName: '海南海口保税区(46014)' },
  { label: '海口综合保税区', value: '46016', tagName: '海口综合保税区(46016)' },
  { label: '三亚', value: '46021', tagName: '三亚(46021)' },
  { label: '三沙', value: '46031', tagName: '三沙(46031)' },
  { label: '五指山', value: '46041', tagName: '五指山(46041)' },
  { label: '琼海', value: '46051', tagName: '琼海(46051)' },
  { label: '儋州', value: '46061', tagName: '儋州(46061)' },
  { label: '洋浦经济开发区', value: '46062', tagName: '洋浦经济开发区(46062)' },
  { label: '洋浦保税港区', value: '46066', tagName: '洋浦保税港区(46066)' },
  { label: '文昌', value: '46071', tagName: '文昌(46071)' },
  { label: '万宁', value: '46081', tagName: '万宁(46081)' },
  { label: '东方', value: '46091', tagName: '东方(46091)' },
  { label: '定安', value: '46101', tagName: '定安(46101)' },
  { label: '屯昌', value: '46111', tagName: '屯昌(46111)' },
  { label: '澄迈', value: '46121', tagName: '澄迈(46121)' },
  { label: '临高', value: '46131', tagName: '临高(46131)' },
  { label: '白沙', value: '46141', tagName: '白沙(46141)' },
  { label: '昌江', value: '46151', tagName: '昌江(46151)' },
  { label: '乐东', value: '46161', tagName: '乐东(46161)' },
  { label: '陵水', value: '46171', tagName: '陵水(46171)' },
  { label: '保亭', value: '46181', tagName: '保亭(46181)' },
  { label: '琼中', value: '46191', tagName: '琼中(46191)' },
  { label: '海南其他', value: '46901', tagName: '海南其他(46901)' },
  { label: '海南洋浦经济技术开发区', value: '46902', tagName: '海南洋浦经济技术开发区(46902)' },
  { label: '海南洋浦保税港区', value: '46906', tagName: '海南洋浦保税港区(46906)' },
  { label: '万州经济技术开发区', value: '50012', tagName: '万州经济技术开发区(50012)' },
  { label: '重庆万州综合保税区', value: '50016', tagName: '重庆万州综合保税区(50016)' },
  { label: '万州区', value: '50019', tagName: '万州区(50019)' },
  { label: '重庆涪陵综合保税区', value: '50026', tagName: '重庆涪陵综合保税区(50026)' },
  { label: '涪陵区', value: '50029', tagName: '涪陵区(50029)' },
  { label: '渝中区', value: '50039', tagName: '渝中区(50039)' },
  { label: '大渡口区', value: '50049', tagName: '大渡口区(50049)' },
  { label: '重庆两江新区江北区', value: '50052', tagName: '重庆两江新区江北区(50052)' },
  { label: '重庆两路寸滩综合保税区（水港）', value: '50056', tagName: '重庆两路寸滩综合保税区（水港）(50056)' },
  { label: '江北区', value: '50059', tagName: '江北区(50059)' },
  { label: '重庆果园保税物流中心（B型）', value: '5005W', tagName: '重庆果园保税物流中心（B型）(5005W)' },
  { label: '重庆西永综合保税区', value: '50066', tagName: '重庆西永综合保税区(50066)' },
  { label: '沙坪坝区', value: '50069', tagName: '沙坪坝区(50069)' },
  { label: '重庆铁路保税物流中心', value: '5006W', tagName: '重庆铁路保税物流中心(5006W)' },
  { label: '重庆高新技术产业开发区', value: '50073', tagName: '重庆高新技术产业开发区(50073)' },
  { label: '九龙坡区', value: '50079', tagName: '九龙坡区(50079)' },
  { label: '重庆经济技术开发区', value: '50082', tagName: '重庆经济技术开发区(50082)' },
  { label: '南岸区', value: '50089', tagName: '南岸区(50089)' },
  { label: '重庆两江新区北碚区', value: '50092', tagName: '重庆两江新区北碚区(50092)' },
  { label: '北碚区', value: '50099', tagName: '北碚区(50099)' },
  { label: '万盛区', value: '50109', tagName: '万盛区(50109)' },
  { label: '双桥区', value: '50119', tagName: '双桥区(50119)' },
  { label: '重庆两江新区渝北区', value: '50122', tagName: '重庆两江新区渝北区(50122)' },
  { label: '重庆出口加工区', value: '50125', tagName: '重庆出口加工区(50125)' },
  { label: '重庆两路寸滩综合保税区（空港）', value: '50126', tagName: '重庆两路寸滩综合保税区（空港）(50126)' },
  { label: '渝北区', value: '50129', tagName: '渝北区(50129)' },
  { label: '巴南区', value: '50139', tagName: '巴南区(50139)' },
  { label: '重庆南彭公路保税物流中心（B型）', value: '5013W', tagName: '重庆南彭公路保税物流中心（B型）(5013W)' },
  { label: '长寿经济技术开发区', value: '50212', tagName: '长寿经济技术开发区(50212)' },
  { label: '长寿县', value: '50219', tagName: '长寿县(50219)' },
  { label: '綦江区', value: '50229', tagName: '綦江区(50229)' },
  { label: '潼南县', value: '50239', tagName: '潼南县(50239)' },
  { label: '重庆市铜梁区', value: '50249', tagName: '重庆市铜梁区(50249)' },
  { label: '大足区', value: '50259', tagName: '大足区(50259)' },
  { label: '荣昌县', value: '50269', tagName: '荣昌县(50269)' },
  { label: '重庆市璧山区', value: '50279', tagName: '重庆市璧山区(50279)' },
  { label: '梁平县', value: '50289', tagName: '梁平县(50289)' },
  { label: '城口县', value: '50299', tagName: '城口县(50299)' },
  { label: '丰都县', value: '50309', tagName: '丰都县(50309)' },
  { label: '垫江县', value: '50319', tagName: '垫江县(50319)' },
  { label: '武隆县', value: '50329', tagName: '武隆县(50329)' },
  { label: '忠县', value: '50339', tagName: '忠县(50339)' },
  { label: '开县', value: '50349', tagName: '开县(50349)' },
  { label: '云阳县', value: '50359', tagName: '云阳县(50359)' },
  { label: '奉节县', value: '50369', tagName: '奉节县(50369)' },
  { label: '巫山县', value: '50379', tagName: '巫山县(50379)' },
  { label: '巫溪县', value: '50389', tagName: '巫溪县(50389)' },
  { label: '黔江', value: '50399', tagName: '黔江(50399)' },
  { label: '石柱土家族自治县', value: '50409', tagName: '石柱土家族自治县(50409)' },
  { label: '秀山土家族苗族自治县', value: '50419', tagName: '秀山土家族苗族自治县(50419)' },
  { label: '酉阳土家族苗族自治县', value: '50429', tagName: '酉阳土家族苗族自治县(50429)' },
  { label: '彭水苗族土家族自治县', value: '50439', tagName: '彭水苗族土家族自治县(50439)' },
  { label: '重庆江津综合保税区', value: '50816', tagName: '重庆江津综合保税区(50816)' },
  { label: '江津区', value: '50819', tagName: '江津区(50819)' },
  { label: '合川区', value: '50829', tagName: '合川区(50829)' },
  { label: '永川区', value: '50839', tagName: '永川区(50839)' },
  { label: '南川区', value: '50849', tagName: '南川区(50849)' },
  { label: '成都经济技术开发区', value: '51012', tagName: '成都经济技术开发区(51012)' },
  { label: '成都高新技术产业开发区', value: '51013', tagName: '成都高新技术产业开发区(51013)' },
  { label: '四川成都出口加工区', value: '51015', tagName: '四川成都出口加工区(51015)' },
  { label: '成都高新、国际铁路港或高新西园综合保税区', value: '51016', tagName: '成都高新、国际铁路港或高新西园综合保税区(51016)' },
  { label: '成都其他', value: '51019', tagName: '成都其他(51019)' },
  { label: '成都空港、天府新区成都片区保税物流中心', value: '5101W', tagName: '成都空港、天府新区成都片区保税物流中心(5101W)' },
  { label: '自贡', value: '51039', tagName: '自贡(51039)' },
  { label: '攀枝花', value: '51049', tagName: '攀枝花(51049)' },
  { label: '泸州高新技术产业开发区', value: '51053', tagName: '泸州高新技术产业开发区(51053)' },
  { label: '泸州综合保税区', value: '51056', tagName: '泸州综合保税区(51056)' },
  { label: '泸州', value: '51059', tagName: '泸州(51059)' },
  { label: '泸州港保税物流中心（Ｂ型）', value: '5105W', tagName: '泸州港保税物流中心（Ｂ型）(5105W)' },
  { label: '德阳', value: '51069', tagName: '德阳(51069)' },
  { label: '绵阳经济技术开发区', value: '51072', tagName: '绵阳经济技术开发区(51072)' },
  { label: '绵阳高新技术产业开发区', value: '51073', tagName: '绵阳高新技术产业开发区(51073)' },
  { label: '四川绵阳出口加工区', value: '51075', tagName: '四川绵阳出口加工区(51075)' },
  { label: '绵阳综合保税区', value: '51076', tagName: '绵阳综合保税区(51076)' },
  { label: '绵阳其他', value: '51079', tagName: '绵阳其他(51079)' },
  { label: '广元经济技术开发区', value: '51082', tagName: '广元经济技术开发区(51082)' },
  { label: '广元', value: '51089', tagName: '广元(51089)' },
  { label: '遂宁', value: '51099', tagName: '遂宁(51099)' },
  { label: '内江', value: '51109', tagName: '内江(51109)' },
  { label: '乐山高新技术产业开发区', value: '51113', tagName: '乐山高新技术产业开发区(51113)' },
  { label: '乐山', value: '51119', tagName: '乐山(51119)' },
  { label: '宜宾临港经济开发区', value: '51142', tagName: '宜宾临港经济开发区(51142)' },
  { label: '宜宾综合保税区', value: '51146', tagName: '宜宾综合保税区(51146)' },
  { label: '宜宾', value: '51149', tagName: '宜宾(51149)' },
  { label: '宜宾港保税物流中心（B型）', value: '5114W', tagName: '宜宾港保税物流中心（B型）(5114W)' },
  { label: '南充', value: '51159', tagName: '南充(51159)' },
  { label: '南充保税物流中心（B型）', value: '5115W', tagName: '南充保税物流中心（B型）(5115W)' },
  { label: '达县', value: '51169', tagName: '达县(51169)' },
  { label: '雅安', value: '51179', tagName: '雅安(51179)' },
  { label: '阿坝', value: '51189', tagName: '阿坝(51189)' },
  { label: '甘孜', value: '51199', tagName: '甘孜(51199)' },
  { label: '凉山', value: '51209', tagName: '凉山(51209)' },
  { label: '广汉', value: '51229', tagName: '广汉(51229)' },
  { label: '江油', value: '51239', tagName: '江油(51239)' },
  { label: '都江堰', value: '51249', tagName: '都江堰(51249)' },
  { label: '峨眉山', value: '51259', tagName: '峨眉山(51259)' },
  { label: '资阳', value: '51269', tagName: '资阳(51269)' },
  { label: '眉山', value: '51279', tagName: '眉山(51279)' },
  { label: '广安', value: '51289', tagName: '广安(51289)' },
  { label: '巴中', value: '51299', tagName: '巴中(51299)' },
  { label: '成都铁路保税物流中心（B型）', value: '5151W', tagName: '成都铁路保税物流中心（B型）(5151W)' },
  { label: '四川其他', value: '51909', tagName: '四川其他(51909)' },
  { label: '贵阳高新技术产业开发区', value: '52013', tagName: '贵阳高新技术产业开发区(52013)' },
  { label: '贵阳综合保税区', value: '52016', tagName: '贵阳综合保税区(52016)' },
  { label: '贵阳其他', value: '52019', tagName: '贵阳其他(52019)' },
  { label: '六盘水', value: '52029', tagName: '六盘水(52029)' },
  { label: '遵义综合保税区', value: '52036', tagName: '遵义综合保税区(52036)' },
  { label: '遵义', value: '52039', tagName: '遵义(52039)' },
  { label: '铜仁', value: '52049', tagName: '铜仁(52049)' },
  { label: '黔西南', value: '52059', tagName: '黔西南(52059)' },
  { label: '毕节', value: '52069', tagName: '毕节(52069)' },
  { label: '安顺', value: '52079', tagName: '安顺(52079)' },
  { label: '黔东南', value: '52089', tagName: '黔东南(52089)' },
  { label: '黔南', value: '52099', tagName: '黔南(52099)' },
  { label: '贵阳贵安新区', value: '52502', tagName: '贵阳贵安新区(52502)' },
  { label: '贵安综合保税区', value: '52506', tagName: '贵安综合保税区(52506)' },
  { label: '贵州其他', value: '52909', tagName: '贵州其他(52909)' },
  { label: '昆明经济技术开发区', value: '53012', tagName: '昆明经济技术开发区(53012)' },
  { label: '昆明高新技术产业开发区', value: '53013', tagName: '昆明高新技术产业开发区(53013)' },
  { label: '云南昆明出口加工区', value: '53015', tagName: '云南昆明出口加工区(53015)' },
  { label: '昆明综合保税区', value: '53016', tagName: '昆明综合保税区(53016)' },
  { label: '昆明其他', value: '53019', tagName: '昆明其他(53019)' },
  { label: '昆明高新保税物流中心（B型）', value: '5301W', tagName: '昆明高新保税物流中心（B型）(5301W)' },
  { label: '东川', value: '53029', tagName: '东川(53029)' },
  { label: '昭通', value: '53039', tagName: '昭通(53039)' },
  { label: '曲靖经济技术开发区', value: '53042', tagName: '曲靖经济技术开发区(53042)' },
  { label: '曲靖', value: '53049', tagName: '曲靖(53049)' },
  { label: '楚雄', value: '53059', tagName: '楚雄(53059)' },
  { label: '玉溪', value: '53069', tagName: '玉溪(53069)' },
  { label: '云南省蒙自经济技术开发区', value: '53072', tagName: '云南省蒙自经济技术开发区(53072)' },
  { label: '云南红河综合保税区', value: '53076', tagName: '云南红河综合保税区(53076)' },
  { label: '红河', value: '53079', tagName: '红河(53079)' },
  { label: '文山', value: '53089', tagName: '文山(53089)' },
  { label: '普洱', value: '53099', tagName: '普洱(53099)' },
  { label: '西双版纳', value: '53109', tagName: '西双版纳(53109)' },
  { label: '大理', value: '53119', tagName: '大理(53119)' },
  { label: '保山', value: '53129', tagName: '保山(53129)' },
  { label: '德宏', value: '53139', tagName: '德宏(53139)' },
  { label: '丽江', value: '53149', tagName: '丽江(53149)' },
  { label: '怒江', value: '53159', tagName: '怒江(53159)' },
  { label: '迪庆', value: '53169', tagName: '迪庆(53169)' },
  { label: '临沧', value: '53179', tagName: '临沧(53179)' },
  { label: '畹町', value: '53189', tagName: '畹町(53189)' },
  { label: '瑞丽', value: '53199', tagName: '瑞丽(53199)' },
  { label: '河口', value: '53209', tagName: '河口(53209)' },
  { label: '腾俊国际陆港保税物流中心（B型）', value: '5351W', tagName: '腾俊国际陆港保税物流中心（B型）(5351W)' },
  { label: '云南其他', value: '53909', tagName: '云南其他(53909)' },
  { label: '拉萨经济技术开发区', value: '54012', tagName: '拉萨经济技术开发区(54012)' },
  { label: '拉萨综合保税区', value: '54016', tagName: '拉萨综合保税区(54016)' },
  { label: '拉萨', value: '54019', tagName: '拉萨(54019)' },
  { label: '昌都', value: '54029', tagName: '昌都(54029)' },
  { label: '山南', value: '54039', tagName: '山南(54039)' },
  { label: '日喀则', value: '54049', tagName: '日喀则(54049)' },
  { label: '那曲', value: '54059', tagName: '那曲(54059)' },
  { label: '阿里', value: '54069', tagName: '阿里(54069)' },
  { label: '林芝', value: '54079', tagName: '林芝(54079)' },
  { label: '西藏其他', value: '54909', tagName: '西藏其他(54909)' },
  { label: '陕西航天经济技术开发区', value: '61012', tagName: '陕西航天经济技术开发区(61012)' },
  { label: '西安新技术产业开发区', value: '61013', tagName: '西安新技术产业开发区(61013)' },
  { label: '陕西西安出口加工区', value: '61015', tagName: '陕西西安出口加工区(61015)' },
  { label: '西安、西安高新和西安航空基地综合保税区', value: '61016', tagName: '西安、西安高新和西安航空基地综合保税区(61016)' },
  { label: '西安其他', value: '61019', tagName: '西安其他(61019)' },
  { label: '铜川', value: '61029', tagName: '铜川(61029)' },
  { label: '宝鸡高新技术产业开发区', value: '61033', tagName: '宝鸡高新技术产业开发区(61033)' },
  { label: '宝鸡综合保税区', value: '61036', tagName: '宝鸡综合保税区(61036)' },
  { label: '宝鸡其他', value: '61039', tagName: '宝鸡其他(61039)' },
  { label: '杨凌农业高新技术产业示范区', value: '61043', tagName: '杨凌农业高新技术产业示范区(61043)' },
  { label: '陕西西咸空港、杨凌综合保税区', value: '61046', tagName: '陕西西咸空港、杨凌综合保税区(61046)' },
  { label: '咸阳', value: '61049', tagName: '咸阳(61049)' },
  { label: '陕西西咸保税物流中心', value: '6104W', tagName: '陕西西咸保税物流中心(6104W)' },
  { label: '渭南', value: '61059', tagName: '渭南(61059)' },
  { label: '汉中经济技术开发区', value: '61062', tagName: '汉中经济技术开发区(61062)' },
  { label: '汉中', value: '61069', tagName: '汉中(61069)' },
  { label: '安康', value: '61079', tagName: '安康(61079)' },
  { label: '商洛', value: '61089', tagName: '商洛(61089)' },
  { label: '延安', value: '61099', tagName: '延安(61099)' },
  { label: '榆林', value: '61109', tagName: '榆林(61109)' },
  { label: '陕西其他', value: '61909', tagName: '陕西其他(61909)' },
  { label: '兰州宁卧庄新技术产业开发区', value: '62013', tagName: '兰州宁卧庄新技术产业开发区(62013)' },
  { label: '兰州新区综合保税区', value: '62016', tagName: '兰州新区综合保税区(62016)' },
  { label: '兰州其他', value: '62019', tagName: '兰州其他(62019)' },
  { label: '嘉峪关', value: '62029', tagName: '嘉峪关(62029)' },
  { label: '金昌', value: '62039', tagName: '金昌(62039)' },
  { label: '白银', value: '62049', tagName: '白银(62049)' },
  { label: '天水', value: '62059', tagName: '天水(62059)' },
  { label: '酒泉', value: '62069', tagName: '酒泉(62069)' },
  { label: '张掖', value: '62079', tagName: '张掖(62079)' },
  { label: '武威', value: '62089', tagName: '武威(62089)' },
  { label: '武威保税物流中心', value: '6208W', tagName: '武威保税物流中心(6208W)' },
  { label: '定西', value: '62099', tagName: '定西(62099)' },
  { label: '陇南', value: '62109', tagName: '陇南(62109)' },
  { label: '平凉', value: '62119', tagName: '平凉(62119)' },
  { label: '庆阳', value: '62129', tagName: '庆阳(62129)' },
  { label: '临夏', value: '62139', tagName: '临夏(62139)' },
  { label: '甘南', value: '62149', tagName: '甘南(62149)' },
  { label: '甘肃其他', value: '62909', tagName: '甘肃其他(62909)' },
  { label: '西宁经济技术开发区', value: '63012', tagName: '西宁经济技术开发区(63012)' },
  { label: '青海高新技术产业开发区', value: '63013', tagName: '青海高新技术产业开发区(63013)' },
  { label: '西宁综合保税区', value: '63016', tagName: '西宁综合保税区(63016)' },
  { label: '西宁', value: '63019', tagName: '西宁(63019)' },
  { label: '海东', value: '63029', tagName: '海东(63029)' },
  { label: '青海曹家堡保税物流中心（B型）', value: '6302W', tagName: '青海曹家堡保税物流中心（B型）(6302W)' },
  { label: '海北', value: '63039', tagName: '海北(63039)' },
  { label: '黄南', value: '63049', tagName: '黄南(63049)' },
  { label: '海南', value: '63059', tagName: '海南(63059)' },
  { label: '果洛', value: '63069', tagName: '果洛(63069)' },
  { label: '玉树', value: '63079', tagName: '玉树(63079)' },
  { label: '格尔木昆仑经济技术开发区', value: '63082', tagName: '格尔木昆仑经济技术开发区(63082)' },
  { label: '海西', value: '63089', tagName: '海西(63089)' },
  { label: '青海其他', value: '63909', tagName: '青海其他(63909)' },
  { label: '银川经济技术开发区', value: '64012', tagName: '银川经济技术开发区(64012)' },
  { label: '银川综合保税区', value: '64016', tagName: '银川综合保税区(64016)' },
  { label: '银川', value: '64019', tagName: '银川(64019)' },
  { label: '石嘴山', value: '64029', tagName: '石嘴山(64029)' },
  { label: '石嘴山保税物流中心（B型）', value: '6402W', tagName: '石嘴山保税物流中心（B型）(6402W)' },
  { label: '吴中', value: '64039', tagName: '吴中(64039)' },
  { label: '固原', value: '64049', tagName: '固原(64049)' },
  { label: '中卫', value: '64059', tagName: '中卫(64059)' },
  { label: '宁夏其他', value: '64909', tagName: '宁夏其他(64909)' },
  { label: '乌鲁木齐经济技术开发区', value: '65012', tagName: '乌鲁木齐经济技术开发区(65012)' },
  { label: '乌鲁木齐高新技术产业开发区', value: '65013', tagName: '乌鲁木齐高新技术产业开发区(65013)' },
  { label: '新疆乌鲁木齐出口加工区', value: '65015', tagName: '新疆乌鲁木齐出口加工区(65015)' },
  { label: '乌鲁木齐综合保税区', value: '65016', tagName: '乌鲁木齐综合保税区(65016)' },
  { label: '乌鲁木齐其他', value: '65019', tagName: '乌鲁木齐其他(65019)' },
  { label: '克拉玛依', value: '65029', tagName: '克拉玛依(65029)' },
  { label: '阿拉山口综合保税区', value: '65036', tagName: '阿拉山口综合保税区(65036)' },
  { label: '博乐', value: '65039', tagName: '博乐(65039)' },
  { label: '巴音', value: '65049', tagName: '巴音(65049)' },
  { label: '阿克苏', value: '65059', tagName: '阿克苏(65059)' },
  { label: '克孜', value: '65069', tagName: '克孜(65069)' },
  { label: '喀什综合保税区', value: '65076', tagName: '喀什综合保税区(65076)' },
  { label: '喀什', value: '65079', tagName: '喀什(65079)' },
  { label: '和田', value: '65089', tagName: '和田(65089)' },
  { label: '中哈霍尔果斯国际边境合作中心中方配套区（一期）', value: '65095', tagName: '中哈霍尔果斯国际边境合作中心中方配套区（一期）(65095)' },
  { label: '伊宁', value: '65099', tagName: '伊宁(65099)' },
  { label: '中哈霍尔果斯国际边境合作中心中方配套区（一期）', value: '6509A', tagName: '中哈霍尔果斯国际边境合作中心中方配套区（一期）(6509A)' },
  { label: '奎屯保税物流中心', value: '6509W', tagName: '奎屯保税物流中心(6509W)' },
  { label: '塔城', value: '65109', tagName: '塔城(65109)' },
  { label: '阿勒泰', value: '65119', tagName: '阿勒泰(65119)' },
  { label: '石河子经济技术开发区', value: '65122', tagName: '石河子经济技术开发区(65122)' },
  { label: '石河子', value: '65129', tagName: '石河子(65129)' },
  { label: '吐鲁番', value: '65219', tagName: '吐鲁番(65219)' },
  { label: '哈密', value: '65229', tagName: '哈密(65229)' },
  { label: '昌吉回族自治州', value: '65239', tagName: '昌吉回族自治州(65239)' },
  { label: '新疆其他', value: '65909', tagName: '新疆其他(65909)' },
]);
