import unitAry from '../../../../configs/unit';// 单位代码

export default function (mydatas) {
  const { tableDatas, rowClkBus, hsDetailTableRef } = mydatas;
  /** 高亮行（添加至共享事件）
   *
   * @param {Object} row
   */
  rowClkBus.setCurrent = (row) => { // 设置高亮色
    hsDetailTableRef.value.setCurrentRow(row);
  };

  /** 行选中时触发的事件
   * @param {Object} currentRow 当前行数据
   * @param {Object} oldCurrentRow 变动之前数据
   * 根据选中行将tableDatas中的索引存放入rowClkBus共享数据中
   */
  function handleCurrentChange(
    currentRow,
    // oldCurrentRow,
  ) {
    rowClkBus.oldRangeClkId = rowClkBus.rangeClkId;
    rowClkBus.currentRow = { ...currentRow };// 记录当前行数据
    // 获取当前的tableDatas中下标key值，放入共享点击数据
    tableDatas.forEach((item, idx) => {
      if (JSON.stringify(item) === JSON.stringify(currentRow)) { // 查找到
        rowClkBus.rangeClkId = idx;
      }
    });
  }

  /** 点击行事件
   * @param {Object} row  行数据
   * @param {*} column 列数据
   * @param {*} event 事件对象
   *
   */
  function rowClkFunc(
    // row,
    // column, event
  ) {
    const {
      clkRowFunc,
    } = rowClkBus;
    //  注释if，不能开启点击同一行，开启后点击同一行不能操作部分功能
    clkRowFunc.clkRowFormStateBus();
  }

  /** 自定义索引ID
     * @param {Numer} idx 表单中数组索引值
     * @returns 倒序索引值
     */
  function mySetIndex(idx) {
    return tableDatas.length - idx;
  }

  /** 根据代码自动生成【产品数量】中文单位
     * @param {any} codeNum 单位代码
     */
  const autoMakeProductCNUnit = (codeNum) => {
    let findRes = '';
    unitAry.forEach((element) => {
      if (element.value === codeNum) {
        findRes = element.label;
      }
    });
    return findRes;
  };

  /** 合并行或列的计算方法
    * 思路：
    * 系统为根据返回数组【行，列】来进行合并(0表示删除列或行，1表示保持原状，>0正数表示要操作合并的列或行)
    * 例如：
    * 返回[0,0]:表示删除当前列
    * 返回[2,1]:表示行合并2行，列保持不变
    * 返回[1,1]:表示保持原状
    * 合并判断依据：
    *   合并例： 混装状态 && ctns 有值
    *   隐藏行： 混装状态 && ctns 无值
    */
  function objectSpanMethod({
    row, // 行数据
    column, // 列值
    rowIndex, // 行索引
    // columnIndex, // 列索引
  }) {
    const key = column.property; // 列索引值 或 数据键名
    // 【箱数】【混装】只可以为多列合并，【毛重】可以单列或多列合并，所以要分开
    if (['ctns', 'packageState'].includes(key)) { // 只操作数组中列
      const mergerCol = Number(row.moreProductCtns);// 向下合并行数
      if (mergerCol && Number(row.ctns) !== 0) { // 不为0时，向下合并行数
        return [mergerCol, 1];
      } if (row.packageState === 1 && Number(row.ctns) === 0) { // 在混装前题下，箱数为0的时候，说明是被合并（需要隐藏当格数据)
        return [0, 0];
      }
    } else if (!!row.packageState && (key === 'grossWt')) { // 【毛重】列
      // 【混装】状态下&&当前列为【毛重】运行如下代码
      if (Number(row.grossWt)) { // 【毛重】有值
        let n = 0;// 记录从当前【混装】&&【毛重】到下一个止中间个数
        let b = true;// 截止到有值时不再向n累加
        /**
         * 从行标即tabledatas索引值开始计算遇到为0的个数，即为要合并的个数
         *【毛重】为0，并且未遇到下一个有值
         *
         */
        for (let i = rowIndex; i < tableDatas.length; i += 1) {
          if (i === rowIndex) { //  初始化值
            n = 1;
          } else if ((!Number(tableDatas[i].grossWt)) && b) {
            // 【毛重】为0，并且未遇到下一个有值
            n += 1;
          } else {
            b = false;
          }
        }
        return [n, 1];
      }
      return [0, 0];
    }
    return [1, 1];
  }

  /** 表单求和
     * columns是每列值，data是数据
     * 遍历columns列的值，取出key值，便于后期data中以key取值
     * 遍历data数据，取出当前列的每行值累加结果放入sums
     * 结果以返回一个数组形式展示，ID列要以『合计』为名称，
     * 先判断ID列，将其设置为「合计」，不是ID则先设置为空，先点位。
     * 排除不计算合计的列
     * 需要计算的则将计算的结果 覆盖sums最初的空字符串
     * 再计根据情况设置小数点
     *
     * 注意：合并例以0值代替
     */
  function getSummaries({ columns, data }) {
    const sums = [];// 返回展示结果
    columns.forEach((column, index) => {
      const propName = column.property;// 获取到key
      sums[index] = ((propName === 'ID') && '合计') || '';// 第一行ID为合计名称，其他先占位空
      // 排除不用计算的，减少计算，第一行不计算
      if (
        !['packageState', 'hsCode', 'productName', 'unitPrice'].includes(propName)
        && index !== 0
      ) {
        let n = 0;
        data.forEach((element) => {
          n += Number(element[propName]);
        });
        switch (propName) {
          case 'ctns':
            sums[index] = n;
            break;
          case 'totalAmount':
            sums[index] = n.toFixed(4);
            break;
          default:
            sums[index] = n.toFixed(2);
            break;
        }
      }
    });
    return sums;
  }

  return {
    autoMakeProductCNUnit, // 根据代码自动生成【产品数量】中文单位
    getSummaries, // 表单求和
    objectSpanMethod, // 合并行或列的计算方法
    rowClkFunc, // 行的点击事件
    mySetIndex, // 自定义表单索引ID
    handleCurrentChange, // 选中行发生变动函数
  };
}
