import {
  ElNotification,
  ElMessageBox, // 弹出对话框
  ElMessage, // 顶部提示框
} from 'element-plus';
import myAxios from '../../../utils/axios/index_v1';
import Rules from '../../../utils/utilsClass/rulesClass';

export default class DrawerDetailsClass {
  constructor(myDatas) {
    this.myDatas = myDatas;
  }

  /**
         * 对比当前数据是否有改动
         * 清空共享数据$createCustomsDatas
         */
  clearTempStore = () => {
    const { storeDatas } = this.myDatas;
    // 清空共享中临时内容 关闭所有【侧边栏】关恢复原始值
    storeDatas.saveState = false; // 保存状态
    storeDatas.drawerDetailsRefs = null; // drawerDetails $refs
    storeDatas.weightInfoRefs = null; // weightInfo $refs
  }

  /** 去除申报实例html代码，
       * 如： `<font color="red">${keyWord}</font>` html
       *
       */
  delHtmlCode = (sbslProductName) => sbslProductName.replaceAll('<font color="red" tag="myself">', '')
    .replaceAll('</font>', '')
    .trim()
    .replaceAll('  ', '')
    .replaceAll(' ', '')
    .replaceAll('，', '')

  // delStr 删除特殊字符
  delStr = (content) => {
    if (content !== undefined && content !== null && content !== '') {
      return content.replaceAll('  ', '')
        .replaceAll(' ', '')
        .replaceAll('，', ',')
        .replaceAll('；', ',')
        .replaceAll('：', ':');
    }
    return '';
  }

  /** pushBaseDatas 向datas中添加基础数据 */
  pushBaseDatas = () => {
    const {
      baseDatas,
      datas, storeDatas,
    } = this.myDatas;
    // 遍历baseDatas中内容添加至datas
    Object.keys(baseDatas).forEach((key) => {
      datas.tradeDatas[key] = baseDatas[key].value; // 遍历出key添加到datas中
    });
    // 向海关编码栏中添加固定的海关编码信息
    datas.tradeDatas.hsCode = storeDatas.argOfProductName.hsCode;
    datas.tradeDatas.productName = this.delHtmlCode(storeDatas.argOfProductName.productName);
  }

  /** initBaseDatas 初始化渲染申报明细元素
         * @param res object 申报要素明细
         * 申报要素处理方法
         * 根据数据库查询到的申报要素进行向datas中添加基本数据 再渲染
         * 流程原理：
         * 1 向数据获取申报要素
         * 2 数据格式：[{item: 0, cn_name: '品名', is_must_filed: 1}....]
         * 3 先将固定格式提取固定后，再提取未数据中无需固化数据的信息
         * 4 将无需固化的数据提出以sbys_序列号item作为主键
         * 先存入于baseDats中
         */
  initBaseDatas = (res) => {
    const {
      baseDatas, datas,
      // storeDatas,
      // initDatas,
    } = this.myDatas;
    res.forEach((e, idx) => {
      const proName = e.cn_name;

      if (proName === '品名') {
        Object.assign(baseDatas.productName, {
          setTimeoutObj: null,
          inputFunc: this.activeChangeFunc(), // 添加range监听事件
        });
      } else if (proName === '出口享惠情况') {
        Object.assign(baseDatas.enjoyDiscont, {
          setTimeoutObj: null,
          inputFunc: this.activeChangeFunc(), // 添加range监听事件
        });
      } else if (proName === '品牌类型') {
        Object.assign(baseDatas.brandType, {
          setTimeoutObj: null,
          inputFunc: this.activeChangeFunc(), // 添加range监听事件
        });
      } else {
        let placeholderVal = '';
        let labelName = '';
        const upperStr = proName.toLocaleUpperCase();
        // 创建一个新的对象
        const keyName = (proName === '品牌[中文及外文名称]') ? 'brandName' : `sbys_${e.item}`;
        baseDatas[keyName] = {};// 先创建一个对象
        // 解析
        if (/\[*\]/.test(proName)) {
          // 类似于 其他[非必报要素，请根据实际情况填报]
          const regRes = proName.match(/(.*)\[(.*)\]/);
          placeholderVal = regRes[2].toString();
          labelName = regRes[1].toString();
          if (proName === '品牌[中文及外文名称]') {
            // 品牌可编辑状态信息
            Object.assign(baseDatas[keyName], {
              disabled: true,
            });
          }
        } else if (upperStr === 'GTIN' || upperStr === 'CAS') {
          // GTIN  CAS
          labelName = upperStr;
          placeholderVal = upperStr;
          Object.assign(baseDatas[keyName], {
            disabled: !e.is_must_filed,
          });
        } else if (/^签约日期/.test(proName)) {
          labelName = proName;
          placeholderVal = proName;
          // 签约日期
          baseDatas[keyName].datePicker = true; // 时间选择器标签
        } else {
          // 其他
          labelName = proName;
          placeholderVal = proName;
        }
        if (idx === (res.length - 1)) {
          // 最后一个添加bulur事件,
          Object.assign(baseDatas[keyName], {
            blurFuncs: () => {
              this.focusFirstNode(1);// 最后一个bulr重新定位至第一个
            },
          });
        }

        const mustInput = (proName === '品牌[中文及外文名称]')
          ? '填写前请选择[品牌类型]'
          : `请填写-${placeholderVal}`;

        Object.assign(baseDatas[keyName], {
          label: labelName,
          placeholder: e.is_must_filed ? `必填内容-${placeholderVal}` : '非必填内容',
          toolTipVal: e.is_must_filed
            ? mustInput
            : `${placeholderVal}`,
          value: (proName === '品牌[中文及外文名称]') ? '无牌' : '',
          setTimeoutObj: null,
          inputFunc: this.activeChangeFunc(), // 添加input监听事件
        });

        // 添加校验
        if (e.is_must_filed) {
          datas.rules[keyName] = Rules.DrawDetails;
        }
      }
    });
  }

  /** 添加信息至共享 同时 修改产品信息栏中的【品名】与【海关编码】
   *
   * @param {*} sbysObj 申报要素晚实例
   * @returns
   */
  addInfoToStore = () => {
    const {
      datas, storeDatas, sbslContent,
    } = this.myDatas;
    return new Promise((resolve) => {
      const {
        hsCode, // 海关编码
        productName, // 产品名称
        enjoyDiscont, // 优惠
        brandType, // 品牌类型
        // brandName, // 品牌名称
      } = datas.tradeDatas;

      // 设置品名(含申报要素)
      let joinProductName = '';
      joinProductName = `${productName.toString()}  ${brandType.toString()}|${enjoyDiscont.toString()}`;// 名称  1|0

      // 组合成字符串长名
      sbslContent.sbysObj.forEach((item) => {
        const proName = item.cn_name;
        if (!['品名', '品牌类型', '出口享惠情况'].includes(proName)) {
          const keyName = (proName === '品牌[中文及外文名称]') ? 'brandName' : `sbys_${item.item}`;
          joinProductName += `|${datas.tradeDatas[keyName].toString()}`;
        }
      });

      // 修改【产品信息】中的编码、品名信息
      storeDatas.setWeightInfoHscode({
        hsCode: hsCode.toString(), // 海关编码
        productName: joinProductName.toString(), // 产品名（拼接）
        unitJointStr: storeDatas.argOfProductName.unitJointStr, // 法定单位字符串
      });

      // 清空共享中临时内容 关闭所有【侧边栏】关恢复原始值
      this.clearTempStore();
      storeDatas.drawerHscode = false; // 海关编码信息侧边栏开关
      storeDatas.drawerDetails = false; // 申报明细侧边栏开关
      resolve(true);
    });
  }

  /** loadSbslToInput 加载海关信息传递过来的数据填充至dataBase中
                 * @param{string} sbysObj 申报要素对象
                 * @param{string} sbslStr 海关申报实例内容
                 * 原理：
                 * 1 从海关信加载过来的申报实例进行拆分。
                 * 2 拆分出数量对应数据，品牌，品名，这些为必填写项
                 * 3 记录[品名,出口享惠情况,品牌类型,品牌[中文及外文名称],GTIN, CAS,签约日期]
                 * 4 对照申报要素表设置字段名称
                 * 5 如果有填写品牌但【申报要素】对象没有品牌[中文及外文名称]栏，
                 *   则在最后添加品牌，会超过【申报要素对象】的长度，注意后面拆分
                 */
  loadSbslToInput = (sbysObj, sbslStr) => {
    const {
      datas,
      storeDatas, baseDatas,
    } = this.myDatas;
    const { enterDetailsType } = storeDatas.argOfProductName; // 获取添加的数据类型：true：【产品栏】进入，false：【海关搜索】进入

    // 判断首字是数字或其他，数字说明是0|0 申报内容
    const bl = /^[0-4]{1}\|[01]{1}/.test(sbslStr);
    if (sbslStr === undefined) return;// 没有数据不执行后面代码
    // 是以何种来匹配|或分割;
    const sbslCtnSplitAry = sbslStr.indexOf('|') !== -1 ? sbslStr.split('|') : sbslStr.split(';');
    let sbslPrefix = [];
    let sbslAry = [];

    // 拆分数据
    if (bl && sbslStr) { // 以数字开头的实例
      sbslPrefix = sbslCtnSplitAry.slice(0, 2);// 取出前缀
      sbslAry = sbslCtnSplitAry.slice(2, sbslCtnSplitAry.length); // 截取【出口享惠情况】之后的内容
    } else {
      sbslAry = sbslCtnSplitAry;
    }
    const sbslPrefixLen = sbslPrefix.length;

    let tempIdx = 0;// 用记录下标取申报实例数据索引值
    sbysObj.forEach((item) => {
      const proName = item.cn_name;
      const enProName = proName.toLocaleUpperCase();
      if (/^签约日期/.test(proName)) {
        datas.tradeDatas[`sbys_${item.item}`] = enterDetailsType ? sbslAry[tempIdx] : '';
        tempIdx += 1;
      } else if (/品牌\[中文及外文名称\]/.test(proName)) {
        if (sbslPrefixLen === 2 && sbslPrefix[0] === '0') {
          datas.tradeDatas.brandName = '无牌';
          baseDatas.brandName.disabled = true;
        } else if (sbslPrefixLen === 2 && sbslPrefix[0] !== '0') {
          datas.tradeDatas.brandName = sbslAry[tempIdx];
          baseDatas.brandName.disabled = false;
        } else {
          datas.tradeDatas.brandName = '无牌';
          baseDatas.brandName.disabled = true;
        }
        tempIdx += 1;
      } else if (/品牌类型/.test(proName)) {
        datas.tradeDatas.brandType = sbslPrefix.length === 2 ? sbslPrefix[0].toString() : '0';
      } else if (/出口享惠情况/.test(proName)) {
        datas.tradeDatas.enjoyDiscont = sbslPrefix.length === 2 ? sbslPrefix[1].toString() : '0';
      } else if (enProName === 'CAS' || enProName === 'GTIN') {
        // 不操作，不添加tempIdx
        if (enterDetailsType) {
          datas.tradeDatas[`sbys_${item.item}`] = this.delStr(sbslAry[tempIdx]);
          tempIdx += 1; //  从产品信息进入
        }
      } else if (!(/品名/.test(proName))) { // 品名之前已添加
        datas.tradeDatas[`sbys_${item.item}`] = this.delStr(sbslAry[tempIdx]);
        tempIdx += 1;
      }
    });
  }

  // saveInfo 保存表单
  saveInfo = () => {
    const {
      saveBtn,
      storeDatas,
      ruleForm,
      baseDatas,
    } = this.myDatas;
    const { argOfProductName } = storeDatas;

    // 【取消】则返回上一页
    if (!saveBtn.value) {
      if (argOfProductName.enterDetailsType) { // 【产品信息】点击进入,
        storeDatas.drawerDetails = false;
        storeDatas.drawerHscode = false;
      } else { // 【海关编码信息】进入
        storeDatas.drawerDetails = false;
      }
    } else { // 【保存】
      ruleForm.value.validate((volid, filed) => {
        // 先校验表单
        if (volid) {
          // 如果是【海关编码】页进入，保存前需要提示认真核对数据
          // 判断以何种方式进入true:【产品信息】进入,false:从选择海关实例进入
          if (!argOfProductName.enterDetailsType) { // 选择海关实例进入
            ElMessageBox.confirm('务必仔细核对产品参数与『加载』的参数是否正确！', {
              title: '提示',
              cancelButtonText: '修改',
              confirmButtonText: '正确',
            }).then(() => {
              // 添加完整信息至provice（productsIgnfo)
              // 获取到datas中form表单信息,组合成品名【品名 |1|0|xxx|xxx|】
              this.addInfoToStore().then((res) => {
                if (res) {
                  ElMessage.success({
                    message: '添加【品名】成功',
                  });
                }
              });
            }).catch(() => {
              // TODO
            });
          } else { // 【产品信息】栏进入直接保存
            this.addInfoToStore().then((res) => {
              if (res) {
                ElMessage.success({
                  message: '添加【品名】成功',
                });
              }
            });
          }
        } else {
          // 错误信息提示
          Object.keys(filed).forEach((e) => {
            const { label } = baseDatas[e];

            setTimeout(() => {
              ElNotification.error({
                title: '警告',
                message: `【${label}】:${filed[e][0].message}`,
                duration: 3000,
              });
            }, 500);
          });
        }
      });
    }
  }

  // resetForm 重置表单
  resetForm = () => {
    const {
      ruleForm, saveBtn, datas, initDatas, baseDatas,
    } = this.myDatas;

    ElMessageBox.confirm('确定要清空数据？', {
      title: '提示',
      cancelButtonText: '取消',
      confirmButtonText: '确定',
    }).then((res) => {
      if (res === 'confirm') {
        datas.tradeDatas = { ...initDatas };
        ruleForm.value.resetFields();
        ElMessage.success('重置成功');
        saveBtn.value = false;
        // 判断品牌类型是否为无品牌
        if (Object.prototype.hasOwnProperty.call(datas.tradeDatas, 'brandName')) {
          // 存在品牌录入属性
          baseDatas.brandName.disabled = !!(initDatas.brandType === '0');
        }
      }
    })
      .catch((err) => {
        if (err === 'cancel') {
          ElMessage.warning('取消【重置】操作');
        }
      });
  }

  // btnstatus 按钮显示值（比较值是否有变动）
  btnstatus = (oldDatas, newDatas) => {
    // 这里不用Object.entries或JSON.stringify因为有排序问题
    const { saveBtn } = this.myDatas;
    try {
      Object.keys(newDatas).forEach((key) => {
        if (Object.prototype.hasOwnProperty.call(oldDatas, key)) { // 存在属性
          if (newDatas[key].toString() !== oldDatas[key].toString()) {
            throw Error('false');// 有错误抛出异常，结束for
          }
        } else {
          // 不存在属性
          throw Error('false');
        }
      });
    } catch (error) {
      saveBtn.value = true;
      return true;
    }
    saveBtn.value = false;
    return false;
  }

  // activeChangeFunc按钮数据录入时监控
  /**
   * @param e 渲染页面形参 输入值
   * @param keyName 渲染页面形参 操作的健值名称
   * @returns 函数s
   */
  activeChangeFunc = () => {
    const that = this;
    return function (e, keyName) {
      const { setTimeoutObj } = that.myDatas.baseDatas[keyName];
      const {
        saveBtn, initDatas, datas, baseDatas,
      } = that.myDatas;

      clearTimeout(setTimeoutObj);
      this.setTimeoutObj = setTimeout(() => {
        // 设置品牌编辑动态信息
        if (keyName === 'brandType') {
          // rangeOfBrandTypeFunc 根据品牌类型来设置品牌输入方式
          const rangeOfBrandTypeFunc = (disabledVal, inputVal) => {
            if (Object.prototype.hasOwnProperty.call(datas.tradeDatas, 'brandName')) {
              // 存在有品牌输入属性
              if (Object.prototype.hasOwnProperty.call(baseDatas.brandName, 'disabled')) {
                baseDatas.brandName.disabled = disabledVal;// 可编辑
                if (['无牌', '空', '无', '', '无品牌', '品牌无'].includes(datas.tradeDatas.brandName)) {
                  datas.tradeDatas.brandName = inputVal;// 置空
                } else if (inputVal === '无牌') {
                  datas.tradeDatas.brandName = '无牌';// 置空
                }
              }
            }
          };
          if (datas.tradeDatas[keyName] !== '0') {
            // 品牌类型不是无品牌，需要处理品牌输入框的显示 问题
            rangeOfBrandTypeFunc(false, '');
          } else {
            rangeOfBrandTypeFunc(true, '无牌');
          }
        }

        // 设置按钮状态信息
        if (!saveBtn.value && (initDatas[keyName] !== e)) {
          // 输入的值已改变，需修改按钮状态
          saveBtn.value = true;// 保存状态
        } else {
          // 校验整个内容是否与备份值相同
          that.btnstatus(initDatas, datas.tradeDatas);
        }
      }, 500);
    };
  }

  /**
   * focusFirstNode 让指定节点获取焦点
   * @param {Number} idx 索引值
   */
  focusFirstNode = (idx) => {
    const { storeDatas, ruleForm } = this.myDatas;
    storeDatas.drawerDetailsRefs = ruleForm.value;
    ruleForm.value.$el[idx].focus();// 将光标放置到idx索引
    // if (ruleForm.value.$el.length > 0) {
    //   ruleForm.value.$el[idx].focus();
    // }
  }

  onBeforeMountFunc = async () => {
    const {
      storeDatas,
      sbslContent, initDatas, datas,
    } = this.myDatas;
    const { argOfProductName } = storeDatas;
    const { enterDetailsType } = argOfProductName;
    // 先向数据库查询申报要素内容
    await myAxios().initAxios({
      method: 'post',
      url: '/declare_sbys',
      data: {
        hscode: argOfProductName.hsCode,
      },
    }).then((res) => {
      // 申报要素 请求
      if (res.code === 200) { //  请求成功
        const sbslOBJ = res.datas;
        sbslContent.sbysObj = [...res.datas];
        this.initBaseDatas(sbslOBJ);
        this.pushBaseDatas();// 将空数据添加到表单的数据中
        if (enterDetailsType) { // 【产品信息】栏进入
          this.loadSbslToInput(sbslOBJ, argOfProductName.exampleRef);// 加载申报实例
          Object.assign(initDatas, { ...datas.tradeDatas });// 备份初始值
        } else { // 【海关编辑】进入
          Object.assign(initDatas, { ...datas.tradeDatas });// 备份初始值
          this.loadSbslToInput(sbslOBJ, argOfProductName.exampleRef);// 加载申报实例
        }
        this.btnstatus(initDatas, datas.tradeDatas);
        this.focusFirstNode(1);
      }
    }).catch((err) => { // 有错误
      let errMsg = err;
      if (Object.prototype.hasOwnProperty.call(err, 'state')) {
        errMsg = err.state;
      } else if (Object.prototype.hasOwnProperty.call(err, 'message')) {
        errMsg = err.message;
      }
      // 报错时 返回初始页
      if (enterDetailsType) {
        // 解压品名对应申报要素单元
        storeDatas.drawerDetails = false;
        storeDatas.drawerHscode = false;

        ElMessage.error('加载错误');
        console.error('从海关信息侧边栏过来的数据', errMsg);
      } else {
        // 从海关信息侧边栏过来的数据
        storeDatas.drawerDetails = false;
        ElMessage.error('加载错误');
        console.error('从海关信息侧边栏过来的数据', errMsg);
      }
    });
  }
}
