<template>
  <div class="manufacturerinfo">
    <el-form ref="factoryInfoRef" :model="manufacturer" :rules="factoryRules" label-width="120px" class="demoRuleForm">
      <el-form-item :label="baseDatas.companyName.label" prop="companyName">
        <el-autocomplete size="small" v-model="manufacturer['companyName']"
          :placeholder="baseDatas.companyName.placeholder" :hide-loading="true" :trigger-on-focus="true"
          :fetch-suggestions="manuFactureInfoClass.querySearchAsync" @select="manuFactureInfoClass.handleSelect"
          popper-class="test_class"></el-autocomplete>
      </el-form-item>
      <el-form-item :label="baseDatas.companyCode.label" prop="companyCode">
        <el-input size="small" :placeholder="baseDatas.companyCode.placeholder" v-model="manufacturer['companyCode']"
          clearable></el-input>
      </el-form-item>
      <el-form-item :label="baseDatas.tel.label" prop="tel">
        <el-input size="small" :placeholder="baseDatas.tel.placeholder" v-model="manufacturer['tel']"
          @input="baseDatas.tel.inputFunc" clearable></el-input>
      </el-form-item>
      <el-form-item :label="baseDatas.contactName.label" prop="contactName">
        <el-input size="small" :placeholder="baseDatas.contactName.placeholder" v-model="manufacturer['contactName']"
          clearable></el-input>
      </el-form-item>
      <el-form-item :label="baseDatas.address.label" prop="address">
        <el-input size="small" :placeholder="baseDatas.address.placeholder" v-model="manufacturer['address']"
          clearable></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import {
  onBeforeMount,
  onMounted,
  reactive,
  toRefs,
  ref,
  inject,
  nextTick,
} from 'vue';

import Rules from '../../utils/utilsClass/rulesClass';
import ManuFactureInfoClass from './class/manufactureInfoClass';

export default {
  name: 'manufacturerinfo',
  setup() {
    // 数据
    const factoryInfoRef = ref(null);// 获取当前节点
    const createCustomsDatasBus = inject('createCustomsDatasBus');// 传递公共数据
    const setFilterOfContainer = inject('setFilterOfContainer'); // 登陆相关数据（父组件共享）

    const {
      elMainRef,
      // saveDatasFuncsBus, // 父组件调用子组件函数
      factoryInfo, // 父组件调存储
      factoryInfodatas, // 数据
    } = createCustomsDatasBus;

    const factoryRules = reactive({
      companyName: [Rules.CompanyCNRules],
      companyCode: [Rules.CompanyCode],
      tel: [Rules.MobileRules],
    });
    // 页面加载完成后将$ref赋值给共享数据$bus
    nextTick(() => {
      createCustomsDatasBus.factoryInfoRef.value = factoryInfoRef.value;
    });

    const baseDatas = reactive({
      companyName: {
        label: '生产企业',
        placeholder: ' 请输入生产企业全称',
        value: '',
      },
      companyCode: {
        label: '单位信用代码',
        placeholder: '生产企业18位信用编码',
        value: '',
      },

      tel: {
        label: '手机',
        placeholder: '选填内容，生产企业联系电话',
        value: '',
        myclass: true,
        errormsg: '',
        inputFunc: (e) => {
          if (e !== '' && !Object.prototype.hasOwnProperty.call(factoryRules.tel[0], 'asyncValidator')) {
            factoryRules.tel[0].required = true;
            Object.assign(factoryRules.tel[0], { asyncValidator: Rules.FactoryMobile });
          } else if (e === '' && Object.prototype.hasOwnProperty.call(factoryRules.tel[0], 'asyncValidator')) {
            factoryRules.tel[0].required = false;
            delete factoryRules.tel[0].asyncValidator;
          }
        },
      },
      contactName: {
        label: '联系人',
        placeholder: '选填内容，联系人',
        value: '',
        required: false,
      },
      address: {
        label: '地址',
        placeholder: '选填内容，生产企业地址',
        value: '',
        required: false,
      },
    });

    // 参数值：绑定数据，登陆相关数据,操作的关键key值（即是由哪个关键字的key去查询），访问后端的地址
    const manuFactureInfoClass = new ManuFactureInfoClass(factoryInfodatas.manufacturer, setFilterOfContainer, 'companyName', 'factory_info', (res) => {
      const options = [];
      res.results.forEach((item) => {
        item.value = `${item.companyName} ${item.companyCode} ${item.address}`;
        options.push(item);
      });
      return options;
    });// 后台加载生产厂商的执行方法类
    // 方法
    const myMethods = reactive({
      initDatas: () => {
        // 初始化数据,将所有数据清空，并将当前值赋值给到共享数据
        Object.keys(baseDatas).forEach((key) => {
          factoryInfodatas.manufacturer[key] = '';// 所有数据清空
          factoryInfodatas.manufacturer[key] = baseDatas[key].value; // 遍历出key添加到datas中
        });
        factoryRules.tel[0].required = false;
        delete factoryRules.tel[0].asyncValidator;
      },
    });
    onBeforeMount(() => {
      myMethods.initDatas();
    });
    // 添加测试数据
    onMounted(() => {
      const testDatas = {
        address: '',
        companyCode: '',
        companyName: '',
        contactName: '',
        tel: '',
      };
      Object.assign(factoryInfodatas.manufacturer, testDatas);// 拷贝对象
    });

    return {
      ...toRefs(factoryInfodatas),
      ...toRefs(myMethods),
      factoryRules,
      createCustomsDatasBus,
      baseDatas,
      // saveDatasFuncsBus,
      factoryInfoRef,
      factoryInfo,
      elMainRef,
      manuFactureInfoClass,
      // querySearchAsync,
      // handleSelect,
      // focusFunc,
    };
  },
};
</script>
<style lang="scss" scoped>
.mycard {
  padding: $base * 0.5 !important;
}

.manufacturerinfo {

  padding: $base $base $base * 0.3 $base;
  @import '@/assets/scss/globalcss/elementsFromstyle.scss';

  .demoRuleForm {
    :deep(.el-form-item) {
      &:nth-child(-n+4) {
        width: 50%;
      }

      &:last-child {
        width: 100% !important;
      }
    }
  }

  .myclass {

    //隐藏*数据
    :deep(.el-form-item__label) {
      position: relative;

      &::before {
        content: '' !important;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}
</style>
