// import { onBeforeMount } from 'vue';
import { nextTick } from 'vue';
import {
  ElMessage,
  ElNotification,
  ElMessageBox,
} from 'element-plus';
import unitAry from '../../../../configs/unit';
import utils from '../../../../utils/deepCopy';

export default class WeightInfoMethodsClass {
  constructor(createCustomsDatasBus, baseDatas) {
    this.createCustomsDatasBus = createCustomsDatasBus;
    this.baseDatas = baseDatas;
  }

  /** 根据currentBtnState的值设置disabled编辑状态
   * @param {*} createCustomsDatasBus
   * @param {*} baseDatas
   */
  initDisabledState = () => {
    const { config, stateContent } = this.createCustomsDatasBus;
    const setDisabledState = (formIsDisable, packageStateIsDisable = false, moreProductCtnsIsDisable = false, ctnsIsDisable = false) => {
      config.disabledForm = formIsDisable;// 设置全局可否编辑[false:可编辑,true:不可编辑]
      this.baseDatas.packageState.disabledState = packageStateIsDisable;// 设置是否混装可否编辑[true：不可编辑，false:可编辑 ]
      this.baseDatas.moreProductCtns.disabledState = moreProductCtnsIsDisable;// 设置混装品类数量可否编辑[true：不可编辑，false:可编辑 ]
      this.baseDatas.ctns.disabledState = ctnsIsDisable;// 设置箱数可否编辑[true：不可编辑，false:可编辑 ]
    };
    switch (config.currentBtnState) {
      case 'mixinInit':
        if (stateContent.nextStateus) { // 未返回
          setDisabledState(false);
        } else { // 返回的数据
          setDisabledState(false, true, true, true);
        }
        break;
      case 'mixinEdite':
      case 'mixinSave':
        setDisabledState(false, true, true, true);
        break;
      case 'editStateAdd':
      case 'editStateSave':
        setDisabledState(false, false, true);
        break;
      case 'modifyStateAdd':
      case 'modifyStateSave':
        setDisabledState(false, true, true, false);
        break;
      case 'tableStateShow':
        setDisabledState(true, true, true, true);
        break;
      default:
        setDisabledState(false);
        break;
    }
  }

  /** 重置复制的数据
   * @param {Array} keepNames  保留的数组
   * @param {Object} opObj 要操作的对象
   * @returns 参数正确，返回对象 错误返回错误提示信息
   */
  resetCopyDatas = (keepNames, opObj) => {
    if (keepNames instanceof Array) {
      const newObj = {};
      Object.keys(opObj).forEach((key) => {
        newObj[key] = keepNames.includes(key) ? opObj[key] : '';
      });
      Object.assign(newObj, { mixinInfo: [], packageState: 0 });
      return newObj;
    }
    return '传递参数错误';
  }

  /**  混装情况下根据datas.detailsDatas.mixinInfo中的数据填充至formDatas中
       *因混装及表格合并需要，混装合并均次内容数据为0，为了展示具体内容，自动添加mixinInfo保存的数组数据来实现展示
       */
  mixinInfoToFormDatas = () => {
    const { config, datas } = this.createCustomsDatasBus;
    if (config.currentBtnState === 'tableStateShow' && Number(datas.detailsDatas.packageState) === 1) { // 加rowClkBus，是为了识别点击行有更改
      const mixinAry = [...datas.detailsDatas.mixinInfo];
      datas.detailsDatas.ctns = Number(mixinAry[1]).toString();
      datas.detailsDatas.grossWt = Number(mixinAry[2]).toFixed(2).toString();
    }
  }

  /** 对比数据是否有改动
     *
     * @param {*} oldDatas 原有数据
     * @param {*} newDatas 现有数据
     * @returns boolearn 没有改变 true 改变false
     */
  isAddNewDatas = (oldDatas, newDatas) => {
    // 这里不用Object.entries或JSON.stringify因为有排序问题
    try {
      Object.keys(newDatas).forEach((key) => {
        if (Object.prototype.hasOwnProperty.call(oldDatas, key)) { // 存在属性
          if (newDatas[key].toString() !== oldDatas[key].toString()) {
            throw Error('false');// 有错误抛出异常，结束for
          }
        } else {
          // 不存在属性
          throw Error('false');
        }
      });
    } catch (error) {
      return false;
    }
    return true;
  }

  /** 操作【当前进度】还原备份数据
     *
    */
  restoreBackUpDatas = () => {
    const { backupStepDatas, stepAllDatas } = this.createCustomsDatasBus;
    if (backupStepDatas.backupStepDatas.length !== 0) { // 防止多次调用再附值
      // 还有备份数据
      stepAllDatas.stepDatas = backupStepDatas.backupStepDatas;
      stepAllDatas.active = backupStepDatas.backupActive;
    }

    // 删除备份数据
    backupStepDatas.backupStepDatas = [];
    backupStepDatas.backupActive = null;
  }

  /** buttonState 修改按钮状态
       * @param {Object} createCustomsDatasBus 所有数据
       * @param {string} currentState 当前状态["mixinInit","mixinEdite","mixinSave","editStateAdd","editStateSave","modifyStateAdd","modifyStateSave","tableStateShow"]
       */

  buttonState = (currentState) => {
    const { btnState, config, rowClkBus } = this.createCustomsDatasBus;
    Object.keys(btnState).forEach((key) => {
      btnState[key] = false;// 先将全部设置为false,默认值
    });
    const parentStatus = { // 按钮当前状态
      // [保存/取消 |  重置 ]  正常编辑状态
      editState: () => {
        config.buttonState = true;
        config.nextBTN = false;
      },
      // [返回/上一步 | 全部保存/下一步] 混装编辑状态
      mixinEditState: () => {
        config.buttonState = true;
        config.nextBTN = true;
      },
      // [新增|复制|修改|删除] 展示表单状态
      tableState: () => { config.buttonState = false; },
    };
    const status = {
      // 【返回/上一步 | 全部保存/下一步】
      mixinInit: () => { // 混装初始状态【返回|下一步】
        parentStatus.mixinEditState();// 混装编辑状态
        btnState.prev = true;
        btnState.next = false;
      },
      mixinEdite: () => { // 混装编辑状态中【上一步|下一步】
        parentStatus.mixinEditState();// 混装编辑状态
        btnState.prev = false;
        btnState.next = false;
      },
      mixinSave: () => { // 混装准备保存状态【上一步|全部保存步】
        parentStatus.mixinEditState();// 混装编辑状态
        btnState.prev = false;
        btnState.next = true;
      },
      // 【保存/取消 | 重置】
      editStateAdd: () => { // 【取消|重置】状态
        parentStatus.editState();
        btnState.save = false;
      },
      editStateSave: () => { // 【保存|重置】状态
        parentStatus.editState();
        btnState.save = true;
      },
      modifyStateAdd: () => { // 【取消|重置】状态
        parentStatus.editState();
        btnState.save = false;
      },
      modifyStateSave: () => { // 【保存|重置】状态
        parentStatus.editState();
        btnState.save = true;
      },
      tableStateShow: () => { // 【新增|复制|修改|删除】状态
        parentStatus.tableState();
        // 全部为不可编辑状态
        config.disabledForm = true;
      },
    };
    // 限定currentState的值
    if (['mixinInit', 'mixinEdite', 'mixinSave', 'editStateAdd', 'editStateSave', 'modifyStateAdd', 'modifyStateSave', 'tableStateShow'].includes(currentState)) {
      status[currentState]();
      // 设置当前按钮状态值
      config.currentBtnState = currentState;
      rowClkBus.currentBtnState = currentState;
      this.initDisabledState();// 根据currentBtnState的值设置disabled编辑状态
    } else {
      console.error('按钮参数值超出范围');
    }
  }

  /** 根据内容而设置按钮内容
      * @param {*} createCustomsDatasBus
      *  第一步【返回|下一步】                  nextStep为0
      *  【上一步|全部保存】                    nextStep等于moreProductCtns-1
      *  中间【上一步|下一步】【上一步|全部保存】  nextStep不为0
     */
  setBtnState = () => {
    const {
      datas, config, initDatas, stateContent,
    } = this.createCustomsDatasBus;
    const {
      packageState, moreProductCtns,
    } = datas.detailsDatas;
    /**
     * 不可编辑 （disabledForm:false)                 tableStateShow
     * 可编辑   （disabledForm:true)
     *    修改   (config.modifyState : true)
     *        混装 （packageState : 1)
     *            未修改  （对比）                     modifyStateAdd
     *            修改    （对比)                     modifyStateSave
     *        非混装 （packageState : 0)
     *            未修改  （对比）                     modifyStateAdd
     *            修改    （对比)                     modifyStateSave
     *    正常添加编辑 (config.modifyState = false)
     *        混装 （packageState : 1)
     *          第一步：mixinInit                    mixinInit
     *        非混装 （packageState : 0)
     *
     */
    if (!config.disabledForm) { // 可编辑模式
      if (config.modifyState) { // 修改状态
        this.buttonState( // 根据内容更改对比设置【取消/保存|重置】
          this.isAddNewDatas(datas.detailsDatas, initDatas)
            ? 'modifyStateAdd'// 未更改 【取消|重置】
            : 'modifyStateSave',
        );// 更改 【保存|重置】
      } else { // 非修改状态 ，正常编辑
        const { nextStep } = stateContent;
        if (Number(packageState) === 1) { // 正常编辑 混装
          if (nextStep === 0) {
            this.buttonState('mixinInit');// 【返回|下一步】
          } else if (nextStep === moreProductCtns - 1) {
            this.buttonState('mixinSave');// 【上一步|全部保存】
          } else {
            this.buttonState('mixinEdite');// 【上一步|下一步】或 【上一步|全部保存】
          }
        } else { // 正常编辑 非混装
          this.buttonState(this.isAddNewDatas(datas.detailsDatas, initDatas) ? 'editStateAdd' : 'editStateSave'); // 根据内容更改对比设置【取消/保存|重置】
        }
      }
    } else { // 不可编辑模式
      this.buttonState('tableStateShow');// 【新增|复制|修改|删除】状态
      this.baseDatas.netWt.tooltip = '本产品净重';
      this.baseDatas.grossWt.tooltip = '本产品毛重';
      config.modifyState = false;// 还原修改状态值为默认值为false
    }
  }

  /** 混装退出后清除数据 */
  mixinClearFunc = () => {
    const {
      config,
      stateContent, mixinpackOBJ, weightFormRef,
    } = this.createCustomsDatasBus;
    if (!config.disabledForm) { // 修改状态时判断是【返回】还是【全部保存】
      config.currentBtnState = config.currentBtnState === 'mixinSave' ? 'tableStateShow' : 'editStateAdd';
    }
    // 混装信息
    mixinpackOBJ.splice(0, mixinpackOBJ.length);
    Object.assign(stateContent, { // 添加覆盖原有数据,nextStateus保持不变
      uuid: null, // 记录当前批次的UUID
      nextTooltip: '【混装品类数量】未初始化完成', // 初始值，后期会变动
      prevTooltip: '返回至【非混装】状态', //  初始值，后期会变动
      nextStep: 0, // 初始步骤为0
    });
    // 【当前进度】
    this.restoreBackUpDatas();
    this.createCustomsDatasBus.backupStepDatas.backupStepDatas = [];// 【当前进度】 步聚值
    this.createCustomsDatasBus.backupStepDatas.backupActive = null;// 【当前进度】 当前进度值
    nextTick(() => { // 清空错误提示信息
      weightFormRef.value.clearValidate();
    });
  }

  /** 添加测试数据
     * @param {object} datas 测试数据
     * @param {object} createCustomsDatasBus 数据
     */
  insertTestDatasToDatas = (addDatas) => {
    const { tableDatas } = this.createCustomsDatasBus;

    tableDatas.unshift(...addDatas);
  }

  /** 设置法定单位选项
       * @param {string} unitJointStr 法定单位组合字符串
       * @returns {*}有值返回数组对象，无值则返回false
       */
  setUnitOptions = (unitJointStr) => {
    if (unitJointStr) {
      const optAry = [];
      const ary = unitJointStr.split(' | ');
      // unitAry 是全部法定单位数据导入数据
      unitAry.forEach((item) => {
        if (ary.includes(item.label)) { // 对象中有需要查找的内容
          optAry.push(item);
        }
      });
      return optAry;
    }
    return false;
  }

  // setUnitOptionsFunc 设置法定单位值
  setUnitOptionsFunc = () => {
    const optAry = this.setUnitOptions(this.createCustomsDatasBus.datas.detailsDatas.unitJointStr);
    if (optAry) {
      this.baseDatas.unit.selectOptions = [...optAry];
    }
  }

  /** 检验全部【产品信息】表单数据是否有误，并提示信息
       * @param {array} aryData 要校验的数组
       * @param {object} createCustomsDatasBus 数据
       * @param {object} baseDatas 渲染数据
       * @param {bool} isDilog 是否需要提示错误[true:提示,false:不需要提示],默认true
       * @returns  bool true:有错误，false:无错误
       */
  validateAllFileds = (aryData, isDilog = true) => {
    const { weightFormRef } = this.createCustomsDatasBus;

    if (typeof (aryData) === 'string') {
      aryData = [aryData];
    }

    const promisAry = aryData.map((item) => {
      const p = new Promise((resolve) => {
        nextTick(() => {
          weightFormRef.value.validateField(item, (errMsg) => {
            const tempObj = {};
            tempObj.label = this.baseDatas[item].label;
            tempObj.errMsg = errMsg;
            resolve(tempObj);
          });
        });
      });
      return p;
    });
    // 判断结果集中是否有错误
    return Promise.all(promisAry).then((res) => {
      let bl = false;
      res.forEach((item) => {
        if (item.errMsg !== '') {
          // 弹出错误提示框
          if (isDilog) {
            setTimeout(() => {
              ElNotification.error({
                title: '警告',
                message: `【${item.label}】:${item.errMsg}`,
                duration: 3000,
              });
            }, 100);
          }
          bl = true;
        }
      });
      if (bl && !isDilog) {
        ElMessage.error('请填写正确内容');
      }
      return bl;
    });
  }

  /** 初始化表单数据
     *
     * @param {Object} createCustomsDatasBus 表单数据
     */
  initFormDatas = () => {
    const {
      config,
      weightFormRef,
      storeDatas, datas,
    } = this.createCustomsDatasBus;

    weightFormRef.value.resetFields();// 将数据初始化置空

    // 更改为可编辑状态
    config.disabledForm = false;
    // 将混装品类数量标记为无需校验状态
    datas.rules.moreProductCtns.map((obj) => {
      obj.required = false;
      return obj;
    });

    // 清空store中数据
    Object.keys(storeDatas.argOfProductName).forEach((key) => {
      storeDatas.argOfProductName[key] = (key === 'enterDetailsType') ? false : '';
    });

    const reSetSleectOptions = () => {
      const tempAry = [];
      unitAry.forEach((item) => {
        if (!['克', '千克', '克拉'].includes(item.label)) {
          tempAry.push(item);
        }
      });
      return tempAry;
    };
    // 手动删除以下三个
    datas.detailsDatas.unitJointStr = '';
    datas.detailsDatas.mixinInfo = [];
    this.baseDatas.unit.selectOptions = [];// 还原【法定单位】
    this.baseDatas.productQTY.appendOptions.selectOptions = [...reSetSleectOptions()];// 还原【产品数量】下拦框值

    weightFormRef.value.resetFields();
    // 清除【产品数量】验证错误信息
    nextTick(() => {
      weightFormRef.value.clearValidate(['productQTY']);
    });
    this.createCustomsDatasBus.initDatas = { ...datas.detailsDatas };
    this.setBtnState();
  }

  /** 初始化表单
      * @param {Object} baseDatas 渲染数据
      * @param {Object} datas form表单数据
      * 将baseDatas中渲染到页面，同时将【表单】数据detailDatas作初始化（无数据）
      *
      */
  initForm = () => {
    const {
      datas, initDatas,
    } = this.createCustomsDatasBus;

    this.baseDatas.unitQTY.value = '';// 法定单位数量置空
    Object.keys(this.baseDatas).forEach((key) => {
      // 如果是【产品数量】，需要遍历appendOptions中选择器,同时向datas添加数据
      if (key === 'productQTY') {
        const newKey = this.baseDatas[key].appendOptions.key;
        datas.detailsDatas[newKey] = null;
        datas.detailsDatas[newKey] = this.baseDatas[key].appendOptions.value;
      } else {
        datas.detailsDatas[key] = '';
      }
      datas.detailsDatas[key] = this.baseDatas[key].value; // 遍历出key添加到datas中
      const baseDatasKey = this.baseDatas[key];
      if (Object.keys(baseDatasKey).includes('rules')) {
        // 只有rules存在才执行，添加校验规则
        datas.rules[key] = [];
        datas.rules[key].push(...baseDatasKey.rules);
        datas.rules[key].forEach((e) => {
          e.required = baseDatasKey.required;
        });
      }
    });

    // 添加 新对象至数据中
    Object.assign(datas.detailsDatas, { mixinInfo: [], unitJointStr: '' });

    // tableDatas中没有数据【保存】【重置】
    nextTick(() => {
      this.setBtnState();
    });

    // 初始化备份数据
    Object.assign(initDatas, { ...datas.detailsDatas });
  }

  /** 批量操作可编辑状态
     *
    //  * @param {Object} createCustomsDatasBus 全部数据
     * @param {Object} baseDatas 渲染数据
     * @param {Array} aryDatas
     * @param {Bool} bl false:可编辑 true:禁止编辑
     */
  handleDisabledStateFunc = (aryDatas, bl) => {
    if (aryDatas.constructor === Array) {
      aryDatas.forEach((key) => {
        this.baseDatas[key].disabledState = bl;
      });
    } else {
      console.error('参数出错 ');
    }
  }

  /** 修改【混装产品数量】红点，编辑状态及清空值【仅适用于是否混装点击事件】
     * @param {*} createCustomsDatasBus  全部数据
     * @param {*} baseDatas  渲染数据
     */
  mixinState = () => {
    const { datas, moreProductCtnsRef, config } = this.createCustomsDatasBus;
    this.baseDatas.ctns.disabledState = false;// 【箱数】可编辑

    const func = (bl) => { // bl 为bool类型
      delete datas.rules.moreProductCtns;// 先删除当前表单中的校验规则
      datas.rules.moreProductCtns = this.baseDatas.moreProductCtns.rules;// 重新复制
      datas.rules.moreProductCtns[0].required = bl;
      // 清除错误提示信息
    };
    if (datas.detailsDatas.packageState === 0) { // 【非混装】
      // 去除红点，同时不再校验
      datas.detailsDatas.moreProductCtns = '';// 清空【混装品名数量】内容
      func(false);
      nextTick(() => {
        moreProductCtnsRef.value.clearValidate();
        this.baseDatas.moreProductCtns.disabledState = true;// 将【混装品类数量】设置不可编辑
      });
    } else { // 【混装】
      // 向form中的rules添加【混装品名数量】校验
      func(true);
      if (config.currentBtnState === 'tableStateShow' || config.disabledForm) {
        nextTick(() => {
          moreProductCtnsRef.value.clearValidate();
        });
      }
    }
  }

  /** 生成校验数组
       * 需要判断是不是混装模式且在修改状态下，【混装品类数量】是无需进行校验。
       */
  validateAry = () => {
    const { datas } = this.createCustomsDatasBus;
    const tempAry = Object.keys(datas.rules);
    const morePctIdx = tempAry.indexOf('moreProductCtns');

    if (morePctIdx !== -1 && !datas.rules.moreProductCtns[0].required) {
      // 混装数量无需要填写
      tempAry.splice(morePctIdx, 1);
    }
    return tempAry;
  }

  /** 向【产品信息】栏中添加数据
     * @param {Object} createCustomsDatasBus
     * 不改变disabled
     */
  insertToFormData = (willInsertDatas) => {
    const {
      datas, config, weightFormRef,
    } = this.createCustomsDatasBus;
    Object.assign(datas.detailsDatas, willInsertDatas);// 向表单数据中添加值

    this.mixinInfoToFormDatas(this.createCustomsDatasBus);
    // 添加法定单位options
    const optionsStr = datas.detailsDatas.unitJointStr;
    const optAry = this.setUnitOptions(optionsStr);// 设置法定单位下拉选项
    if (optAry) {
      this.baseDatas.unit.selectOptions = [...optAry];
    }
    // 检验混装内容
    this.mixinState();
    if (config.currentBtnState === 'tableStateShow' || config.disabledForm) { // 【新增|复制|修改|删除】状态下点击行【产品信息】栏不进行校验
      nextTick(() => {
        weightFormRef.value.clearValidate();
      });
    }
  }

  /** 自动计算产品价值
       * @param {String} opNode 操作baseDatas节点
       * @param {Object} createCustomsDatasBus 操作数据
       * @param {Object} baseDatas baseDatas数据
       * @param {boll} bl 是否提示框
       */
  autoComputedVal = (opNode, bl = false) => {
    // 判断opNode接收到的值，来判断需要验证当前的字段内容
    let tempValAry = [];// 临时变量
    if (['unitQTY', 'unitPrice'].includes(opNode)) {
      tempValAry = ['unitQTY', 'unitPrice'];
    } else {
      tempValAry = ['unitQTY', 'totalAmount'];
    }
    this.validateAllFileds(tempValAry, bl).then((isError) => {
      if (!isError) { // 无误
        const { detailsDatas } = this.createCustomsDatasBus.datas;
        const { unitQTY, unitPrice, totalAmount } = detailsDatas;
        switch (opNode) {
          case 'unitQTY':// 【法定数量】
          case 'unitPrice':// 【单价】
            detailsDatas.totalAmount = Number(unitPrice * unitQTY).toFixed(4);
            break;
          default: // 【总价】

            if (Number(unitQTY) !== 0) { // 【法定数量】不能为0
              detailsDatas.unitPrice = Number(totalAmount / unitQTY).toFixed(4);
            } else {
              detailsDatas.unitPrice = Number(0).toFixed(4);
            }
            break;
        }
      }
    });
  }

  /** 【法定单位】数据变更时
    * @param {*} createCustomsDatasBus
    * @param {*} baseDatas
    */
  rangeUnitFunc = () => {
    const { weightFormRef, datas, config } = this.createCustomsDatasBus;
    const { detailsDatas } = datas;
    weightFormRef.value.clearValidate('unitQTY');
    if (detailsDatas.unit === '035') { // 035千克时，
      // 法定单位为千克时则取净重
      weightFormRef.value.validateField('netWt', (qtyErrMsg) => {
        if (!qtyErrMsg) {
          detailsDatas.unitQTY = detailsDatas.netWt;
        }
      });
    } else if (detailsDatas.unit === detailsDatas.qtyUnit) {
      // 单位与【产品数量】单位相同则值默认为相同
      detailsDatas.unitQTY = detailsDatas.productQTY;
    } else if (!config.modifyState) {
      // 其他（要判断是否为编辑状态,非编辑状态才空)
      detailsDatas.unitQTY = '';
    }
    this.baseDatas.unitQTY.value = detailsDatas.unitQTY;
    // 重新计算价格
    this.autoComputedVal('unitQTY', false);
  }

  /** 根据『海关申报要素』传递的值修改『产品信息中』的【海关编码】【品名】
      * @param {Object} baseDatas 渲染数据
      * @param {Object} datas form表单数据
      * 用途：【产品申报明细】保存时，同步设置【产品信息】栏中的【海关编码】【产品】信息
     */
  addStoreMethod = () => {
    const { storeDatas, datas, weightFormRef } = this.createCustomsDatasBus;

    // 初始化weightInfoHscode事件，
    storeDatas.setWeightInfoHscode = (obj) => {
      const oldUnitJointStr = datas.detailsDatas.unitJointStr;

      Object.assign(datas.detailsDatas, obj);
      // 获取【海关查询信息】或【产品申报要素明细】传递过来的单位，进行分割渲染数据
      if (// 条件需法定单位不为空且与现在的拼接单位内容不一样
        obj.unitJointStr !== ''
        && JSON.stringify(obj.unitJointStr) !== JSON.stringify(oldUnitJointStr)
      ) {
        const res = this.setUnitOptions(obj.unitJointStr); // 初始化法定单位
        if (res) {
          this.baseDatas.unit.selectOptions = [...res];// 设置渲染数据
          datas.detailsDatas.unit = res[0].value.toString();
        }
      }

      if (datas.detailsDatas.unitQTY) {
        this.rangeUnitFunc();
      }
      // 取消所有的验证结果
      weightFormRef.value.clearValidate();

      this.setBtnState();
    };
    // });
  }

  /** 返回上次事件状态
     *
     * @param {Object} createCustomsDatasBus 数据
     */
  goBackState = () => {
    const {
      initDatas, config, tableDatas, rowClkBus,
      // hsDetailTableRef,
    } = this.createCustomsDatasBus;
    // 清除备份数据initDatas
    Object.keys(initDatas).forEach((key) => {
      delete initDatas[key];
    });

    config.disabledForm = true; // 全部禁止编辑
    rowClkBus.setCurrent(rowClkBus.rangeClkId === null ? tableDatas[0] : tableDatas[rowClkBus.rangeClkId]);// 设置最新增加的行高亮

    this.setBtnState(); // 修改为【新增|复制|修改|删除】
    if (config.currentBtnState !== 'tableStateShow') {
      ElMessage.success({
        message: '【取消】当前操作',
      });
    }
  }

  /** 保存之前校验并保存
     *
     * @param {Object} createCustomsDatasBus 存储数据
     * @param {Object} baseDatas 渲染数据
     */
  saveItemBeforeFunc = () => {
    // 先校验数据正确性
    this.validateAllFileds(this.validateAry(), false).then((isError) => {
      if (!isError) {
        const {
          config, datas, tableDatas, mixinpackOBJ,
          hsTabaleRef, initDatas, rowClkBus,
        } = this.createCustomsDatasBus;
        const { detailsDatas } = datas;
        const { hsDetailTableRef } = hsTabaleRef.value;
        /** 校验数据是否重复
         * @param {Object} tbldata 表单数据
         * @param {String} pdcName 产品名称
         * @param {Object} willInsertDatas 要入tableDatas插入的数据
         */
        const validIncludesVal = (tbldata, pdcName, willInsertDatas) => {
          willInsertDatas.ctns = willInsertDatas.ctns.toString();
          const tableDataStrs = JSON.stringify(tbldata);
          const bl = tableDataStrs.includes(pdcName);
          if (bl) { // 有重复数据
            ElNotification.error({
              title: '错误提示',
              message: '提交的品名数据中重复,请修改【品名】信息',
              duration: 3000,
            });
          } else { // 无重复数据
            // 获取到表单组件父级，能获取到【表单】所有数据
            tableDatas.unshift({ ...willInsertDatas });

            // 清除备份数据initDatas
            Object.keys(initDatas).forEach((key) => {
              delete initDatas[key];
            });

            /** 高亮最新一行数据  全部禁止编辑
           * 获取取【表单】页的所需数据
           * 1，取消之前高亮
           * 2，通过rowClass函数来设置【表单】中「row-class-name」样式为'rowClassPass'
           */

            config.disabledForm = true; // 全部禁止编辑
            rowClkBus.setCurrent(tableDatas[0]);// 设置最新增加的行高亮
            rowClkBus.currentRow = { ...willInsertDatas };
            rowClkBus.rangeClkId = 0;// 赋值点击行
            rowClkBus.oldRangeClkId = 0;// 赋值点击行

            // 将scorllTop回到第一行
            hsDetailTableRef.$el.children[2].scrollTop = 0;
            hsDetailTableRef.$el.children[2].scrollLeft = 0;

            this.setBtnState();// 修改为【新增|复制|修改|删除】
            ElMessage.success({
              message: '保存至表单成功',
            });
          }
        };

        if (detailsDatas.packageState === 0) { // 不是混装直接将【毛重】与【箱数】传递给混装记录器中mixin*,注释：更改方案为数组，等检验
          detailsDatas.mixinInfo = [];

          // 实例化 验证重复数据
          validIncludesVal(tableDatas, detailsDatas.productName, datas.detailsDatas);
        } else { // 混装状态,返向取值向tableDatas添加值
          // 注：混装minx*的值在上一步下一下的点击操作中处理添加
          for (let i = mixinpackOBJ.length - 1; i > -1; i -= 1) {
            const mixinObj = mixinpackOBJ[i];// 每列数据
            validIncludesVal(tableDatas, mixinObj.productName, mixinObj);
          }
        }
      } else {
        this.createCustomsDatasBus.rowClkBus.setCurrent();
      }
    });
  }

  /** *************  以下为混装函数   ************* */

  /** 点击 返回按钮 至【非混装】操作事宜
       * @param {*} createCustomsDatasBus
       * @param {*} currentState
       *  0，提示警示状态信息
       *  1，回到【是否混装】为 『非混装』状态，开放【箱数】编辑，禁止【混装品类数量】编辑
       */
  mixinBackToInit = () => {
    const p = ElMessageBox.confirm('返回【非混装】状态将会导致你目前【混装】状态下编辑的内容清空,确定要返回吗？', {
      title: '警告提示',
      type: 'warning',
      showClose: false,
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      closeOnClickModal: false, // 点击蒙层取消
      closeOnPressEscape: false, // 禁止按ESC
    });
    p.then((res) => {
      if (res === 'confirm') {
        const {
          datas, stateContent, mixinpackOBJ, tableDatas,
        } = this.createCustomsDatasBus;

        datas.detailsDatas.packageState = 0;// 非混装状态
        this.mixinState();// 【混装品名数量】红点显示与否
        this.baseDatas.packageState.disabledState = false;
        this.baseDatas.moreProductCtns.disabledState = !datas.detailsDatas.packageState;// 还原编辑与否
        stateContent.nextStep = 0;// 还原记录步数数据

        if (mixinpackOBJ.length > 0) {
          for (let i = 0; i < Number(mixinpackOBJ[0].moreProductCtns); i += 1) {
            tableDatas.shift();// 混装状态 因未保存要删除已存入表格中的混装数据
          }
        }
        this.mixinClearFunc();
        this.setBtnState();//  设置按钮信息
      }
    }).catch((err) => {
      if (err === 'cancel') {
        // TODO
      }
    });
  }

  /** 操作【当前进度】备份原有数据同时清空原有数据 */
  backUpStepValue = () => {
    const {
      backupStepDatas, stepAllDatas,
    } = this.createCustomsDatasBus;
    utils.deepCopyDatas(backupStepDatas.backupStepDatas, stepAllDatas.stepDatas);
    backupStepDatas.backupActive = stepAllDatas.active;
    stepAllDatas.stepDatas = [];
  }

  /** 操作【当前进度】添加现有数据
     *
     */
  reSetStepDatas = () => {
    const {
      stepAllDatas, stateContent, datas,
    } = this.createCustomsDatasBus;
    const { moreProductCtns } = datas.detailsDatas;
    // 根据【混装品类数量】定制最大数
    for (let i = 0; i < moreProductCtns; i += 1) {
      stepAllDatas.stepDatas[i] = { title: `第${i + 1}步` };
    }
    stepAllDatas.active = stateContent.nextStep;
  }

  /** 向下获得混装毛重的 总毛重，总净重
       *  开始值：为当前所在行的下一行，
       *  结束值：为范围值内
       * @param {Number} startIdxNode 向上开始索引
       * @param {Number} endIdxNode 向上结束索引
       * @param {Object} opDatasNode 操作的对象
       * @returns {Object} {endIdx, downTotalNet} 结束索引值，向下总净重
       */
  getDownInfos = (startIdxNode, endIdxNode, opDatasNode) => {
    let endIdx = null;// 结束索引值
    let downTotalNet = 0;// 记录向下的总净重

    if (startIdxNode === endIdxNode) { // 只有一条数据
      downTotalNet = 0;
      endIdx = startIdxNode;
    } else {
      for (let i = startIdxNode; i < endIdxNode; i += 1) {
        if (endIdx === null && Number(opDatasNode[i].grossWt) !== 0) {
          endIdx = i;// 结束
          break;
        }
        downTotalNet += Number(opDatasNode[i].netWt);
        if (i === endIdxNode - 1 && Number(opDatasNode[i].grossWt) === 0) { // 最后一个为0
          endIdx = i + 1;// 结束
          break;
        }
      }
    }
    return { endIdx, downTotalNet };
  }

  /** 向上获得混装毛重的 总毛重，总净重
     *  开始值：为当前所在行的下一行，
     *  结束值：为范围值内
     * @param {Number} startIdxNode 向上开始索引
     * @param {Number} endIdxNode 向上结束索引 最小值为-1（越界）
     * @param {Object} opDatasNode 操作的对象
     * @returns {Object} {startIdx, upTotalNet}开始索引值，向上总净重  startIdx： 最小值为-1（越界）
     * 注意：返回信息说明：上一个有值的节点（如遇上是混装毛重，则继续向上获取节点。反之，上是非混装毛重（即有值），返回当前毛重节点)
     *
     */
  getUpInfos = (startIdxNode, endIdxNode, opDatasNode) => {
    let startIdx = null;// 开始索引值
    let upTotalNet = 0;// 设置向上的总净重

    if (startIdxNode === endIdxNode) { // 只有一条数据
      upTotalNet = 0;
      startIdx = endIdxNode;
    } else {
      // 反向运作
      for (let i = startIdxNode; i > endIdxNode; i -= 1) {
        upTotalNet += Number(opDatasNode[i].netWt);// 退出之前，要记录遇到毛重不为0的净重
        if (startIdx === null && (Number(opDatasNode[i].grossWt) !== 0)) {
          // 第一步必须是有值所以为会退出
          startIdx = i;
          break;
        }
      }
    }
    return { startIdx, upTotalNet };
  }

  /** 混装状态向上，向下汇总 开始|结束索引值、记录向上|向下总净重
     * @param {*} createCustomsDatasBus
        * 思路：
        * 毛重如果是0--混装毛重：
        *    向上取到总净重，向下取到总净重，记录开始索引 到 向下结束索引
        *    设置：从开始索引到结束索引
        *          毛重：取向上的，
        *          净重：向上总净重+向下总净重+当前自身总净重
        * 毛重如果非0--毛重：
        *    向上取到总净重 ，向下取到总净重
        *    设置：向下的
        *           毛重：当前的毛重
        *           净重：向下总净重+当前自身总净重
        *         向上的
        *           毛重：向上的毛重
        *           净重：向上总净重
        *
        * 另一种思路：要以索引出毛重有值且离当前最近的两个值来判断，开始与结束（实现)
      */
  mixinNoteInfo = () => {
    const {
      config, mixinpackOBJ, rowClkBus, tableDatas,
    } = this.createCustomsDatasBus;

    /** 先操作向下
     * 结构图:           startIdx --- startCtnIdx --- [startIdx] --- rangeClkId --- endIdx --- endCtnIdx
     * startIdx:        混装毛重 开始 索引值(向上的值有可能会超出startCtnIdx)
     * endIdx:          混装毛重 结束 索引值（向下的值只能在endCtnIdx范围内)
     * downTotalNet:    向 下取 总净重
     * upTotalNet:      向 上取 总净重
     * startCtnIdx      整个批次混装的开始
     * endCtnIdx        整个批次混装的结束
     * @returns {startIdx, endIdx, downTotalNet, upTotalNet, startCtnIdx, endCtnIdx,}
     *  */
    const objLen = mixinpackOBJ.length;
    if (!config.modifyState) { // 非【修改】状态
      const { nextStep } = this.createCustomsDatasBus.stateContent;
      const { endIdx, downTotalNet } = this.getDownInfos(nextStep + 1, objLen, mixinpackOBJ);
      const { startIdx, upTotalNet } = this.getUpInfos(nextStep - 1, -1, mixinpackOBJ);
      // const { moreProductCtns, packageState } = createCustomsDatasBus.datas.detailsDatas;
      return {
        startIdx: startIdx === -1 ? 0 : startIdx, endIdx: endIdx === null ? 0 : endIdx, downTotalNet, upTotalNet, startCtnIdx: null, endCtnIdx: null,
      };
    }
    /** 【修改】状态  计算上下关系值
     * startIdx:      混装毛重 开始 索引值
     * endIdx:        混装毛重 结束 索引值
     * downTotalNet:  向 下取 总净重
     * upTotalNet:    向 上取 总净重
     * startCtnIdx:   当前批次开始索引值
     * endCtnIdx:     当前批次结束索引值
     * @returns {startIdx, endIdx, downTotalNet, upTotalNet, startCtnIdx, endCtnIdx,}
     */
    if (config.modifyState) { // 【修改】状态
      // 先将当前批次的混装组选出来
      const currentMixinAry = (function getTheSameMixinAry(tableDatasNode, rowClkBusNode) {
        const tempAry = [];
        const currentUuid = rowClkBusNode.currentRow.mixinInfo[0];// 有可能取不到uuid,只取到undefined，证明不是混装返回空[]
        if (currentUuid !== undefined) {
          tableDatasNode.forEach((item, idx) => {
            if ((item.mixinInfo.length > 0) && item.mixinInfo[0] === currentUuid) tempAry.push(idx);
          });
        }

        return tempAry;
      }(tableDatas, rowClkBus));
      // 开始与结束索引值（取数组中大小值）注意有可能是空数组（返回当前行索引值）
      const startCtnIdx = currentMixinAry.length === 0 ? rowClkBus.rangeClkId : Math.min(...currentMixinAry); // 当前批次混装开始索引值（当前不是混装时开始也结束为自身）
      const endCtnIdx = currentMixinAry.length === 0 ? rowClkBus.rangeClkId : Math.max(...currentMixinAry); // 当前批次混装结束索引值（当前不是混装时开始也结束为自身）

      if (currentMixinAry.length > 0) {
        const { endIdx, downTotalNet } = this.getDownInfos(rowClkBus.rangeClkId + 1, endCtnIdx + 1, tableDatas);// 向下求值
        const { startIdx, upTotalNet } = this.getUpInfos(rowClkBus.rangeClkId - 1, startCtnIdx - 1, tableDatas);// 向上求值
        return {
          startIdx, endIdx, downTotalNet, upTotalNet, startCtnIdx, endCtnIdx,
        };
      }
      return { // 不是混装
        startIdx: rowClkBus.rangeClkId, endIdx: rowClkBus.rangeClkId + 1, downTotalNet: 0, upTotalNet: 0, startCtnIdx, endCtnIdx,
      };
    }
    // 除以上【修改|混装编辑状态】外的其他返回null
    return null;
  }

  /** 重置混装mixinInfo信息 */
  mixinResetMixinInfo = () => {
    const { nextStateus, nextStep, uuid } = this.createCustomsDatasBus.stateContent;
    const { detailsDatas } = this.createCustomsDatasBus.datas;
    const { ctns, grossWt, netWt } = detailsDatas;

    /** 设置table中数据
       * @param {*} myDatasNode myDatas节点数据
       * @param {*} idx         数据nextStep
       * @param {*} formDatas   表单datas.detailsDatas数据
       */
    const setTableDatas = (createCustomsDatasBusNode, idx) => {
      const tempDatas = { ...createCustomsDatasBusNode.datas.detailsDatas };
      createCustomsDatasBusNode.mixinpackOBJ[nextStep] = { ...createCustomsDatasBusNode.datas.detailsDatas };// 添加数据至mixinpackOBJ中
      if (idx !== 0) { // 非第一步
        tempDatas.ctns = '0';
      }
      createCustomsDatasBusNode.tableDatas[idx] = tempDatas;// 设置table中的数据
      if (createCustomsDatasBusNode.stateContent.nextStateus// 未返回
        && createCustomsDatasBusNode.tableDatas[idx + 1].hsCode// 下一个数据不为空
      ) {
        createCustomsDatasBusNode.rowClkBus.setCurrent(createCustomsDatasBusNode.tableDatas[idx + 1]);// 设置高亮
      }// 设置高亮
      createCustomsDatasBusNode.hsTabaleRef.value.hsDetailTableRef.$refs.bodyWrapper.scrollTop = nextStep * 34 - 2 * 34;// 定位至最新行
    };

    const { mixinpackOBJ, tableDatas } = this.createCustomsDatasBus;
    if (Number(grossWt) === 0 && nextStateus) { // 毛重混装 未返回状态
      const countBeforeObj = mixinpackOBJ[nextStep - 1];// 上一次总和净重
      const totalNet = Number(countBeforeObj.mixinInfo[3]) + Number(netWt);// 当前混装净重总和
      // 将现有的表单中数据修改
      detailsDatas.mixinInfo = [countBeforeObj.mixinInfo[0], Number(countBeforeObj.mixinInfo[1]), Number(countBeforeObj.mixinInfo[2]), totalNet];// 添加混装备份值数组[箱数，毛重，混合毛重的净重之合]
      //  反向操作mixinInfo
      for (let i = nextStep - 1; i > -1; i -= 1) { // nextStep - 1当前操作还没有保存
        mixinpackOBJ[i].mixinInfo[3] = totalNet;
        tableDatas[i].mixinInfo[3] = totalNet;

        if (Number(mixinpackOBJ[i].grossWt) !== 0) {
          break;
        }
      }
    } else if (Number(grossWt) !== 0 && nextStateus) { // 未返回编辑状态，非毛重混装
      detailsDatas.mixinInfo = [uuid, Number(ctns), Number(grossWt), Number(netWt)];// 添加混装备份值数组[标识UID，箱数，毛重，混合毛重的净重之合]
    } else if (!nextStateus) { // 返回编辑状态
      if (// 毛重与净重值不等说明有改动过
        Number(mixinpackOBJ[nextStep].netWt) !== Number(detailsDatas.netWt)
        || Number(mixinpackOBJ[nextStep].grossWt) !== Number(detailsDatas.grossWt)
      ) {
        const mixinNoteInfoRes = this.mixinNoteInfo();

        const {
          startIdx, endIdx, downTotalNet, upTotalNet,
        } = mixinNoteInfoRes !== null && mixinNoteInfoRes;

        // 根据毛重是否为混装毛重来进行设置mixinInfo
        if (Number(grossWt) === 0) { // 混装毛重
          const totalGWT = Number(mixinpackOBJ[startIdx].grossWt);
          const totalNWT = upTotalNet + downTotalNet + Number(netWt);
          for (let i = startIdx; i < endIdx; i += 1) {
            // 箱数不设置，跟之前相同
            mixinpackOBJ[i].mixinInfo[2] = totalGWT;// 毛重
            mixinpackOBJ[i].mixinInfo[3] = totalNWT;// 总净重
            tableDatas[i].mixinInfo[2] = totalGWT;// 毛重
            tableDatas[i].mixinInfo[3] = totalNWT;// 总净重
          }
        } else { // 非混装毛重
          const startObj = this.createCustomsDatasBus.mixinpackOBJ;
          const upMixinInfo = [startObj[startIdx].mixinInfo[0], startObj[startIdx].mixinInfo[1], Number(startObj[startIdx].grossWt), upTotalNet];
          const downMixinInfo = [startObj[startIdx].mixinInfo[0], startObj[nextStep].mixinInfo[1], Number(grossWt), downTotalNet + Number(netWt)];
          // 设置向上值
          startObj.slice(startIdx, nextStep).forEach((_, idx) => {
            mixinpackOBJ[startIdx + idx].mixinInfo = upMixinInfo;
            tableDatas[startIdx + idx].mixinInfo = upMixinInfo;
          });
          // 设置向下值
          startObj.slice(nextStep, endIdx).forEach((_, idx) => {
            mixinpackOBJ[nextStep + idx].mixinInfo = downMixinInfo;
            tableDatas[nextStep + idx].mixinInfo = downMixinInfo;
          });
          detailsDatas.mixinInfo = downMixinInfo;
        }
      }
    }
    setTableDatas(this.createCustomsDatasBus, nextStep);
  }

  /** 【毛重】【净重】toolTip混装提示信息
       * @param {*} baseDatasNode         baseDatas数据
       * @param {*} validName             校验字符串名 即哪个值[netWt,grossWt]
       */
  toolTipInfoOfNetAndGw = () => {
    const {
      mixinpackOBJ, stateContent, datas, config,
    } = this.createCustomsDatasBus;
    const { nextStateus, nextStep } = stateContent;
    const {
      grossWt, packageState, netWt,
    } = datas.detailsDatas;
    if (Number(packageState) === 1) { // 混装
      // 计算当前的mixinInfo最近开始与结束值

      const mixinNoteInfoRes = this.mixinNoteInfo();
      const {
        startIdx, downTotalNet, upTotalNet, startCtnIdx,
      } = mixinNoteInfoRes !== null && mixinNoteInfoRes;

      const avlidIsNan = (value) => (Number.isNaN(Number(value)) ? 0 : Number(value));// 校验 是否为输出其他不相关的数据

      /** 设置毛重不为混装时的提示信息
       * @param {Object} baseDatasNodes       baseDatas数据
       * @param {Number} downTotalNetNode     当前混装毛重下的总净重
       * @param {Number} grossWtNode          当前输入毛重
       * @param {Object} opRowDatas           需要操作的当前对象
       * @param {Bool} isBackEdit             向哪个节点操作提示信息，true 非返回编辑(默认) ,false 返回编辑|修改编辑状态
       */
      const toolTipOfhaveGw = (baseDatasNodes, downTotalNetNode, grossWtNode, opRowDatas, isBackEdit = true) => {
        const tlNW = avlidIsNan(downTotalNetNode).toFixed(2);
        const tlGW = avlidIsNan(grossWtNode).toFixed(2);
        const oldNetWtObj = opRowDatas === undefined ? {} : opRowDatas;
        const oldNet = Object.prototype.hasOwnProperty.call(oldNetWtObj, 'netWt') ? oldNetWtObj.netWt : 0;
        const gwShowNet = avlidIsNan(Number(tlNW) + Number(netWt)).toFixed(2);
        let subRes = tlGW - tlNW;
        subRes = subRes < 1 ? `请录入总净重<总毛重${Number(grossWt).toFixed(2)}KGS` : `当前录入净重需 <${subRes.toFixed(2)}KGS`;
        let subOneself = tlGW - tlNW - oldNet;
        subOneself = subOneself < 1 ? `请录入总净重<总毛重${Number(grossWt).toFixed(2)}KGS` : `当前录入净重需 <${subOneself.toFixed(2)}KGS`;

        if (isBackEdit) { // 非返回编辑
          baseDatasNodes.netWt.tooltip = `非返回编辑3: 混装总毛重：${tlGW}KGS,${subOneself}`;
          baseDatasNodes.grossWt.tooltip = `非返回编辑3: 录入毛重时值需 > ${gwShowNet}KGS`;
        } else {
          baseDatasNodes.netWt.tooltip = `${Number(tlNW) !== 0
            ? `返回编辑3: 录混装总净重重（未含当前净重）:${tlNW}KGS,`
            : ''
            } 混装总毛重：${tlGW}KGS, ${subRes}`;
          baseDatasNodes.grossWt.tooltip = ` 返回编辑3: 已录混装总净重：${gwShowNet}KGS,录入毛重需 > ${gwShowNet}KGS`;
        }
      };
      /**  毛重为混装时的提示信息
       * @param {Object} basedatasNodes     baseDatas基础数据
       * @param {Number} upTotalNetNode     向上取净重总和（不含自身）
       * @param {Number} downTotalNetNode   向下取净重总和（不含自身）
       * @param {Number} stepNum            当前步骤
       * @param {Number} startCtnNum        混装起始步骤
       * @param {String} uuid               当前混批的UUID
       * @param {Object} opDatasNode        操作的对象
       * @param {Bool} isBackEdit           向哪个节点操作提示信息，true 非返回编辑(默认) ,false 返回编辑|修改编辑状态
       */
      const toolTipOfMixinGw = (basedatasNodes, upTotalNetNode, downTotalNetNode, stepNum, startCtnNum, isBackEdit = true) => {
        // 以防第一步没有mixinInfo
        const tbDatas = this.createCustomsDatasBus.tableDatas;
        const tbLen = tbDatas.length;
        const tlGW = tbLen === 0 ? 0 : Number((this.createCustomsDatasBus.tableDatas[startIdx === -1 ? 0 : startIdx].mixinInfo[2])).toFixed(2);// 总毛重
        const tlNW = tbLen === 0 ? 0 : upTotalNetNode + downTotalNetNode;// 总净重
        const gwShowNet = Number(avlidIsNan(Number(tlNW) + Number(netWt))).toFixed(2);
        let subRes = tlGW - tlNW;

        subRes = subRes < 1 ? '录入混装总净重需< 混装总毛重' : `当前净重需< ${subRes.toFixed(2)}KGS`;

        if (isBackEdit) { // 非返回编辑
          basedatasNodes.netWt.tooltip = `非返回编辑2: 已录混装总毛重：${tlGW}KGS, ${subRes}`;
          basedatasNodes.grossWt.tooltip = `非返回编辑2: 已录混装总净重：${gwShowNet}KGS ,${stepNum === startCtnNum
            ? `录入毛重时值需 > ${(Number(tlNW) + Number(netWt)).toFixed(2)}KGS`
            : `已录混装毛重${tlGW}KGS`}`;
        } else { // 返回编辑
          basedatasNodes.netWt.tooltip = `返回编辑2: 录混装总净重重（未含当前净重）:${tlNW}KGS, ${(
            (startIdx === -1 || (startCtnIdx === null && this.createCustomsDatasBus.rowClkBus.rangeClkId === 0)) && (Number(grossWt) === 0)// 修改状态时的第一行为0，及混装编辑时的第一行
          )// 第一行索引值为0
            ? `混装总毛重：${Number(grossWt)}KGS,录入混装总净重需< 混装总毛重`
            : `混装总毛重：${tlGW}KGS,${subRes}`
            }`;
          basedatasNodes.grossWt.tooltip = `返回编辑2: 已录混装总净重:${gwShowNet}KGS ,${stepNum === startCtnNum
            ? `录入毛重需 > ${(tlNW + Number(netWt)).toFixed(2)}KGS`
            : `已录混装毛重${tlGW}KGS`}`;
        }
      };

      if (!config.modifyState) { // 非修改编辑状态下
        if (Number(grossWt) !== 0) { // 毛重有值，只计算向下值
          toolTipOfhaveGw(this.baseDatas, downTotalNet, Number(grossWt), mixinpackOBJ[nextStep], !!nextStateus);
        } else { // 毛重混装 无值
          toolTipOfMixinGw(this.baseDatas, upTotalNet, downTotalNet, nextStep, 0, !!nextStateus);
        }
      } else { // 修改编辑状态下
        const { rowClkBus } = this.createCustomsDatasBus;
        if (Number(grossWt) !== 0) { // 毛重有值，只计算向下值
          toolTipOfhaveGw(this.baseDatas, downTotalNet, Number(grossWt), rowClkBus.currentRow, false);
        } else { // 毛重混装
          toolTipOfMixinGw(this.baseDatas, upTotalNet, downTotalNet, rowClkBus.rangeClkId, startCtnIdx, false);
        }
      }
    } else { // 非混装
      this.baseDatas.netWt.tooltip = '本产品净重';
      this.baseDatas.grossWt.tooltip = '本产品毛重';
    }
  }

  /** 【上一步】操作后按钮toolTip信息
     * @param {*} createCustomsDatasBus
     */
  mixinPrevToolTipState = () => {
    const { stateContent, datas } = this.createCustomsDatasBus;
    const { nextStep } = stateContent;
    if (nextStep) { // 非第一步
      stateContent.prevTooltip = `退回至第${nextStep}步编辑的混装内容`;
      stateContent.nextTooltip = `剩余${Number(datas.detailsDatas.moreProductCtns) - (nextStep + 1)}个品名未录入`;
    } else { // 第一步
      stateContent.nextStep = 0;
      stateContent.prevTooltip = '返回至【非混装】状态';
      stateContent.nextTooltip = `剩余${Number(datas.detailsDatas.moreProductCtns) - (nextStep + 1)}个品名未录入`;
    }
  }

  /** mixinPrevItemSetState 【上一步】设置状态
     * 设置信息：
     *    更改nextStep递减
     *    将mixinpackObj的值更改为表单detailDatas
     *    高雷按钮信息
     *    设置高亮
     *    设置scroll值
     */
  mixinPrevItemSetState = () => {
    const {
      stateContent, datas, mixinpackOBJ, tableDatas, rowClkBus, hsTabaleRef,
      stepAllDatas,
    } = this.createCustomsDatasBus;

    if (stateContent.nextStep > 0) { // 只有大于0才执行如下代码
      stateContent.nextStep -= 1;// 设置nextStep值
      rowClkBus.oldRangeClkId = rowClkBus.rangeClkId === null ? 0 : rowClkBus.rangeClkId;
      rowClkBus.rangeClkId = stateContent.nextStep;
      const upStepData = { ...mixinpackOBJ[stateContent.nextStep] };
      datas.detailsDatas = upStepData;// 返回上一层数据

      // 设置【法定单位】
      const res = this.setUnitOptions(upStepData.unitJointStr); // 初始化法定单位
      if (res) {
        this.baseDatas.unit.selectOptions = [...res];// 设置渲染数据
      }
      // 设置上一步的
      this.setBtnState(); // 修改为【上一步|下一步】或【返回|下一步】
      rowClkBus.setCurrent(tableDatas[stateContent.nextStep]);// 设置高亮
      hsTabaleRef.value.hsDetailTableRef.$refs.bodyWrapper.scrollTop = stateContent.nextStep * 34 - 2 * 34;// 定位至最新行
    } else if (stateContent.nextStep === 0) { // 第一步
    }
    this.validateAllFileds(['netWt', 'grossWt'], false).then((isError) => {
      if (!isError) {
        this.toolTipInfoOfNetAndGw();
        stepAllDatas.active = stateContent.nextStep;
      }
    });
    this.mixinPrevToolTipState();// 操作后按钮toolTip信息
  }

  /** 准备下一步的数据
     * @param {*} createCustomsDatasBus
     * @param {*} baseDatas
     * 保留【是否混装】【混装品类数量】【箱数】，其他清空
     */
  mixinChangeNextFormDatas = () => {
    const {
      datas, stateContent, mixinpackOBJ, tableDatas, rowClkBus, hsTabaleRef,
      weightFormRef,
    } = this.createCustomsDatasBus;
    if (stateContent.nextStateus) { // 必须是未返回才清空
      rowClkBus.setCurrent();// 取消全部高亮
      Object.keys(datas.detailsDatas).forEach((key) => {
        if (!['packageState', 'moreProductCtns', 'ctns'].includes(key)) {
          if (key === 'mixinInfo') { // 新数组
            datas.detailsDatas.mixinInfo = [];
          } else { // 清空
            // 测试数据
            // datas.detailsDatas.productName = `全棉普梳纱${Number(stateContent.nextStep)}  1|0|粗梳|单纱|非零售|100%棉|97分特|自主品牌|环锭纺|2022-05-11|||other`;

            // 不要删除
            datas.detailsDatas[key] = '';
            // 删除法定单位
            this.baseDatas.unit.selectOptions = [];
            nextTick(() => {
              weightFormRef.value.clearValidate();
            });
          }
        }
      });
    } else { // 已返回[下一步]
      const nextIdx = Number(stateContent.nextStep);
      if (mixinpackOBJ[nextIdx]) datas.detailsDatas = { ...mixinpackOBJ[nextIdx] };

      if (tableDatas[nextIdx].hsCode !== '') {
        rowClkBus.setCurrent(tableDatas[nextIdx]); // 设置高亮
      } else {
        rowClkBus.setCurrent();// 取消全部高亮
      }
      hsTabaleRef.value.hsDetailTableRef.$refs.bodyWrapper.scrollTop = stateContent.nextStep * 34 - 2 * 34;// 定位至最新行
    }
  }

  /** 更改下一步按钮信息
     * @param {*} createCustomsDatasBus
     * @param {*} baseDatas
     */
  mixinChangeNextBtnState = () => {
    const { stateContent, rowClkBus, datas } = this.createCustomsDatasBus;
    const { moreProductCtns } = datas.detailsDatas;
    const { nextStep } = stateContent;
    let nextStepToNum = Number(nextStep);
    const moreProductCtnsToNUm = Number(moreProductCtns) - 1;
    // 设置 下一步按钮显示内容 及 toolTip提示内容
    if (nextStepToNum < moreProductCtnsToNUm) {
      stateContent.nextStep += 1;// 在混装数量范围内添加
      // 修改点击按钮值
      rowClkBus.oldRangeClkId = rowClkBus.rangeClkId === null ? 0 : rowClkBus.rangeClkId;
      rowClkBus.rangeClkId = stateContent.nextStep;
    }
    nextStepToNum = Number(stateContent.nextStep);// 重新确定当前值
    if (nextStepToNum === moreProductCtnsToNUm) { // 最后一个
      stateContent.nextTooltip = '即将录入完成，点击【全部保存】保存本批次混装数据';
      this.setBtnState();// 【上一步|全部保存步】
    } else { // 未到最后一个
      stateContent.nextTooltip = `剩余${moreProductCtnsToNUm - nextStepToNum}个品名未录入`;
      this.setBtnState();// 【上一步|下一步】
    }
    stateContent.prevTooltip = `退回至第${nextStepToNum}步编辑的混装内容`;

    this.baseDatas.netWt.tooltip = '本产品净重';
    this.baseDatas.grossWt.tooltip = '本产品毛重';
    this.mixinChangeNextFormDatas();// 为下一步数据作准备（清空）
    this.toolTipInfoOfNetAndGw();
  }

  /** 根据类型设置向上向下全通道来设置tableDatas中的值
   * @param {*} createCustomsDatasBus
   * @param {*} baseDatas
   * @param {String} modelStr [up,down,all] up向上设置，down向下设置，all全通设置（包含了向上向下）
   */

  modifyDatasOfModel = (mixinNoteInfoRes, modelStr) => {
    if (!['up', 'down', 'all'].includes(modelStr)) {
      throw new Error('请输入正确的模式文字');
    }
    const {
      startIdx, endIdx, downTotalNet, upTotalNet, startCtnIdx, endCtnIdx,
    } = mixinNoteInfoRes !== null && mixinNoteInfoRes;
    //   '\r\nstartCtnIdx:', startCtnIdx,
    //   '\r\nendCtnIdx:', endCtnIdx,
    //   '\r\n startIdx:', startIdx,
    //   '\r\n endIdx:', endIdx,
    //   '\r\n downTotalNet:', downTotalNet,
    //   '\r\n upTotalNet:', upTotalNet,
    // );
    const {
      tableDatas, rowClkBus, datas, config,
    } = this.createCustomsDatasBus;
    const {
      ctns, netWt, mixinInfo, grossWt,
    } = datas.detailsDatas;
    const copyCtns = ctns.toString();
    const clkIdx = rowClkBus.rangeClkId;

    const copyGrossWt = tableDatas[startIdx === -1 ? 0 : startIdx].grossWt.toString();
    const copyDownGrossWt = grossWt.toString();

    const copyNetWt = netWt.toString();

    const tbMixinInfo = tableDatas[startCtnIdx].mixinInfo;
    const tempStartIdx = startIdx === -1 ? 0 : startIdx;

    const mixinUpAry = [tbMixinInfo[0], Number(copyCtns), Number(tableDatas[tempStartIdx].grossWt), upTotalNet];// [标记ID,箱数,毛重,混合毛重的净重之合]
    const mixinAllAry = [mixinInfo[0], Number(copyCtns), Number(copyGrossWt), upTotalNet + downTotalNet + Number(copyNetWt)];// [标记ID,箱数,毛重,混合毛重的净重之合]
    const mixinDownAry = [tbMixinInfo[0], Number(copyCtns), Number(copyDownGrossWt), downTotalNet + Number(copyNetWt)];// [标记ID,箱数,毛重,混合毛重的净重之合]

    Object.assign(tableDatas[rowClkBus.rangeClkId], { ...datas.detailsDatas });
    /*
        * 自身是非毛重混装：
        *     设置向上的值:
        *         注意事项：
        *           1，向上遇到当前混装毛重开始值为-1，说明索引值为0，第一行。向上不执行
        *           2，向上全部设置箱数，因为混装毛重第一行必须填写具体的值，方便后面合并
        *           3，向上有可能不是当前混装毛重批次的，只能修改其mixinInfo中的箱数。而三元素保持其原有值。
        *           4，向上的混装毛重批次的，修改其以混装毛重第一个值作为基准，修改其后面的所有值
        *
        *     设置向下的值：
        *         注意事项：
        *           1，向下其实包含自身到结束值。
        *           2，向下总批次混装的，先修改mixinInfo中的箱数，勿动，可能出现其他批次的混装毛重
        *           3，向下的混装毛重批次的，先将所有箱数、毛重修改其为0，为了后面合并体现。
        *           4，设置混装毛重批次要注意，有可也是索引值为0的头部行，如果是索引0的头部行，需要将箱数设置为有值，而毛重是必须要设置所以无要求。
        */
    switch (modelStr) {
      case 'up':
        /** 修改向上的数据
               * 从当前混装批次开始到操作行前结束
               *    向上可能会是有多个混装毛重和非混装毛重混合掺杂出现
               *    全部修改箱数，均为统一
               *    离最近的上一个混装（可以是混装毛重）修改其mixinInfo数据，其他其他没有改变
               *    将头部行设置对应数据
               */
        if (startIdx !== -1 && tableDatas[startIdx === -1 ? 0 : startIdx].mixinInfo.length !== 0) { // 头部越界或上一个为非混装内容，无需执行向上，减少运行速度
          const copyUPGrossWt = tableDatas[tempStartIdx === -1 ? '0' : tempStartIdx].grossWt.toString();
          for (let i = startCtnIdx; i < clkIdx; i += 1) {
            tableDatas[i].mixinInfo[1] = Number(copyCtns); // 只改箱数
            if (i >= tempStartIdx && i < clkIdx) {
              tableDatas[i].mixinInfo = mixinUpAry;
            }
          }
          tableDatas[startCtnIdx].ctns = copyCtns;
          tableDatas[tempStartIdx].grossWt = copyUPGrossWt;
        }
        break;
      case 'down':
        /** 向下设置 * */
        for (let i = clkIdx; i < endCtnIdx + 1; i += 1) {
          tableDatas[i].mixinInfo[1] = Number(copyCtns);
          if (i >= clkIdx && i < endIdx) {
            tableDatas[i].ctns = '0';
            tableDatas[i].grossWt = '0';
            tableDatas[i].mixinInfo = mixinDownAry;
          }
        }

        tableDatas[clkIdx].grossWt = copyDownGrossWt;
        tableDatas[clkIdx].netWt = copyNetWt;
        tableDatas[clkIdx].ctns = (clkIdx === startCtnIdx) ? copyCtns : '0';// 第一行头部文件则填写箱数
        datas.detailsDatas.mixinInfo = mixinDownAry;
        break;
      case 'all':
        // 以下修改mixinInfo中的数据[总混装批次、混装毛重批次]
        for (let i = startCtnIdx; i < endCtnIdx + 1; i += 1) { // 修改箱数
          tableDatas[i].ctns = '0';
          tableDatas[i].mixinInfo[1] = Number(copyCtns);// 不在当前混批毛重的只改箱数
          if (i >= startIdx && i < endIdx) { // 取混装毛重范围内 因自身毛重为混装0值，startIdx起始值为遇到有毛重的那个索引开始，结束是自身的下一个
            tableDatas[i].grossWt = '0';// 先清空数据，因是合并项
            tableDatas[i].mixinInfo = mixinAllAry;// 修改此批次混装毛重的mixinInfo
          }
        }
        // 单向操作，只操作对应的一行[毛重、箱数、净重] 再设置头部数据作合并前的头条
        tableDatas[startCtnIdx].ctns = copyCtns; // 修改当前批次混装开始箱数
        tableDatas[startIdx].grossWt = copyGrossWt;// 修改混装毛重第一个毛重值
        tableDatas[rowClkBus.rangeClkId].netWt = copyNetWt;// 修改当前操作行中的净重
        break;
      default:
        break;
    }
    // 设置按钮信息
    config.disabledForm = true;
    this.setBtnState();
    rowClkBus.currentRow = { ...tableDatas[rowClkBus.rangeClkId] };
  }

  /** 修改状态下数据保存至表格数据中
   *
   * @param {*} createCustomsDatasBus
   */
  modifySavetoTableDatas = () => {
    const {
      datas, initDatas, config, tableDatas,
      rowClkBus,
    } = this.createCustomsDatasBus;

    // 先校验数据正确性
    this.validateAllFileds(this.validateAry(), true).then((isError) => {
      if (!isError) {
        // initData备份数据与现数据对比（主要是毛重、箱数、净重），有改动则修改mixinInfo数据
        if (config.currentBtnState === 'modifyStateSave') { // 有改动
          const {
            grossWt,
            // netWt, ctns, mixinInfo,
          } = datas.detailsDatas;
          const mixinNoteInfoRes = this.mixinNoteInfo();
          const {
            // startIdx, endIdx, downTotalNet, upTotalNet,endCtnIdx
            startCtnIdx,
          } = mixinNoteInfoRes !== null && mixinNoteInfoRes;

          const isCGTChange = ['ctns', 'grossWt', 'netWt'].some((idx) => Number(initDatas[idx]) !== Number(datas.detailsDatas[idx]));// 判断是否更改的三元素
          const clkIdx = rowClkBus.rangeClkId;

          const setBtnStyleOfTable = (myDatasNode) => {
            // 设置按钮信息
            myDatasNode.config.disabledForm = true;
            this.setBtnState();
            myDatasNode.rowClkBus.currentRow = { ...myDatasNode.tableDatas[myDatasNode.rowClkBus.rangeClkId] };
          };

          if (Number(grossWt) !== 0 && tableDatas[rowClkBus.rangeClkId].mixinInfo.length === 0) { // 非混装.毛重有值
            Object.assign(tableDatas[rowClkBus.rangeClkId], { ...datas.detailsDatas });
            setBtnStyleOfTable(this.createCustomsDatasBus);
          } else if (isCGTChange && Number(grossWt) === 0) { // 改动三元素且毛重混装
            try {
              this.modifyDatasOfModel(mixinNoteInfoRes, 'all');
              datas.detailsDatas.grossWt = tableDatas[rowClkBus.rangeClkId].mixinInfo[2].toString();
            } catch (err) {
              console.error(err);
            }
          } else if (isCGTChange && Number(grossWt) !== 0) { // 改动三无素且毛重非混装
            if (tableDatas[clkIdx].mixinInfo.length !== 0) { // 排除向上操作是越界或上一个是非混装内容
              try {
                this.modifyDatasOfModel(mixinNoteInfoRes, 'up');
                this.modifyDatasOfModel(mixinNoteInfoRes, 'down');
                datas.detailsDatas.grossWt = tableDatas[rowClkBus.rangeClkId].mixinInfo[2].toString();
              } catch (err) {
                console.error(err);
              }
            }
          } else if (!isCGTChange) { // 不是改动三元素
            // 判断是不是头部行，因为后续后ctns，grossWt是0
            const isFirstRow = rowClkBus.rangeClkId === Number(startCtnIdx);
            const tempDetailsDatas = { ...datas.detailsDatas };
            tempDetailsDatas.ctns = '0';
            Object.assign(tableDatas[rowClkBus.rangeClkId], isFirstRow ? { ...datas.detailsDatas } : tempDetailsDatas);// 不是第一步值箱数改成0
            setBtnStyleOfTable(this.createCustomsDatasBus);// 设置按钮信息
            datas.detailsDatas.grossWt = tableDatas[rowClkBus.rangeClkId].mixinInfo[2].toString();
          } else {
            console.error('改动其他元素');
          }
        }
      }
    });
  }

  /** 修改非混装数据保存至表格数据中 */
  saveModifyDatasToTable = () => {
    // 先校验数据正确性
    const {
      rowClkBus, tableDatas, datas, config,
    } = this.createCustomsDatasBus;
    const idx = rowClkBus.rangeClkId;
    this.validateAllFileds(this.validateAry(), false).then((isError) => {
      if (!isError) { // 没错误
        Object.assign(tableDatas[idx], { ...datas.detailsDatas });
        rowClkBus.setCurrent(tableDatas[rowClkBus.oldRangeClkId]);// 高亮新点击行
        config.disabledForm = true;
        this.setBtnState();
        this.insertToFormData({ ...tableDatas[rowClkBus.oldRangeClkId] });// 将数据放入表单临时数据中

        rowClkBus.rangeClkId = rowClkBus.oldRangeClkId;
        rowClkBus.oldRangeClkId = rowClkBus.rangeClkId;
        rowClkBus.currentRow = { ...tableDatas[rowClkBus.rangeClkId] };

        this.mixinInfoToFormDatas();// 混装情况下根据datas.detailsDatas.mixinInfo中的数据填充至formDatas中
      } else {
        rowClkBus.setCurrent(tableDatas[idx]);// 高亮修改行
      }
    });
  }

  /** 点击【表格】中行【产品信息】栏响应事件
     * @param {Object} createCustomsDatasBus 存储数据
    //  * @param {Object} baseDatas 渲染数据
     * 状态值:
     * editStateAdd:【取消|重置】
     * editStateSave:【保存|重置】
     * tableStateShow:【新增|复制|修改|删除】
     * mixinSave:【上一步|全部保存步】
     * mixinEdite:【上一步|下一步】
     * mixinInit:【返回|下一步
     */
  clkRowFormState = () => {
    const {
      rowClkBus, config,
      tableDatas,
      datas,
      mixinpackOBJ,
    } = this.createCustomsDatasBus;
    const {
      currentBtnState,
      // rangeClkId,
    } = rowClkBus;

    // 先备份之前的 行号
    const oldRangeClkId = rowClkBus.oldRangeClkId === null ? rowClkBus.rangeClkId : rowClkBus.oldRangeClkId;

    const setRowLightFunc = (myDatasNode, baseDatasNode, opRowIdx) => { // 设置高亮行同时添加显示数据
      myDatasNode.config.disabledForm = true;// 变成不可编辑状态
      this.setBtnState();// 【新增|复制|修改|删除】
      myDatasNode.rowClkBus.setCurrent(myDatasNode.tableDatas[opRowIdx]);
      this.insertToFormData(myDatasNode.tableDatas[opRowIdx]);
    };
    switch (currentBtnState) {
      case 'editStateAdd':// 【取消|重置】
      case 'tableStateShow':// 【新增|复制|修改|删除】

        config.disabledForm = true;// 变成不可编辑状态
        this.insertToFormData(tableDatas[rowClkBus.rangeClkId]);
        // 【产品信息】栏显示上一次点击行的信息
        this.setBtnState();// 会根据不可编辑状态来添加按钮【新增|复制|修改|删除】
        break;
      case 'mixinInit':// 【返回|下一步】
      case 'mixinEdite':// 【上一步|下一步】
      case 'mixinSave':// 【上一步|全部保存】
        ElMessageBox.confirm(`当前处于${datas.detailsDatas.packageState !== 1
          ? '编辑状态'
          : '混装编辑状态'
          },是否需要保存再退出？`, {
          title: '警告',
          type: 'warning',
          cancelButtonText: '退出编辑',
          confirmButtonText: `${datas.detailsDatas.packageState !== 1 ? '保存' : '返回编辑'}`,
          closeOnClickModal: false, // 点击遮罩关闭
          closeOnPressEscape: false, // ESC 键关闭
          showClose: false, // 关闭右上角x
        }).then((res) => { // 返回编辑
          if (res === 'confirm') {
            rowClkBus.rangeClkId = oldRangeClkId;
            const R = tableDatas[rowClkBus.rangeClkId];
            rowClkBus.setCurrent((R.hsCode === '' || mixinpackOBJ.length === 0) ? '' : R);// 高亮时注意是空值（编辑中），空值则不高亮，反之高亮（因为是返回编辑状态)
            this.createCustomsDatasBus.hsTabaleRef.value.hsDetailTableRef.$refs.bodyWrapper.scrollTop = oldRangeClkId * 34 - 2 * 34;// 定位
          }
        }).catch((err) => { // 退出编辑
          if (err === 'cancel') {
            const rowIdx = rowClkBus.rangeClkId;
            const mixinObjLen = mixinpackOBJ.length;
            if (mixinObjLen > 0) { // 需判断是否添加过值，nextStep为1才添加值
              const moreProductCtnsTemp = datas.detailsDatas.moreProductCtns;
              // 设置高亮色及内容
              //  点击为table中添加的临时数据则退出编辑后显示原数据第1个，反之显示点击行
              setRowLightFunc(this.createCustomsDatasBus, this.baseDatas, rowIdx < moreProductCtnsTemp ? moreProductCtnsTemp : rowIdx);
              // 删除表格数据中的临时数据
              for (let i = 0; i < Number(moreProductCtnsTemp); i += 1) {
                tableDatas.shift();// 混装状态 因未保存要删除已存入表格中的混装数据
              }
              this.createCustomsDatasBus.hsTabaleRef.value.hsDetailTableRef.$refs.bodyWrapper.scrollTop = (
                rowIdx < moreProductCtnsTemp
                  ? 0 // 点击混装表单内的数据，因要删除不保存，返回0
                  : (rowIdx - moreProductCtnsTemp) * 34 - 2 * 34// 删除了表格中临时的数据，则需要减之前混装数据
              );// 定位
            } else { // 未添加过值
              setRowLightFunc(this.createCustomsDatasBus, this.baseDatas, rowIdx);
            }
            this.mixinClearFunc();// 混装退出后清除数据
          }
        });
        break;
      case 'editStateSave':// [保存|重置]
        ElMessageBox.confirm(`当前处于${datas.detailsDatas.packageState !== 1
          ? '编辑状态'
          : '混装编辑状态'
          },是否需要保存再退出？`, {
          title: '警告',
          type: 'warning',
          cancelButtonText: '退出编辑',
          confirmButtonText: `${datas.detailsDatas.packageState !== 1 ? '保存' : '返回编辑'}`,
          closeOnClickModal: false, // 点击遮罩关闭
          closeOnPressEscape: false, // ESC 键关闭
          showClose: false, // 关闭右上角x
        }).then((res) => { // 保存数据
          if (res === 'confirm') {
            this.saveItemBeforeFunc();
          }
        }).catch((err) => { // 退出编辑
          if (err === 'cancel') {
            setRowLightFunc(this.createCustomsDatasBus, this.baseDatas, rowClkBus.rangeClkId);
          }
        });
        break;
      case 'modifyStateAdd':// 【取消|重置】
        config.disabledForm = true;// 变成不可编辑状态
        this.setBtnState();// 会根据不可编辑状态来添加按钮【新增|复制|修改|删除】
        break;
      case 'modifyStateSave':// 【保存|重置】

        ElMessageBox.confirm(`当前处于${datas.detailsDatas.packageState !== 1
          ? '编辑状态'
          : '混装编辑状态'
          },是否需要保存再退出？`, {
          title: '警告',
          type: 'warning',
          cancelButtonText: '退出编辑',
          confirmButtonText: `${datas.detailsDatas.packageState !== 1 ? '保存' : '返回编辑'}`,
          closeOnClickModal: false, // 点击遮罩关闭
          closeOnPressEscape: false, // ESC 键关闭
          showClose: false, // 关闭右上角x
        }).then((res) => { // 返回编辑
          if (res === 'confirm') {
            if (datas.detailsDatas.packageState) { // 混装
              rowClkBus.setCurrent(tableDatas[oldRangeClkId]);
              rowClkBus.rangeClkId = oldRangeClkId;
              rowClkBus.oldRangeClkId = rowClkBus.rangeClkId;
              this.createCustomsDatasBus.hsTabaleRef.value.hsDetailTableRef.$refs.bodyWrapper.scrollTop = rowClkBus.rangeClkId * 34 - 2 * 34;// 定位到之前编辑的行
            } else { // 非混装
              // 以防点击其他行，提示保存按钮
              rowClkBus.oldRangeClkId = rowClkBus.rangeClkId;
              rowClkBus.rangeClkId = oldRangeClkId;
              rowClkBus.currentRow = { ...tableDatas[oldRangeClkId] };
              // 保存数据
              this.saveModifyDatasToTable();
            }
          }
        }).catch((err) => { // 退出编辑
          if (err === 'cancel') {
            setRowLightFunc(this.createCustomsDatasBus, this.baseDatas, rowClkBus.rangeClkId);
            rowClkBus.oldRangeClkId = rowClkBus.rangeClkId;
          }
        });
        break;
      default: // 【保存|重置】状态 需要提示保存信息
        break;
    }

    if (config.disabledForm) { // 不可编辑再不需要作如下校验
      this.baseDatas.netWt.tooltip = '本产品净重';
      this.baseDatas.grossWt.tooltip = '本产品毛重';
    }
  }
}
