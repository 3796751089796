<template>
  <div class="tradeinfo">
    <el-form ref="tradeInfoFormRef" :model="tradeDatas" :rules="tradeRules" label-width="120px" class="demo-ruleForm">
      <el-form-item v-for="item, index in baseDatas" :key="index" :label="item.label" :prop="index"
        :required="item.required" :class="item.myclass ? 'myclass' : ''">
        <!-- 选择器 -->
        <el-select v-if="item.selectOptions" v-model="tradeDatas[index]" :disabled="item.disabled"
          :placeholder="item.placeholder" size="small" :id="index" filterable default-first-option
          @change="item.optionChangeFuncs" @visible-change="item.vsbChangeOptions" @input="item.inputFunc">
          <el-option v-for="option in item.selectOptions" :key="option.value" :label="option.tagName"
            :value="option.value"></el-option>
        </el-select>
        <!-- 输入框-->
        <el-input v-else size="small" :placeholder="item.placeholder" v-model="tradeDatas[index]" clearable></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import {
  reactive,
  toRefs,
  onBeforeMount,
  onMounted,
  inject,
  ref,
  nextTick,
  // watch,
} from 'vue';
import SelectoptionsFormClass from '../../utils/selectOfqueryFormClass';
// import saveAllfuncBus from './utlis/saveAllfuncBus';
import origin from '../../configs/origin'; // 境内货源地数组
import country from '../../configs/country'; // 目的地国家数组
import rulesClass from '../../utils/utilsClass/rulesClass';

export default {
  name: 'tradeinfo',
  setup() {
    // $refs集合
    const tradeInfoFormRef = ref(null);
    const selectoptionsFormClass = new SelectoptionsFormClass();
    const inputval = ref(''); // 临时存放输入数据

    const createCustomsDatasBus = inject('createCustomsDatasBus');
    // 验证信息
    const tradeRules = reactive({
      contractNum: [rulesClass.VerifyTradInfo],
      invoiceNum: [rulesClass.VerifyTradInfo],
      productOrigin: [rulesClass.VerifyEmptyRules],
      destCountry: [rulesClass.VerifyEmptyRules],
    });
    const {
      // saveDatasFuncsBus, // 父组件调用子组件函数
      tradeInfo, // 父组件调用子组件函数存放数据
      elMainRef,
      tradeInfoDatas, // form数据配置文件
    } = createCustomsDatasBus; // 父组件调用子组件函数

    // 待页面加载完成将$ref赋值给共享数据BUS
    nextTick(() => {
      createCustomsDatasBus.tradeInfoFormRef.value = tradeInfoFormRef.value;
    });
    function changeCNtoEN(nodeStr) {
      const inputVal = document.querySelector(`#${nodeStr}`);
      inputVal.oninput = function () {
        inputval.value = inputVal.value;
      };
    }
    // 展示页面的配置数据
    const baseDatas = reactive({
      tradetype: {
        label: '贸易方式',
        placeholder: '',
        value: '0110',
        disabled: true,
        selectOptions: [
          { value: '0110', label: '一般贸易', tagName: '一般贸易(0110)' },
          // { value: '0214', label: '来料加工',tagName:"来料加工(0214)" },
        ],
      },
      payMode: {
        label: '成交方式',
        placeholder: '',
        value: '3',
        disabled: true,
        selectOptions: [{ value: '3', label: 'FOB', tagName: 'FOB' }],
      },
      refundDuty: {
        label: '是否退税',
        placeholder: '请选择',
        value: '0',
        disabled: true,
        selectOptions: [{ value: '0', label: '否', tagName: '不退税' }],
      },
      originCountry: {
        label: '原产国(地区）',
        placeholder: '请选择',
        value: 'CHN',
        disabled: true,
        selectOptions: [{ value: 'CHN', label: '中国', tagName: '中国(CHN)' }],
      },
      productOrigin: {
        label: '货源地',
        placeholder: '请选择',
        value: '44031',
        disabled: false,
        selectOptions: [...origin],
        inputFunc: () => {
          selectoptionsFormClass.getQueryFunc(tradeInfoDatas.tradeDatas, baseDatas.productOrigin, inputval.value, 'productOrigin', 'selectOptions');
        },
        vsbChangeOptions: (e) => {
          // 下拉框展示
          if (e) {
            // 展示时
            changeCNtoEN('productOrigin'); // 展示时，监听输入框输入内容
          }
          selectoptionsFormClass.changeOptionsFunc(e, 'productOrigin', tradeInfoDatas.tradeDatas, baseDatas.productOrigin, [...origin], 'selectOptions');
        },
        optionChangeFuncs: (e) => {
          // 下拉点击更新数据
          selectoptionsFormClass.rangeOptionsFunc(e, 'productOrigin');
        },
      },

      destCountry: {
        label: '最终目的国',
        placeholder: '请选择',
        value: '',
        disabled: false,
        selectOptions: [...country],

        inputFunc: () => {
          selectoptionsFormClass.getQueryFunc(tradeInfoDatas.tradeDatas, baseDatas.destCountry, inputval.value, 'destCountry', 'selectOptions');
        },
        vsbChangeOptions: (e) => {
          // 下拉框展示
          if (e) {
            // 展示时
            changeCNtoEN('destCountry'); // 展示时，监听输入框输入内容
          }
          selectoptionsFormClass.changeOptionsFunc(e, 'destCountry', tradeInfoDatas.tradeDatas, baseDatas.destCountry, [...country], 'selectOptions');
        },
        optionChangeFuncs: (e) => {
          // 下拉点击更新数据
          selectoptionsFormClass.rangeOptionsFunc(e, 'destCountry');
        },
      },

      contractNum: {
        label: '合同号',
        placeholder: '本批号贸易合同号',
        value: '',
      },
      invoiceNum: {
        label: '发票号',
        placeholder: '选填内容，发票号',
        value: '',
      },
      packingNum: {
        label: '箱单号',
        placeholder: '选填内容，装箱单号',
        value: '',
      },
      packageMark: {
        label: '箱唛',
        placeholder: '选填内容，箱唛',
        value: '',
      },
    });

    // 方法
    const myMethods = reactive({
      // 初始化数据，添加验证规则
      initDatas: () => {
        Object.keys(baseDatas).forEach((key) => {
          tradeInfoDatas.tradeDatas[key] = baseDatas[key].value; // 遍历出key添加到datas中
        });
      },
    });
    onBeforeMount(() => {
      // 初始化：从baseDatas数据里获取相关数据添加至datas的form表单中
      myMethods.initDatas();
    });

    // 添加测试数据
    onMounted(() => {
      const testDatas = {
        contractNum: '',
        destCountry: '',
        invoiceNum: '',
        originCountry: 'CHN',
        packageMark: '',
        packingNum: '',
        payMode: '3',
        productOrigin: '44031',
        refundDuty: '0',
        tradetype: '0110',
      };
      Object.assign(tradeInfoDatas.tradeDatas, testDatas);
    });

    return {
      ...toRefs(tradeInfoDatas),
      ...toRefs(myMethods),
      tradeRules,
      createCustomsDatasBus,
      baseDatas,
      tradeInfoFormRef,
      tradeInfo,
      elMainRef,
    };
  },
};
</script>
<style lang="scss" scoped>
.tradeinfo {
  padding: $base $base $base * 0.3 $base;
  @import '@/assets/scss/globalcss/elementsFromstyle.scss';

  .myclass {

    //隐藏*数据
    :deep(.el-form-item__label) {
      position: relative;

      &::before {
        content: '' !important;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}
</style>
